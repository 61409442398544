<template>
  <Breadcrumbs :items="breadcrumbItems" />
  <v-card>
    <template v-slot:title> </template>

    <v-divider></v-divider>

    <v-card-text>
      <div>
        <v-form ref="form" @submit.prevent="handleSubmit">
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Name</label>
            </v-col>
            <v-col md="3" sm="3" cols="12">
              <v-text-field v-model="productCategory.name" required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Code</label>
            </v-col>
            <v-col md="3" sm="3" cols="12">
              <v-text-field v-model="productCategory.code" required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Parent Category</label>
            </v-col>
            <v-col md="3" sm="3" cols="12">
              <v-select v-model="productCategory.parent" :items="productCategories" item-value="id" item-title="name"
                    required></v-select>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-card-text>

    <template v-slot:append>
      <v-btn @click="submitForm" color="primary">
        {{ isAdding ? $t('button.save') : $t('button.update') }}
      </v-btn>
    </template>
  </v-card>
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { mapActions } from 'vuex';
import breadcrumbConfig from '@/others/breadcrumbConfig.js';

export default {
  components: {
    Breadcrumbs
  },
  data() {
    return {
      productCategory: {
        id: null,
        name: '',
        code: ''
      },
      productCategories: []
    };
  },
  computed: {
    isAdding() {
      return this.$route.params.action === 'add';
    },
    breadcrumbItems() {
      const action = this.$route.params.action;
      const route = this.$route.name;
      const items = breadcrumbConfig[route];

      if (typeof items === 'function') {
        return items(action);
      }

      return items || [];
    }
  },
  async mounted() {
    const { action, id } = this.$route.params;

    this.fetchCategory()

    // Fetch productCategory details if editing
    if (action === 'edit' && id) {
      try {
        const response = await axios.get(`/product_category/${id}`);
        this.productCategory = response.data;
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    }
  },
  methods: {
    ...mapActions(['showAlert']),
    async handleSubmit() {
      try {
        const productCategoryData = {
          name: this.productCategory.name,
          code: this.productCategory.code,
          parent_id: this.productCategory.parent
        };

        if (this.isAdding) {
          const response = await axios.post('/product_category/', { product_category: productCategoryData });

          if (response.data) {
            this.$router.push({ name: 'productCategory' });
            this.showAlert({ message: 'Successfully added Product Category data', color: 'success' });
          } else {
            this.showAlert({ message: "Failed", color: 'error' });
          }
        } else {
          const response = await axios.put(`/product_category/${this.productCategory.id}`, { product_category: productCategoryData });

          if (response.data) {
            this.$router.push({ name: 'productCategory' });
            this.showAlert({ message: 'Successfully added Product Category data', color: 'success' });
          } else {
            this.showAlert({ message: 'Error updating Product Category', color: 'error' });
          }
        }
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    submitForm() {
      this.$refs.form.validate(); // Trigger form validation
      this.handleSubmit(); // Call the submit method
    },
    async fetchCategory() {
      this.loading = true;
      try {
        const response = await axios.get('/product_category?is_parent=true');
        this.productCategories = response.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
  }
};
</script>