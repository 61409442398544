<template>
    <Breadcrumbs :items="breadcrumbItems" />
    <h3>This is My Profile Page</h3>

</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import breadcrumbConfig from '@/others/breadcrumbConfig';

export default {
    components: {
        Breadcrumbs
    },
    data() {
        return {
            user: {
                id: null,
                email: '',
                password: ''
            },
        };
    },
    async created() {
    },
    computed: {
        breadcrumbItems() {
            const route = this.$route.name;
            const action = this.$route.params.action;
            const breadcrumbItems = breadcrumbConfig[route];

            // Handle case where the route is dynamic, e.g., 'user-form'
            if (typeof breadcrumbItems === 'function') {
                return breadcrumbItems(action);
            }

            return breadcrumbItems || [];
        }
    },

};
</script>