<template>
    <Breadcrumbs :items="breadcrumbItems" />
    <v-card>
      <template v-slot:title> </template>
  
      <v-divider></v-divider>
  
      <v-card-text>
        <div>
          <v-form ref="form" @submit.prevent="handleSubmit">
            <v-row>
              <v-col lg="2" md="2" sm="2" cols="12">
                <label class="custom-label">Promotion Number</label>
              </v-col>
              <v-col md="6" sm="6" cols="12">
                <v-text-field v-model="promotionManager.promotion_number" required bgColor="none"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="2" md="2" sm="2" cols="12">
                <label class="custom-label">Description</label>
              </v-col>
              <v-col md="6" sm="6" cols="12">
                <v-text-field v-model="promotionManager.description" required bgColor="none"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
                <v-col lg="2" md="2" sm="2" cols="12">
                    <label class="custom-label">Promotion Period</label>
                </v-col>
                <v-col cols="6" md="3">
                    <v-date-input v-model="promotionManager.start_date" placeholder="Start Date" variant="outlined" density="compact" hide-details
                        :multiple="false" prepend-icon="" prepend-inner-icon="$calendar"></v-date-input>
                </v-col>
                <v-col cols="6" md="3">
                    <v-date-input v-model="promotionManager.end_date" placeholder="End Date" variant="outlined" density="compact" hide-details
                        :multiple="false" prepend-icon="" prepend-inner-icon="$calendar"></v-date-input>
                </v-col>
            </v-row>
            <v-row class="pb-4">
              <v-col lg="2" md="2" sm="2" cols="12">
                <label class="custom-label">Status</label>
              </v-col>
              <v-col md="6" sm="6" cols="12">
                <v-select bgColor="none" v-model="promotionManager.status" :items="statusOption" item-value="id" item-title="name"
                  placeholder="" required></v-select>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pt-4">
              <v-col lg="2" md="2" sm="2" cols="12">
                <label class="custom-label">Region</label>
              </v-col>
              <v-col md="6" sm="6" cols="12">
                <v-select bgColor="none" v-model="region" :items="regions" item-value="item" item-title="name"
                  placeholder="" required @update:modelValue="findCoverageByType($event)"></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="2" md="2" sm="2" cols="12">
                <label class="custom-label">Area</label>
              </v-col>
              <v-col md="6" sm="6" cols="12">
                <v-select v-model="area" :items="areas" item-value="item" item-title="name" return-object
                  placeholder="" required></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="2" md="2" sm="2" cols="12">
                <label class="custom-label">Distributor</label>
              </v-col>
              <v-col md="6" sm="6" cols="12">
                <v-select v-model="distributor" :items="distributors" item-value="id" item-title="name"
                  placeholder="Select Location" required></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="2" md="2" sm="2" cols="12">
                <label class="custom-label">Ship To</label>
              </v-col>
              <v-col md="6" sm="6" cols="12">
                <v-btn
                  variant="outlined"
                  append-icon="mdi-menu-down"
                  width="100%"
                  height="37"
                  class="d-flex justify-space-between align-center"
                >
                  Select Data
                  <v-dialog activator="parent" max-width="640">
                    <template v-slot:default="{ isActive }">
                      <v-card
                        title="Select Ship to"
                      >
                        <v-data-table
                          :headers="shiptoHeaders"
                          :items="distributors"
                          item-value="id"
                          show-select
                          class="elevation-1"
                          v-model="selectedShipTo"
                          hide-default-footer
                        >
                          <!-- No column using index -->
                          <template v-slot:item.no="{ index }">
                            {{ index + 1 }}
                          </template>

                          <template v-slot:footer.prepend>
                            Showing {{ items.length }} of 30 Data
                          </template>
                        </v-data-table>

                        <template v-slot:actions>
                          <v-btn
                            variant="flat"
                            color="primary"
                            text="Select"
                            @click="isActive.value = false"
                          ></v-btn>
                        </template>
                      </v-card>
                    </template>
                  </v-dialog>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="2" md="2" sm="2" cols="12">
                <label class="custom-label">Promotion Scheme</label>
              </v-col>
              <v-col md="6" sm="6" cols="12">
                <v-btn
                  variant="outlined"
                  append-icon="mdi-list-box-outline"
                  width="100%"
                  height="37"
                  class="d-flex justify-space-between align-center"
                >
                   Create Scheme
                  <v-dialog activator="parent" max-width="80%">
                    <template v-slot:default="{ isActive }">
                      <v-card
                        prepend-icon="mdi-picture-in-picture-bottom-right"
                         :title="`Promotion Scheme for ${this.region} - ${this.area} - ${this.distributor}`"
                      >
                      <v-row class="my-4">
                        <v-col cols="12">
                          <v-data-table :items="schemeData" :headers="schemeHeaders" hide-default-footer >
                            <!-- Sell In Product -->
                            <template v-slot:item.sell_in_by="{ item }">
                              <v-select v-model="item.sell_in_by" :items="selectByOption" class="py-3"
                                placeholder="select sales" required></v-select>
                            </template>
                            <template v-slot:item.sell_in_product="{ item }">
                              <v-btn
                                variant="outlined"
                                append-icon="mdi-menu-down"
                                width="100%"
                                height="37"
                                class="d-flex justify-space-between align-center"
                              >
                                Multi Product
                                <v-dialog activator="parent" max-width="640">
                                  <template v-slot:default="{ isActive }">
                                    <v-card
                                      title="Select Product"
                                    >
                                      <v-data-table
                                        :headers="productHeaders"
                                        :items="products"
                                        item-value="id"
                                        show-select
                                        class="elevation-1"
                                        v-model="item.sell_in_product"
                                        hide-default-footer
                                      >
                                        <!-- No column using index -->
                                        <template v-slot:item.no="{ index }">
                                          {{ index + 1 }}
                                        </template>

                                        <template v-slot:footer.prepend>
                                          Showing {{ items.length }} of 30 Data
                                        </template>
                                      </v-data-table>

                                      <template v-slot:actions>
                                        <v-btn
                                          variant="flat"
                                          color="primary"
                                          text="Select"
                                          @click="isActive.value = false"
                                        ></v-btn>
                                      </template>
                                    </v-card>
                                  </template>
                                </v-dialog>
                              </v-btn>
                            </template>
                            <!-- Free Product -->
                            <template v-slot:item.free_product_by="{ item }">
                              <v-select v-model="item.free_product_by" :items="selectByOption" class="py-3"
                                placeholder="select sales" required></v-select>
                            </template>
                            <template v-slot:item.free_product="{ item }">
                              <v-btn
                                variant="outlined"
                                append-icon="mdi-menu-down"
                                width="100%"
                                height="37"
                                class="d-flex justify-space-between align-center"
                              >
                                Multi Product
                                <v-dialog activator="parent" max-width="640">
                                  <template v-slot:default="{ isActive }">
                                    <v-card
                                      title="Select Product"
                                    >
                                      <v-data-table
                                        :headers="productHeaders"
                                        :items="products"
                                        item-value="id"
                                        show-select
                                        class="elevation-1"
                                        v-model="item.free_product"
                                        hide-default-footer
                                      >
                                        <!-- No column using index -->
                                        <template v-slot:item.no="{ index }">
                                          {{ index + 1 }}
                                        </template>

                                        <template v-slot:footer.prepend>
                                          Showing {{ items.length }} of 30 Data
                                        </template>
                                      </v-data-table>

                                      <template v-slot:actions>
                                        <v-btn
                                          variant="flat"
                                          color="primary"
                                          text="Select"
                                          @click="isActive.value = false"
                                        ></v-btn>
                                      </template>
                                    </v-card>
                                  </template>
                                </v-dialog>
                              </v-btn>
                            </template>
                            <template v-slot:item.target_limit="{ item }">
                              <vue-number v-model="item.target_limit" v-bind="number" class="custom-input text-right ma-2"></vue-number>
                            </template>
                            <template v-slot:item.max_qty="{ item }">
                              <vue-number v-model="item.max_qty" v-bind="number" class="custom-input text-right ma-2"></vue-number>
                            </template>
                            <template v-slot:item.free_qty="{ item }">
                              <vue-number v-model="item.free_qty" v-bind="number" class="custom-input text-right ma-2"></vue-number>
                            </template>
                            <template v-slot:item.allocation_limit="{ item }">
                              <vue-number v-model="item.allocation_limit" v-bind="number" class="custom-input text-right ma-2"></vue-number>
                            </template>
                            <template v-slot:item.auto_implement="{ item }">
                              <v-switch
                                :model-value="item.auto_implement"
                                color="primary"
                              ></v-switch>
                            </template>

                            

                            <template v-slot:item.actions="{ item }"> <!-- Use item.actions here -->
                              <v-btn density="compact" variant="text" color="error"
                                @click="confirmDelete(item, 2)">{{ $t('actions.delete') }}</v-btn>
                            </template>
                          </v-data-table>
                        </v-col>
                        <v-col>
                          <v-btn variant="outlined" color="primary" @click="addNewScheme">Add Another</v-btn>
                        </v-col>
                      </v-row>

                        <template v-slot:actions>
                          <v-btn
                            class="ml-auto"
                            text="Save"
                            @click="isActive.value = false"
                          ></v-btn>
                          
                        </template>
                      </v-card>
                    </template>
                  </v-dialog>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
                <v-col offset="2" cols="4" class="d-flex align-center">
                    <v-btn @click="saveSchemeData" color="primary" class="align-self-center">Add</v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <div>Scheme List</div>
                </v-col>
                <v-col cols="12">
                    <v-data-table :items="schemeList" :headers="schemeListHeaders" hide-default-footer >
                        <template v-slot:item.ship_to="{ item }">
                              Multiple Ship to
                        </template>
                        <template v-slot:item.scheme_details_attributes="{ item }">
                              Multiple Scheme
                        </template>
                        <template v-slot:item.actions="{ item }"> 
                          <v-btn density="compact" variant="text" color="error"
                              @click="confirmDelete(item, 1)">{{ $t('actions.delete') }}</v-btn>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
           

          </v-form>
        </div>
      </v-card-text>
  
      <template v-slot:append>
        <v-btn @click="submitForm" color="primary">
          {{ isAdding ? $t('button.save') : $t('button.update') }}
        </v-btn>
      </template>
    </v-card>

     <!-- Confirmation Dialog -->
    <v-dialog v-model="showConfirmation" max-width="400" persistent>
        <v-card>
            <v-card-title>
                Confirm Deletion
            </v-card-title>
            <v-card-subtitle>
                Are you sure you want to delete this item?
            </v-card-subtitle>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="showConfirmation = false">
                    Cancel
                </v-btn>
                <v-btn @click="deleteItem">
                    Confirm
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </template>
  
  <script>
  import axios from '../plugins/axios';
  import Breadcrumbs from '@/components/Breadcrumbs.vue';
  import { mapActions } from 'vuex';
  import breadcrumbConfig from '@/others/breadcrumbConfig.js';
  
  export default {
    components: {
      Breadcrumbs
    },
    data() {
      return {
        promotionManager: {
          id: null,
        },
        statusOption: ['Active', 'Inactive'],
        coverages: [],
        roles: [],
        distributors: [],
        distributor: null,
        area: null,
        region: null,
        products: [],
        selectedShipTo: [],
        selectedProducts: [],
        selectedFreeProducts: [],
        schemeList: [],
        schemeData: [],
        regions: [],
        areas: [],
        selectedRoleId: null,
        selectedCoverageId: null,
        selectedCoverageTypeId: null,
        showConfirmation: false,
        itemToDelete : null,
        itemNeedToDelete: null,
        selectByOption: ['By Product', 'By Category 1', 'By Category 2', 'By Division' ],
        shiptoHeaders: [
          { text: 'No', value: 'no' },
          { text: 'Ship To Code', value: 'code' },
          { text: 'Ship To Name', value: 'name' },
        ],
        productHeaders: [
          { text: 'No', value: 'no' },
          { text: 'SKU', value: 'sku' },
          { text: 'Product Nick Name', value: 'alias_name' },
          { text: 'Product Name', value: 'product_name' },
        ],
        schemeHeaders: [
          { title: 'Sell In Product', key: 'sell_in_by', align: 'center', width:400 },
          { title: 'Sell In Product', key: 'sell_in_product', align: 'center', width:400 },
          { title: 'Free Product', key: 'free_product_by', align: 'center', width:400 },
          { title: 'Free Product', key: 'free_product', align: 'center', width:400 },
          { title: 'Target Limit', key: 'target_limit', align: 'center', width:400 },
          { title: 'Max Qty', key: 'max_qty', align: 'center', width:400 },
          { title: 'Free Qty', key: 'free_qty', align: 'center', width:400 },
          { title: 'Allocation Limit', key: 'allocation_limit', align: 'center', width:400 },
          { title: 'Auto Implement', key: 'auto_implement', align: 'center' },
          { title: 'Action', value: 'actions', align: 'center' },
        ],
        schemeListHeaders :[
            { title: 'Region', value: 'region', align: 'center' },
            { title: 'Area', key: 'area', align: 'center' },
            { title: 'Distributor', value: 'distributor', align: 'center' },
            { title: 'Ship To', key: 'ship_to', align: 'center' },
            { title: 'Scheme', key: 'scheme_details_attributes', align: 'center' },
            { title: 'Action', value: 'actions', align: 'center' },
        ],
      };
    },
    watch: {
      coverages(newValue, oldValue) {
        this.promotionManager.coverages = []
      }
    },
    computed: {
      isAdding() {
        return this.$route.params.action === 'add';
      },
      breadcrumbItems() {
        const action = this.$route.params.action;
        const route = this.$route.name;
        const items = breadcrumbConfig[route];
  
        if (typeof items === 'function') {
          return items(action);
        }
  
        return items || [];
      }
    },
    async mounted() {
      const { action, id } = this.$route.params;
  
      this.fetchCoverage();
      this.fetchDistributors();
      this.fetchProducts();
      
      // Fetch promotionManager details if editing
      if (action === 'edit' && id) {
        try {
          const response = await axios.get(`/promotion/${id}`);
          this.promotionManager = response.data;
          this.promotionManager.start_date = new Date(response.data.start_date);
          this.promotionManager.end_date = new Date(response.data.end_date);
          this.schemeList = [...response.data.scheme_headers_attributes];
          // this.selectedCoverageId = this.promotionManager.coverage_id
        } catch (error) {
          const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'An error occurred';
  
          this.showAlert({ message: errorMessage, color: 'error' });
        }
      }
    },
    methods: {
      ...mapActions(['showAlert']),
      async handleSubmit() {
        try {
          const promotionData = {
            promotion_number: this.promotionManager.promotion_number, 
            description: this.promotionManager.description, 
            start_date: new Date(this.promotionManager.start_date).toLocaleDateString('en-CA'),
            end_date: new Date(this.promotionManager.end_date).toLocaleDateString('en-CA'),
            status: this.promotionManager.status, 
            scheme_headers_attributes: [
                ...this.schemeList
            ]
          };

          console.log(promotionData)
          console.log(this.area)
  
           if (this.isAdding) {
             const response = await axios.post('/promotion', { promotion: promotionData });
             this.showAlert({ message: 'promotionManager data successfully saved', color: 'success' });
             if (response.data) {
               this.$router.push({ name: 'promotion' });
             } else {
               throw new Error(response.message);
             }
           } else {
             const response = await axios.put(`/promotion/${this.promotionManager.id}`, { promotion: promotionData });
             this.showAlert({ message: 'promotionManager data successfully update', color: 'success' });
             if (response.data) {
               this.$router.push({ name: 'promotion' });
             } else {
               throw new Error('Failed to update promotionManager');
             }
           }
        } catch (error) {
          const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'An error occurred';
  
          this.showAlert({ message: errorMessage, color: 'error' });
        }
      },
      submitForm() {
        this.$refs.form.validate(); // Trigger form validation
        this.handleSubmit(); // Call the submit method
      },
      async fetchCoverage() {
        try {
          const region = await axios.get('/coverage?type=region');
          const area = await axios.get('/coverage?type=area');

          this.regions = region.data;
          this.areas = area.data;
        } catch (error) {
          const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'An error occurred';
  
          this.showAlert({ message: errorMessage, color: 'error' });
        }
      },
      async findCoverageByType(type) {
        console.log(type)
        try {
          const response = await axios.get(`/coverage?type=${(type).toLowerCase()}`);
          this.coverages = response.data;
        } catch (error) {
          console.log(error)
          const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'An asdfasdf asdf';
  
          this.showAlert({ message: errorMessage, color: 'error' });
        }
      },
      async fetchDistributors() {
        this.loading = true;
        try {
          const response = await axios.get('/distributor');
          this.distributors = response.data;
          this.loading = false;
        } catch (error) {
          this.loading = false;
          const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'An error occurred';

          this.showAlert({ message: errorMessage, color: 'error' });
        }
      },
      async fetchProducts() {
        this.loadPfetchProducts = true;
        try {
          const response = await axios.get('/product');
          this.products = response.data;
          this.loading = false;
        } catch (error) {
          this.loading = false;
          const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'An error occurred';

          this.showAlert({ message: errorMessage, color: 'error' });
        }
      },
      addNewScheme() {
        let newScheme = {
          sell_in_by: null,
          sell_in_product: null,
          free_product_by: null,
          free_product: null,
          target_limit: null,
          max_qty: null,
          free_qty: null,
          allocation_limit: null,
          auto_implement: false,
        }

        this.schemeData.push(newScheme);
      },
      confirmDelete(item, type) {
        this.itemToDelete = item;
        this.itemNeedToDelete = type;
        this.showConfirmation = true;
      },
      deleteItem() {
        if(this.itemNeedToDelete === 1) {
          const index = this.schemeList.indexOf(this.itemToDelete);
          if (index !== -1) {
            this.schemeList.splice(index, 1); 
          }
        } else {
          const index = this.schemeData.indexOf(this.itemToDelete);
          if (index !== -1) {
            this.schemeData.splice(index, 1); 
          }
        }
        
       
        this.showConfirmation = false;
        this.itemToDelete = null;
      },
      saveSchemeData(isActive) {
        // Push current schemeData to schemeList
        this.schemeList.push({
          coverage_id: this.area.id,
          distributor: this.distributor,
          ship_to: this.selectedShipTo,
          scheme_details_attributes: this.schemeData
        });
        
        // Reset schemeData for the next input
        this.schemeData = [];
      },
    }
  };
  </script>
  
  <style scoped>
    .custom-input {
      border: 1px solid #ddd;
      padding: 6px 6px;
      border-radius: 2px;
      width: 5vw;
      box-sizing: border-box;
      text-align: right;
    }
  </style>