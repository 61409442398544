<template>
  <Breadcrumbs :items="breadcrumbItems" />
  <v-card>
    <template v-slot:title> </template>

    <v-divider></v-divider>

    <v-card-text>
      <div>
        <v-form ref="form" @submit.prevent="handleSubmit">
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Memo Number</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-text-field v-model="memoDiscount.memo_number" readonly bgColor="none" placeholder="Automatic"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Memo Date</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-date-input disabled v-model="memoDiscount.memo_date" variant="outlined" density="compact" hide-details
                :multiple="false" prepend-icon="" prepend-inner-icon="$calendar"></v-date-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Memo Type</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-select bgColor="none" v-model="memoDiscount.memo_type" :items="memoTypeOptions" 
                placeholder=""></v-select>
            </v-col>
          </v-row>
          <v-row v-if="memoDiscount.memo_type === 'CMO'" v-for="cmo in selectedCMO">
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label"></label>
            </v-col>
              <v-col md="6" sm="6" cols="12">
                <v-btn variant="text" color="primary" @click="navigateToForm('view', cmo)">{{ cmo.transaction_number }}</v-btn>
              </v-col>
          </v-row>

          <v-row class="pb-4" v-if="memoDiscount.memo_type === 'CMO'">
              <v-col lg="2" md="2" sm="2" cols="12">
                  <label class="custom-label">Select CMO Document</label>
              </v-col>
              <v-col cols="6" md="6">
                <v-btn
                  variant="outlined"
                  append-icon="mdi-menu-down"
                  width="100%"
                  height="37"
                  class="d-flex justify-space-between align-center"
                >
                  Select Data
                  <v-dialog activator="parent" max-width="640">
                    <template v-slot:default="{ isActive }">
                      <v-card
                        title="Select CMO"
                      >
                        <v-data-table
                          :headers="cmoHeaders"
                          :items="cmoData"
                          return-object
                          show-select
                          class="elevation-1"
                          v-model="selectedCMO"
                          hide-default-footer
                        >
                          <!-- No column using index -->
                          <template v-slot:item.no="{ index }">
                            {{ index + 1 }}
                          </template>

                          <template v-slot:footer.prepend>
                            Showing {{ items.length }} of 30 Data
                          </template>
                        </v-data-table>

                        <template v-slot:actions>
                          <v-btn
                            variant="flat"
                            color="primary"
                            text="Select"
                            @click="isActive.value = false"
                          ></v-btn>
                        </template>
                      </v-card>
                    </template>
                  </v-dialog>
                </v-btn>
              </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-row class="pt-4">
              <v-col lg="2" md="2" sm="2" cols="12">
                  <label class="custom-label">Discount Period</label>
              </v-col>
              <v-col cols="6" md="3">
                  <v-date-input v-model="memoDiscount.start_date" placeholder="Start Date" variant="outlined" density="compact" hide-details
                      :multiple="false" prepend-icon="" prepend-inner-icon="$calendar"></v-date-input>
              </v-col>
              <v-col cols="6" md="3">
                  <v-date-input v-model="memoDiscount.end_date" placeholder="End Date" variant="outlined" density="compact" hide-details
                      :multiple="false" prepend-icon="" prepend-inner-icon="$calendar"></v-date-input>
              </v-col>
          </v-row>
          <v-row class="pt-4" v-if="memoDiscount.memo_type === 'CMO'">
              <v-col lg="2" md="2" sm="2" cols="12">
                  <label class="custom-label">Discount Value</label>
              </v-col>
              <v-col cols="6" md="3">
                <v-text-field v-model.number="memoDiscount.discount_value" bgColor="none" placeholder=""></v-text-field>
              </v-col>
              <v-col cols="6" md="3">
                <v-select bgColor="none" placeholder="Discount Type" v-model="memoDiscount.discount_type" :items="discountTypes"
                 ></v-select>
              </v-col>
          </v-row>
          <v-row class="pt-4" v-if="memoDiscount.memo_type === 'Direct'">
              <v-col lg="2" md="2" sm="2" cols="12">
                  <label class="custom-label">Distributor & Ship to</label>
              </v-col>
              <v-col cols="6" md="3">
                <v-select v-model="distributor" :items="distributors" item-value="id" item-title="name"
                  placeholder="Select Sold To"></v-select>
              </v-col>
              <v-col cols="6" md="3">
                <v-btn
                  variant="outlined"
                  append-icon="mdi-menu-down"
                  width="100%"
                  height="37"
                  class="d-flex justify-space-between align-center"
                >
                  Select Data
                  <v-dialog activator="parent" max-width="640">
                    <template v-slot:default="{ isActive }">
                      <v-card
                        title="Select Ship to"
                      >
                        <v-data-table
                          :headers="shiptoHeaders"
                          :items="distributors"
                          return-object
                          show-select
                          class="elevation-1"
                          v-model="selectedShipTo"
                          hide-default-footer
                        >
                          <!-- No column using index -->
                          <template v-slot:item.no="{ index }">
                            {{ index + 1 }}
                          </template>

                          <template v-slot:footer.prepend>
                            Showing {{ items.length }} of 30 Data
                          </template>
                        </v-data-table>

                        <template v-slot:actions>
                          <v-btn
                            variant="flat"
                            color="primary"
                            text="Select"
                            @click="isActive.value = false"
                          ></v-btn>
                        </template>
                      </v-card>
                    </template>
                  </v-dialog>
                </v-btn>
              </v-col>
          </v-row>
          <v-row class="pt-4" v-if="memoDiscount.memo_type === 'Direct'">
              <v-col lg="2" md="2" sm="2" cols="12">
                  <label class="custom-label">Discount Type</label>
              </v-col>
              <v-col cols="6">
                <v-select bgColor="none" placeholder="" v-model="memoDiscount.discount_type" :items="discountTypes"
                 ></v-select>
              </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Remark</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-textarea v-model="memoDiscount.remarks" bgColor="none" placeholder=""></v-textarea>
            </v-col>
          </v-row>

          <v-row v-if="memoDiscount.discount_type === 'Product'">
              <v-col cols="12">
                  <div>Product List</div>
              </v-col>
              <v-col cols="12">
                  <v-data-table :items="productList" :headers="productListHeaders" hide-default-footer >
                      <template v-slot:item.ship_to="{ item }">
                            Multiple Ship to
                      </template>
                      <template v-slot:item.scheme_details_attributes="{ item }">
                            Multiple Scheme
                      </template>
                      <template v-slot:item.actions="{ item }"> 
                        <v-btn density="compact" variant="text" color="error"
                            @click="confirmDelete(item, 1)">{{ $t('actions.delete') }}</v-btn>
                      </template>
                  </v-data-table>
              </v-col>
          </v-row>
         

        </v-form>
      </div>
    </v-card-text>

    <template v-slot:append>
      <v-btn color="primary" class="ml-2" @click="submitForm(2)" :disabled="!asApprover || approvedDocument || draftDocument">Approve</v-btn>
        <v-btn color="primary" class="ml-2" @click="submitForm(0)" :disabled="memoDiscount.status === 'Awaiting for Approval' || approvedDocument">Save</v-btn>
        <v-btn color="primary" class="ml-2" @click="submitForm(1)" :disabled="memoDiscount.status === 'Awaiting for Approval' || approvedDocument">Submit</v-btn>
        <v-btn color="primary" class="ml-2" append-icon="mdi-chevron-down" :disabled="!asApprover || approvedDocument || draftDocument">
				Options

				<v-menu activator="parent" density="compact">
					<v-list density="compact">
						<v-list-item density="compact" title="Cancel" value="edit" @click="submitForm(3)"></v-list-item>
					</v-list>
				</v-menu>
			</v-btn>
    </template>
  </v-card>

   <!-- Confirmation Dialog -->
  <v-dialog v-model="showConfirmation" max-width="400" persistent>
      <v-card>
          <v-card-title>
              Confirm Deletion
          </v-card-title>
          <v-card-subtitle>
              Are you sure you want to delete this item?
          </v-card-subtitle>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="showConfirmation = false">
                  Cancel
              </v-btn>
              <v-btn @click="deleteItem">
                  Confirm
              </v-btn>
          </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { mapActions } from 'vuex';
import breadcrumbConfig from '@/others/breadcrumbConfig.js';

export default {
  components: {
    Breadcrumbs
  },
  data() {
    return {
      memoDiscount: {
        id: null,
        memo_number: '',
        memo_type: 'CMO',
        start_date: null,
        end_date: null,
        discount_type: '',
        discount_value: '',
        remarks: '',
      },
      statusOption: ['Active', 'Inactive'],
      memoTypeOptions:['General', 'CMO'],
      discountTypes: ['Percent', 'Value', 'Product'],
      coverages: [],
      roles: [],
      distributors: [],
      cmoData: [],
      distributor: null,
      area: null,
      region: null,
      products: [],
      selectedShipTo: [],
      selectedProducts: [],
      selectedFreeProducts: [],
      productList: [],
      schemeData: [],
      regions: [],
      areas: [],
      selectedRoleId: null,
      selectedCoverageId: null,
      selectedCoverageTypeId: null,
      showConfirmation: false,
      itemToDelete : null,
      itemNeedToDelete: null,
      selectByOption: ['By Product', 'By Category 1', 'By Category 2', 'By Division' ],
      shiptoHeaders: [
        { text: 'No', value: 'no' },
        { text: 'Ship To Code', value: 'code' },
        { text: 'Ship To Name', value: 'name' },
      ],
      cmoHeaders: [
        { text: 'Transaction Number', value: 'transaction_number' },
        { text: 'Shipment Type', value: 'shipment_type' },
        { text: 'Transaction Type', value: 'transaction_type' },
      ],
      productHeaders: [
        { text: 'No', value: 'no' },
        { text: 'SKU', value: 'sku' },
        { text: 'Product Nick Name', value: 'alias_name' },
        { text: 'Product Name', value: 'product_name' },
      ],
      schemeHeaders: [
        { title: 'Sell In Product', key: 'sell_in_by', align: 'center', width:400 },
        { title: 'Sell In Product', key: 'sell_in_product', align: 'center', width:400 },
        { title: 'Free Product', key: 'free_product_by', align: 'center', width:400 },
        { title: 'Free Product', key: 'free_product', align: 'center', width:400 },
        { title: 'Target Limit', key: 'target_limit', align: 'center', width:400 },
        { title: 'Max Qty', key: 'max_qty', align: 'center', width:400 },
        { title: 'Free Qty', key: 'free_qty', align: 'center', width:400 },
        { title: 'Allocation Limit', key: 'allocation_limit', align: 'center', width:400 },
        { title: 'Auto Implement', key: 'auto_implement', align: 'center' },
        { title: 'Action', value: 'actions', align: 'center' },
      ],
      productListHeaders :[
          { title: 'Region', value: 'region', align: 'center' },
          { title: 'Area', key: 'area', align: 'center' },
          { title: 'Distributor', value: 'distributor', align: 'center' },
          { title: 'Ship To', key: 'ship_to', align: 'center' },
          { title: 'Scheme', key: 'scheme_details_attributes', align: 'center' },
          { title: 'Action', value: 'actions', align: 'center' },
      ],
      selectedCMO: [],
      asApprover: false,
      approvedDocument: false,
      draftDocument: false,
    };
  },
  watch: {
    coverages(newValue, oldValue) {
      this.memoDiscount.coverages = []
    }
  },
  computed: {
    isAdding() {
      return this.$route.params.action === 'add';
    },
    breadcrumbItems() {
      const action = this.$route.params.action;
      const route = this.$route.name;
      const items = breadcrumbConfig[route];

      if (typeof items === 'function') {
        return items(action);
      }

      return items || [];
    }
  },
  async mounted() {
    const { action, id } = this.$route.params;

    // this.fetchCoverage();
    this.fetchDistributors();
    this.fetchCmoData();
    this.fetchProducts();
    
    // Fetch memoDiscount details if editing
    if (action === 'view' && id) {
      try {
        const response = await axios.get(`/memo_discount/${id}`);
        this.memoDiscount = response.data;
        this.memoDiscount.start_date = new Date(response.data.start_date);
        this.memoDiscount.end_date = new Date(response.data.end_date);
        this.selectedCMO = [...response.data.memo_discount_details]
        this.memoDiscount.memo_discount_details_attributes = [...response.data.memo_discount_details]
        this.memoDiscount.memo_discount_product_details_attributes = [...response.data.memo_discount_product_details]
        this.asApprover = response.data.is_approver
        this.approvedDocument = response.data.status === "Approved" ? true : false;
        this.draftDocument = response.data.status === "Draft" || response.data.status === "Request for Revise" ? true : false;
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    } else if( action === 'add') {
      this.memoDiscount.memo_date = new Date();
    }
  },
  methods: {
    ...mapActions(['showAlert']),
    async handleSubmit(type = null) {
      const status = type === 0 ? 'Draft' : type === 1 ? 'Awaiting for Approval': type === 2 ? 'Approved' : 'Cancelled';
      try {
        const memoDiscountData = {
          memo_number: this.memoDiscount.memo_number, 
          memo_type: this.memoDiscount.memo_type,
          discount_type: this.memoDiscount.discount_type,
          discount_value: this.memoDiscount.discount_value,
          start_date: new Date(this.memoDiscount.start_date).toLocaleDateString('en-CA'),
          end_date: new Date(this.memoDiscount.end_date).toLocaleDateString('en-CA'),
          remarks: this.memoDiscount.remarks,
          status: status,
          memo_discount_details_attributes: this.memoDiscount.memo_discount_details_attributes,
          memo_discount_product_details_attributes: this.memoDiscount.memo_discount_product_details_attributes,
        };

        let response;
        const updateStatus = type === 0 ? null : type === 1 ? 'submit' : type === 2 ? 'approve' : 'cancel';
        if (updateStatus) {
            response = await axios.put(`/memo_discount/${this.memoDiscount.id}/${updateStatus}`, { memo_discount: memoDiscountData });
        } else {
            response = await axios.put(`/memo_discount/${this.memoDiscount.id}`, { memo_discount: memoDiscountData });
        }

        this.showAlert({ message: 'Memo Discount data successfully update', color: 'success' });
        if (response.data) {
          this.$router.push({ name: 'memoDiscount' });
        } else {
          throw new Error('Failed to update Memo Discount data');
        }
      } catch (error) {
        console.log(error)
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    submitForm(type = null) {
      this.$refs.form.validate(); // Trigger form validation
      this.handleSubmit(type); // Call the submit method
    },
    async fetchCoverage() {
      try {
        const region = await axios.get('/coverage?type=region');
        const area = await axios.get('/coverage?type=area');

        this.regions = region.data;
        this.areas = area.data;
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    async findCoverageByType(type) {
      console.log(type)
      try {
        const response = await axios.get(`/coverage?type=${(type).toLowerCase()}`);
        this.coverages = response.data;
      } catch (error) {
        console.log(error)
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An asdfasdf asdf';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    async fetchDistributors() {
      this.loading = true;
      try {
        const response = await axios.get('/distributor');
        this.distributors = response.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    async fetchCmoData() {
      this.loading = true;
      try {
        const response = await axios.get('/confirm_monthly_order', { params: { cbd: true } });
        this.cmoData = response.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    async fetchProducts() {
      this.loadPfetchProducts = true;
      try {
        const response = await axios.get('/product');
        this.products = response.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    addNewScheme() {
      let newScheme = {
        sell_in_by: null,
        sell_in_product: null,
        free_product_by: null,
        free_product: null,
        target_limit: null,
        max_qty: null,
        free_qty: null,
        allocation_limit: null,
        auto_implement: false,
      }

      this.schemeData.push(newScheme);
    },
    confirmDelete(item, type) {
      this.itemToDelete = item;
      this.itemNeedToDelete = type;
      this.showConfirmation = true;
    },
    deleteItem() {
      if(this.itemNeedToDelete === 1) {
        const index = this.schemeList.indexOf(this.itemToDelete);
        if (index !== -1) {
          this.schemeList.splice(index, 1); 
        }
      } else {
        const index = this.schemeData.indexOf(this.itemToDelete);
        if (index !== -1) {
          this.schemeData.splice(index, 1); 
        }
      }
      
     
      this.showConfirmation = false;
      this.itemToDelete = null;
    },
    saveSchemeData(isActive) {
      // Push current schemeData to schemeList
      this.schemeList.push({
        coverage_id: this.area.id,
        distributor: this.distributor,
        ship_to: this.selectedShipTo,
        scheme_details_attributes: this.schemeData
      });
      
      // Reset schemeData for the next input
      this.schemeData = [];
    },
    navigateToForm(action, cmo = { object_id: null }) {
        if (action == 'view') {
            this.$router.push({ name: 'cmo-view', params: { action, id: cmo.object_id } });
        } else {
            // this.$router.push({ name: 'cmo-form', params: { action, id: cmo.id } });
        }
    },
  }
};
</script>

<style scoped>
  .custom-input {
    border: 1px solid #ddd;
    padding: 6px 6px;
    border-radius: 2px;
    width: 5vw;
    box-sizing: border-box;
    text-align: right;
  }
</style>