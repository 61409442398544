<template>
    <v-alert
      v-model="alert.show"
      :color="alert.color"
      :type="alert.color"
      variant="tonal"
      close-label="Close Alert"
      closable
      border="top"
    >
      {{ alert.message }}
    </v-alert>
  </template>
  
  <script>
  import { mapGetters } from 'vuex';
  
  export default {
    computed: {
      ...mapGetters(['alertState']),
      alert() {
        return this.alertState;
      },
    },
  };
  </script>
  
  <style scoped>
    :deep(.v-alert__content) {
      color: black !important;
    }
  </style>