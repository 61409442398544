<template>
  <Breadcrumbs :items="breadcrumbItems" />
  <v-card>
    <template v-slot:title> </template>

    <v-divider></v-divider>

    <v-card-text>
      <div>
        <v-form ref="form" @submit.prevent="handleSubmit">
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">SO Number</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-text-field v-model="sellIn.so_number" required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Bill Number</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-text-field v-model="sellIn.bill_number" required></v-text-field>
            </v-col>
          </v-row>
          
        </v-form>
      </div>
    </v-card-text>

    <template v-slot:append>
      <v-btn @click="submitForm" color="primary">
        {{ isAdding ? $t('button.save') : $t('button.update') }}
      </v-btn>
    </template>
  </v-card>
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { mapActions } from 'vuex';
import breadcrumbConfig from '@/others/breadcrumbConfig.js';

export default {
  components: {
    Breadcrumbs
  },
  data() {
    return {
      sellIn: {
        id: null,
        so_number: '',
        bill_number: '',
      },
      selectedRoleId: null,
      selectedProductId: null,
    };
  },
  computed: {
    isAdding() {
      return this.$route.params.action === 'add';
    },
    breadcrumbItems() {
      const action = this.$route.params.action;
      const route = this.$route.name;
      const items = breadcrumbConfig[route];

      if (typeof items === 'function') {
        return items(action);
      }

      return items || [];
    }
  },
  async mounted() {
    const { action, id } = this.$route.params;
    // Fetch sellIn details if editing
    if (action === 'edit' && id) {
      try {
        const response = await axios.get(`/selling_in/${id}`);
        this.sellIn = response.data;
        this.selectedProductId = this.sellIn.product_number
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    }
  },
  methods: {
    ...mapActions(['showAlert']),
    async handleSubmit() {
      try {
        const sellInData = {
          so_number :this.sellIn.so_number,
          bill_number: this.sellIn.bill_number,
        };

        if (this.isAdding) {
          const response = await axios.post('/selling_in',  sellInData );
          this.showAlert({ message: 'sellIn data successfully saved', color: 'success' });
          if (response.data) {
            this.$router.push({ name: 'sellIn' });
          } else {
            throw new Error(response.message);
          }
        } else {
          const response = await axios.put(`/selling_in/${this.sellIn.id}`, sellInData );
          this.showAlert({ message: 'sellIn data successfully update', color: 'success' });
          if (response.data) {
            this.$router.push({ name: 'sellIn' });
          } else {
            throw new Error('Failed to update sellIn');
          }
        }
      } catch (error) {
        console.log(error)
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    submitForm() {
      this.$refs.form.validate(); // Trigger form validation
      this.handleSubmit(); // Call the submit method
    },
  }
};
</script>