<template>
    <Breadcrumbs :items="breadcrumbItems" />
    <v-card>
      <template v-slot:title> </template>
  
      <v-divider></v-divider>
  
      <v-card-text>
        <div>
          <v-form ref="form" @submit.prevent="handleSubmit">
            <v-row>
              <v-col lg="2" md="2" sm="2" cols="12">
                <label class="custom-label">Product</label>
              </v-col>
              <v-col md="6" sm="6" cols="12">
                <v-select :item-props="itemProps" v-model="selectedProductId" :items="product" return-object item-title="product_name"
                  placeholder="Select Product" required></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="2" md="2" sm="2" cols="12">
                <label class="custom-label">M+0 To M+1</label>
              </v-col>
              <v-col md="6" sm="6" cols="12">
                <v-text-field v-model="defiasiLimit.m0" required></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="2" md="2" sm="2" cols="12">
                <label class="custom-label">M+1 To M+2</label>
              </v-col>
              <v-col md="6" sm="6" cols="12">
                <v-text-field v-model="defiasiLimit.m1" required></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="2" md="2" sm="2" cols="12">
                <label class="custom-label">M+2</label>
              </v-col>
              <v-col md="6" sm="6" cols="12">
                <v-text-field v-model="defiasiLimit.m2" required></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-card-text>
  
      <template v-slot:append>
        <v-btn @click="submitForm" color="primary">
          {{ isAdding ? $t('button.save') : $t('button.update') }}
        </v-btn>
      </template>
    </v-card>
  </template>
  
  <script>
  import axios from '../plugins/axios';
  import Breadcrumbs from '@/components/Breadcrumbs.vue';
  import { mapActions } from 'vuex';
  import breadcrumbConfig from '@/others/breadcrumbConfig.js';
  
  export default {
    components: {
      Breadcrumbs
    },
    data() {
      return {
        defiasiLimit: {
          product_number: null,
          m0: null,
          m1: null,
          m2: null,
        },
        product: [],
        selectedProductId: null,
      };
    },
    computed: {
      isAdding() {
        return this.$route.params.action === 'add';
      },
      breadcrumbItems() {
        const action = this.$route.params.action;
        const route = this.$route.name;
        const items = breadcrumbConfig[route];
  
        if (typeof items === 'function') {
          return items(action);
        }
  
        return items || [];
      }
    },
    async mounted() {
      const { action, id } = this.$route.params;
  
      this.fetchProducts()
      
      // Fetch defiasiLimit details if editing
      if (action === 'edit' && id) {
        try {
          const response = await axios.get(`/defiation_limit/${id}`);
          this.defiasiLimit = response.data;
        } catch (error) {
          const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'An error occurred';
  
          this.showAlert({ message: errorMessage, color: 'error' });
        }
      }
    },
    methods: {
      ...mapActions(['showAlert']),
      async handleSubmit() {
        try {
          const defiasiLimitData = {
            sku: this.selectedProductId.sku,
            product_name: this.selectedProductId.product_name,
            m0: this.defiasiLimit.m0,
            m1: this.defiasiLimit.m1,
            m2: this.defiasiLimit.m2,
          };
  
          if (this.isAdding) {
            const response = await axios.post('/defiation_limit/', { defiation_limit: defiasiLimitData });
  
            if (response.data) {
              this.$router.push({ name: 'defiasiLimit' });
              this.showAlert({ message: 'Successfully added defiasiLimit data', color: 'success' });
            } else {
              this.showAlert({ message: "Failed", color: 'error' });
            }
          } else {
            const response = await axios.put(`/defiation_limit/${this.defiasiLimit.id}`, { defiation_limit: defiasiLimitData });
  
            if (response.data) {
              this.$router.push({ name: 'defiasiLimit' });
              this.showAlert({ message: 'Successfully added defiasiLimit data', color: 'success' });
            } else {
              this.showAlert({ message: 'Error updating defiasiLimit', color: 'error' });
            }
          }
        } catch (error) {
          const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'An error occurred';
  
          this.showAlert({ message: errorMessage, color: 'error' });
        }
      },
      submitForm() {
        this.$refs.form.validate(); // Trigger form validation
        this.handleSubmit(); // Call the submit method
      },
      async fetchProducts() {
        try {
          const response = await axios.get('/product');
          this.product = response.data;
        } catch (error) {
          const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'An error occurred';
  
          this.showAlert({ message: errorMessage, color: 'error' });
        }
      },
      itemProps (item) {
        return {
          title: item.product_name,
          subtitle: item.sku,
        }
      },
    }
  };
  </script>