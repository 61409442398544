<template>
    <Breadcrumbs :items="breadcrumbItems" />
    <v-card elevation="0" class="mt-2">
        <v-card-text>
            <v-row>
                <v-col cols="3">Rolling Forecast Number</v-col><v-col cols="8">:<v-btn variant="text" @click="navigateToDocument(approvalsData.document)">{{ approvalsData.document?.transaction_number || 'Loading...' }}</v-btn></v-col>
                <v-col cols="3">Status</v-col><v-col cols="8">:<strong>{{ approvalsData.status || 'Loading...' }}</strong></v-col>
                <v-col cols="3">Document Creation</v-col> <v-col cols="8">:<strong>{{ new Date(approvalsData.document?.created_at).toLocaleDateString('en-CA') || 'Loading...' }}</strong></v-col>
            </v-row>

            <!-- Stepper Row -->
            <v-row>
                <v-col
                    v-for="history in approvalsHistory"
                    :key="`${history.step}-content`"
                    cols="12" sm="4" md="4" lg="4"
                >
                    <v-card class="mx-auto card-bordered">
                        <template v-slot:title>
                            <div>
                            <span>Step: {{ history.step }}</span>
                            </div>
                        </template>

                        <v-card-text class="pt-4">
                            <v-row>
                                <v-col cols="4">Approver</v-col>
                                <v-col cols="8">{{ history.user.full_name }}</v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4">Role</v-col>
                                <v-col cols="8">{{  }}</v-col> 
                            </v-row>
                            <v-row>
                                <v-col cols="4">Coverage</v-col>
                                <v-col cols="8">{{  }}</v-col> 
                            </v-row>
                            <v-row>
                                <v-col><v-btn variant="tonal">Approve</v-btn></v-col>
                            </v-row> 
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import breadcrumbConfig from '@/others/breadcrumbConfig.js';
import { mapActions } from 'vuex';
import DataTable from '@/components/DataTable.vue';

export default {
    components: {
        Breadcrumbs,
        DataTable
    },
    data() {
        return {
            loading: false,
            approvalNeeded: {
                id: null,
                type: '',
                document: null,
                status: '',
            },
            headers: [
                { title: 'Name', key: 'name', align: 'start', clickable: true },
                { title: 'Status', key: 'status', align: 'start' },
                { title: '', key: 'actions', align: 'end', sortable: false },
            ],
            approvalsData: [],
            approvalsHistory: [],
            e1: 1,
        };
    },
    computed: {
        breadcrumbItems() {
            const action = this.$route.params.action;
            const route = this.$route.name;
            const items = breadcrumbConfig[route];

            if (typeof items === 'function') {
                return items(action);
            }

            return items || [];
        },
        disabled () {
            return this.e1 === 1 ? 'prev' : this.e1 === this.steps ? 'next' : undefined
        },
    },
    async mounted() {
        const { id } = this.$route.params;
        // Fetch approval audit trail here
        if (id) {
            this.fetchApprovalNeeded(id);
            this.fetchApprovalHistory(id);
        }
    },
    methods: {
        ...mapActions(['showAlert']),
        async fetchApprovalNeeded(id) {
            try {
                const response = await axios.get(`/approval_step/${id}`);
                this.approvalsData= response.data;
            } catch (error) {
                     const errorMessage = error.response?.data?.messages
                    ? error.response.data.messages
                    : error.response?.message || 'An error asdfasdf';

                this.showAlert({ message: errorMessage, color: 'error' });
            }
        },
        async fetchApprovalHistory(id) {
            try {
                const response = await axios.get(`/approval_history/${id}`);
                this.approvalsHistory = response.data;
            } catch (error) {
                     const errorMessage = error.response?.data?.messages
                    ? error.response.data.messages
                    : error.response?.message || 'An error asdfasdf';

                this.showAlert({ message: errorMessage, color: 'error' });
            }
        },
        navigateToDocument(doc) {
            const act = 'edit';
            this.$router.push({ name: 'rofo-view', params: { act, id: doc.id } });
        },
    },
};
</script>

<style scoped>
.custom-label {
    font-weight: bold;
    font-size: 11px;
}

.card-bordered {
    border: 1px solid #ccc; 
    border-radius: 8px; 
  }
</style>