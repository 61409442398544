<template>
  <Breadcrumbs :items="breadcrumbItems" />
  <v-card elevation="0" class="mt-2">
    <template v-slot:append>
      <v-btn class="text-none" color="primary" @click="goToCustomizePage('edit', uom)">
        Customize
      </v-btn>
    </template>

    <v-divider></v-divider>

    <v-card-text>
      <v-row>
        <v-col lg="2" md="2" sm="2" cols="12">
          <label class="custom-label">Name</label>
        </v-col>
        <v-col md="6" sm="6" cols="12">
          <div>{{ uom.name }}</div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

  <v-row class="text-caption mt-2">
    <v-col>
      <v-btn-toggle v-model="toggle" color="primary" variant="text">
          <v-btn height="5vh" size="x-small" density="compact" prepend-icon="mdi-history">History</v-btn>
      </v-btn-toggle>
    </v-col>
  </v-row>
  <v-row class="mt-0 px-4" v-if="toggle === 0">
    <v-timeline align="start" side="end">
      <v-timeline-item
        v-for="(item, index) in historyItems"
        :key="index"
        dot-color="white"
        size="x-small"
        density="compact"
      >
        <div class="d-flex">
          <div class="me-4 text-caption">{{ item.time }}</div>
          <div>
            <div class="text-caption">
              <div v-html="item.text"></div>
            </div>
          </div>
        </div>
      </v-timeline-item>
    </v-timeline>
  </v-row>
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import breadcrumbConfig from '@/others/breadcrumbConfig.js';
import { mapActions } from 'vuex';

export default {
  components: {
    Breadcrumbs,
  },
  data() {
    return {
      uom: {
        id: null,
        name: ''
      },
      historyItems: [],
      toggle: null,
    };
  },
  computed: {
    breadcrumbItems() {
      const action = this.$route.params.action;
      const route = this.$route.name;
      const items = breadcrumbConfig[route];

      if (typeof items === 'function') {
        return items(action);
      }

      return items || [];
    },
  },
  async mounted() {
    const { id } = this.$route.params;

    // Fetch uom details
    if (id) {
      try {
        const response = await axios.get(`/uom/${id}`);
        this.uom = response.data;
        this.historyItems = response.data.object_histories;
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    }
  },
  methods: {
    ...mapActions(['showAlert']),
    goToCustomizePage(action, uom) {
      this.$router.push({ name: 'uom-form', params: { action, id: uom.id } });
    },
  },
};
</script>

<style scoped>
.custom-label {
  font-weight: bold;
  font-size: 11px;
}
</style>
