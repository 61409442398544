<template>
    <Breadcrumbs :items="breadcrumbItems" />
    <v-row>
        <v-col cols="12" class="d-flex justify-end">
            <v-btn color="primary" @click="navigateToForm('add')">{{ $t('button.newCmo') }}</v-btn>
            <v-btn class="ml-2" :disabled="!canCombine()" color="primary" @click="combineDocument">{{ $t('button.combineDocument') }}</v-btn>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <v-layout class="overflow-visible" style="height: 40px;">
                <v-bottom-navigation v-model="value" active>
                    <v-btn @click="fetchCMO('Distributor')">Distributor (GT)</v-btn>
                    <v-btn @click="fetchCMO('Direct')">Direct (MT)</v-btn>
                    <v-btn @click="fetchCMO('CBD')">CBD</v-btn>
                </v-bottom-navigation>
            </v-layout>
        </v-col>
    </v-row>
    <!-- <v-row class="d-flex flex-row-reverse"> -->
    <v-row>
        <v-col cols="2">
            <v-select disabled bg-color="white" v-model="filter.type" :items="cmoTypes" label="Type"></v-select>
        </v-col>
        <v-col cols="2">
            <v-text-field disabled bg-color="white" placeholder="" density="compact" v-model="filterParams"
                append-inner-icon="mdi-magnify"></v-text-field>
        </v-col>
        <v-col cols="2">
            <v-text-field
              disabled
              bg-color="white"
              v-model="filter.start"
              label="Start date"
              type="date"
            ></v-text-field>
        </v-col>
        <v-col cols="2">
            <v-text-field
              disabled
              bg-color="white"
              v-model="filter.end"
              label="End date"
              type="date"
            ></v-text-field>
        </v-col>
        <v-col cols="2">
            <v-select disabled bg-color="white" v-model="filter.status" :items="statusOption" label="Status"></v-select>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <v-data-table
                v-model="selected"
                :items="cmo"
                 return-object
                :headers="headers"
                show-select
            >
               
              <template v-for="header in headers" v-slot:[`item.${header.key}`]="{ item }">
                  <template v-if="header.clickable">
                      <v-btn variant='text' color="primary" style="font-weight: bold;" @click="navigateToForm('view', item)" class="pa-0 text-start align-start" density="compact">
                          {{ item[header.key] }}
                      </v-btn>
                  </template>
                  <template v-else>
                      
                      <div v-if="header.key === 'status'">
                        <div v-if="item.status !== null">
                            <v-chip :color="getStatusColor(item.status)" variant="flat" size="x-small">
                                {{ item.status }}
                            </v-chip>
                        </div>
                      </div>
                      <div v-else>
                        {{ item[header.key] }}
                      </div>
                  </template>
              </template>
            </v-data-table>
        </v-col>
    </v-row>

    <v-overlay
			:model-value="loading"
			class="align-center justify-center"
			persistent
		>
			<v-progress-circular
				color="primary"
				size="120"
				indeterminate
			></v-progress-circular>
		</v-overlay>
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import breadcrumbConfig from '@/others/breadcrumbConfig';
import { mapActions } from 'vuex';
import DataTable from '@/components/DataTable.vue';

export default {
    components: {
        Breadcrumbs,
        DataTable
    },
    data() {
        return {
            value: 0,
            loading: false,
            filter : {
                type: '',
            },
            cmoTypes: ['Full Shipment', 'Multi Drop'],
            statusOption: ['Awaiting Approval', 'Draft', 'Submitted', 'Approved'],
            headers: [
                { title: 'Transaction Number', key: 'transaction_number', align: 'start', clickable: true, sortable: true },
                { title: 'Period', key: 'period', align: 'start', sortable: true },
                { title: 'Transaction Date', key: 'transaction_date', align: 'start', sortable: true },
                { title: 'Shipment Type', key: 'shipment_type', align: 'start', sortable: true },
                { title: 'Ship To', key: 'ship_to', align: 'start', sortable: true },
                { title: 'Created By', key: 'owner_name', align: 'start', sortable: true },
                { title: 'Status', key: 'status', align: 'start', sortable: true },
            ],
            cmo: [],
            tableActions: {
                // edit: this.navigateToForm,
                // delete: this.deleteUser,
                view: this.navigateToForm
            },
            selected: [],
        };
    },
    computed: {
        breadcrumbItems() {
            const route = this.$route.name;
            const action = this.$route.params.action;
            const breadcrumbItems = breadcrumbConfig[route];

            // Handle case where the route is dynamic, e.g., 'user-form'
            if (typeof breadcrumbItems === 'function') {
                return breadcrumbItems(action);
            }

            return breadcrumbItems || [];
        }
    },
    methods: {
        ...mapActions(['showAlert']),
        async fetchCMO(type) {
            this.loading = true;
            try {
                const response = await axios.get(`/confirm_monthly_order?type=${type}`);
                this.cmo = response.data;
                this.loading = false;
            } catch (error) {
                const errorMessage = error.response?.data?.messages
                    ? error.response.data.messages
                    : error.response?.message || 'An error occurred';

                this.showAlert({ message: errorMessage, color: 'error' });
                this.loading = false;
            }
        },
        navigateToForm(action, cmo = { id: null }) {
            if (action == 'view') {
                this.$router.push({ name: 'cmo-view', params: { action, id: cmo.id } });
            } else {
                this.$router.push({ name: 'cmo-form', params: { action, id: cmo.id } });
            }
        },
        async deleteUser(userToDelete) {
            try {
                const response = await axios.delete(`/cmo/${userToDelete.id}`);

                if (response.data) {
                    this.cmo = this.cmo.filter(u => u.id !== userToDelete.id);

                    this.showAlert({ message: 'Success', color: 'success' });
                } else {
                    throw new Error('Failed to delete user');
                }
            } catch (error) {
                const errorMessage = error.response?.data?.messages
                    ? error.response.data.messages
                    : error.response?.message || 'An error occurred';

                this.showAlert({ message: errorMessage, color: 'error' });
            }
        },
        combineDocument() {
          const selectedIds = this.selected.map(item => item.id); // Extract IDs from selected objects
          this.$router.push({ 
            name: 'cmo-form', 
            params: { 
              action: 'combine', 
              id: null, 
              cmo_ids: JSON.stringify(selectedIds)
            } 
          });
        },
        handleAction(actionName, item) {
            if (this.actions[actionName]) {
                if (actionName == 'edit') {
                    this.actions[actionName]('edit', item)
                } else if (actionName == 'view') {
                    this.actions[actionName]('view', item)
                } else {
                    this.actions[actionName](item);
                }
            }
        },
        getStatusColor(status) {
          if(status !== null){
            switch (status.toLowerCase()) {
            case 'active':
                return 'green';
            case 'inactive':
                return 'red';
            case 'draft':
                return 'yellow';
            case 'pending':
                return 'orange';
            case 'request for revise':
                return 'orange';
            case 'awaiting for approval':
                return 'primary';
            case 'approved':
                return 'green';
            default:
                return 'primary';
            }
          } else {
              return null;
          }
        },
        canCombine() {
          if (this.selected.length < 2) return false;

          if (this.selected.some(item => item.shipment_type === 'Full Shipment')) return false;
          
          const firstShipmentType = this.selected[0].shipment_type;
          return this.selected.every(item => item.shipment_type === firstShipmentType);
        },
    },
    mounted() {
        this.fetchCMO('Distributor');
    }
};
</script>

<style scoped>
:deep(.v-bottom-navigation__content) {
    justify-content: left !important;
}
</style>