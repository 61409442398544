<template>
  <!-- <div v-if="!havePermission">
    <v-empty-state action-text="Back to Home Page"
      image="https://cdn.vuetifyjs.com/docs/images/components/v-empty-state/connection.svg"
      text="There might be a problem with your connection or permission to access this page. Please check your internet connection or contact your administrator."
      title="Something Went Wrong" @click:action="backToHome"></v-empty-state>
  </div> -->
  <!-- <div v-else> -->
    <Breadcrumbs :items="breadcrumbItems" />
    <v-row>
      <v-col cols="12" class="d-flex justify-end">
        <v-btn color="primary" @click="navigateToForm('add')">{{ $t('button.newTransactionType') }}</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <data-table :headers="headers" :items="transactionTypes" :actions="tableActions" />
      </v-col>
    </v-row>
  <!-- </div> -->
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import breadcrumbConfig from '@/others/breadcrumbConfig';
import { mapActions } from 'vuex';
import DataTable from '@/components/DataTable.vue';

export default {
  components: {
    Breadcrumbs,
    DataTable
  },
  data() {
    return {
      headers: [
        { title: 'Name', key: 'name', align: 'start', sortable: true },
        // { title: 'Module', key: 'modules', align: 'start', width:'200px' },
        { title: '', key: 'actions', align: 'end', sortable: false },
      ],
      havePermission: false,
      transactionTypes: [],
      tableActions: {
        edit: this.navigateToForm,
        delete: this.deleteTransactionType
      }
    };
  },
  computed: {
    breadcrumbItems() {
      const route = this.$route.name;
      const action = this.$route.params.action;
      const breadcrumbItems = breadcrumbConfig[route];

      if (typeof breadcrumbItems === 'function') {
        return breadcrumbItems(action);
      }

      return breadcrumbItems || [];
    }
  },
  methods: {
    ...mapActions(['showAlert']),
    async fetchTransactionType() {
      try {
        const response = await axios.get('/transaction_type');
        this.transactionTypes = response.data;
        this.havePermission = true;
      } catch (error) {
        const errorMessage = error.response?.data?.messages 
                            ? error.response.data.messages 
                            : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    navigateToForm(action, transactionType = { id: null }) {
      this.$router.push({ name: 'transactionType-form', params: { action, id: transactionType.id } });
    },
    async deleteTransactionType(transactionTypeToDelete) {
      try {
        const response = await axios.delete(`/transaction_type/${transactionTypeToDelete.id}`);

        if (response.data) {
          this.transactionTypes = this.transactionTypes.filter(u => u.id !== transactionTypeToDelete.id);
          this.showAlert({ message: 'Transaction type deleted successfully', color: 'success' });
        } else {
          throw new Error('Failed to delete transaction type');
        }
      } catch (error) {
        const errorMessage = error.response?.data?.messages 
                            ? error.response.data.messages 
                            : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    backToHome() {
      this.$router.push("/home")
    }
  },
  mounted() {
    this.fetchTransactionType();
  }
};
</script>