<template>
	<Breadcrumbs :items="breadcrumbItems" />
	<v-card>
		<template v-slot:title>
			<!-- Show Head Tabs if the rofo type is NOT District -->
			<v-row class="py-3" v-if="rofoType !== 'District'">
				<v-tabs v-model="selectedHeadTab" bg-color="white" show-arrows
				@update:modelValue="(value) => updateHeadTabValue(value, headTabsData.findIndex(tab => tab === value))">
					<v-tab v-for="(data, index) in headTabsData" :key="data" :text="data.name" :class="{'inactive-tab': selectedHeadTab !== index}" 
						:value="data"></v-tab>
				</v-tabs>
			</v-row>
			<div>
				<v-chip class="ma-2" label>
          <v-icon icon="mdi-account" start></v-icon>
          {{ "Created By: " + rollingForecastParams.owner_name }}
        </v-chip>
        <v-chip class="ma-2" label>
          <v-icon icon="mdi-label" start></v-icon>
          {{ rollingForecastParams.status }}
        </v-chip>
        <v-chip v-if="discretionSubmitted" class="ma-2" color="orange" label>
          <v-icon icon="mdi-alert-octagon" start></v-icon>
          Discretion Submitted
        </v-chip>
      </div>
		</template>
    
    <v-card-text v-if="rollingForecastParams.revise_remark">
      <template v-if="rollingForecastParams.revise_remark">
        <v-alert
          type="info"
          variant="tonal"
          dense
          outlined>
          <strong>Revision Notes:</strong> 
          <p class="text-subtitle-1">{{ rollingForecastParams.revise_remark }}</p>
        </v-alert>
      </template>
    </v-card-text>
    
		<v-divider></v-divider>

		<v-card-text>
			<div>
				<v-form ref="form" @submit.prevent="handleSubmit">
					<v-row>
            <v-col md="4" sm="4" cols="12">
							<v-row>
								<v-col>
									<label class="custom-label">Transaction Type</label>
								</v-col>
								<v-col>
									<v-select :readonly="mode === 0 && selectedHeadTab !== 0 || approvedDocument" v-model="rollingForecastParams.transaction_type_id"
										:items="transactionTypes" item-value="id" item-title="name" required></v-select>
								</v-col>
							</v-row>
						</v-col>
						<v-col md="4" sm="4" cols="12">
							<v-row>
								<v-col>
									<label class="custom-label">Area</label>
								</v-col>
								<v-col>
									<v-text-field :readonly="mode === 0" v-model="rollingForecastParams.area"
										required></v-text-field>
								</v-col>
							</v-row>
						</v-col>
						<v-col md="4" sm="4" cols="12">
							<v-row>
								<v-col>
									<label class="custom-label">Transaction Period</label>
								</v-col>
								<v-col>
									<v-select readonly v-model="rollingForecastParams.period_id"
										:items="periods" item-value="id" item-title="title" required></v-select>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
					<v-row>
						<v-col md="4" sm="4" cols="12">
							<v-row>
								<v-col>
									<label class="custom-label">Transaction Number</label>
								</v-col>
								<v-col>
									<v-text-field readonly placeholder="Autonumber"
										v-model="rollingForecastParams.transaction_number" required></v-text-field>
								</v-col>
							</v-row>
						</v-col>
						<v-col md="4" sm="4" cols="12">
							<v-row>
								<v-col>
									<label class="custom-label">Region</label>
								</v-col>
								<v-col>
									<v-text-field readonly v-model="rollingForecastParams.region"
										required></v-text-field>
								</v-col>
							</v-row>
						</v-col>
						<v-col md="4" sm="4" cols="12">
							<v-row>
								<v-col>
									<label class="custom-label">View Mode</label>
								</v-col>
								<v-col>
									<v-select :readonly="mode === 0 && selectedHeadTab !== 0" v-model="rollingForecastParams.viewMode"
									:items="viewTypes" @update:modelValue="changeViewMode($event)"></v-select>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
					<!-- <v-row v-if="rollingForecastParams.revise_remark">
						<v-col md="4" sm="4" cols="12">
							<v-row>
								<v-col>
									<label class="custom-label">Remark Revise</label>
								</v-col>
								<v-col>
									<v-textarea readonly v-model="rollingForecastParams.revise_remark" rows="2"></v-textarea>
								</v-col>
							</v-row>
						</v-col>
					</v-row> -->
				</v-form>
			</div>
		</v-card-text>

		<template v-slot:append>
			<v-btn color="primary" class="ml-2" @click="submitDiscretion" v-if="showDiscretion" :disabled="discretionSubmitted">Submit Discretion</v-btn>
			<v-btn color="primary" class="ml-2" append-icon="mdi-chevron-down" v-if="discretionToApprove">
				Update Discretion

				<v-menu activator="parent" density="compact">
					<v-list density="compact">
						<v-list-item density="compact" title="Approve Discretion" value="edit" @click="updateDiscretion(1)"></v-list-item>
						<v-list-item density="compact" title="Reject Discretion" value="edit" @click="updateDiscretion(0)"></v-list-item>
					</v-list>
				</v-menu>
			</v-btn>

			<v-btn color="primary" class="ml-2" @click="submitForm(2)" :disabled="!asApprover || !awaitingApproval || showDiscretion">Approve</v-btn>
      <v-btn color="primary" class="ml-2" @click="submitForm(0)" :disabled="!draftDocument">Save</v-btn>
      <v-btn color="primary" class="ml-2" @click="submitForm(1)" :disabled="!draftDocument">Submit</v-btn>
			<v-btn color="primary" class="ml-2" append-icon="mdi-chevron-down" :disabled="(!asApprover && !requestRevise) || discretionSubmitted">
				Options

				<v-menu activator="parent" density="compact">
					<v-list density="compact">
						<!-- <v-list-item density="compact" title="Edit" value="edit" @click="updateMode"></v-list-item> -->
						<v-list-item density="compact" title="Request for Revise" value="edit" @click="requestForRevise"></v-list-item>
					</v-list>
				</v-menu>
			</v-btn>
		</template>
	</v-card>

	<div class="pa-3"></div>

	<v-card>
		<template v-slot:title>
			<v-row>
				<v-tabs v-model="this.selectedTab" bg-color="white" show-arrows
					@update:modelValue="updateTabValue($event)">
					<v-tab v-for="(data, index) in tabsData" :key="data" :text="data.name"
						:value="(data, index)"></v-tab>
				</v-tabs>
			</v-row>

			<v-row>
				<v-col>
					<v-tooltip v-model="tooltipShow" location="end" theme="light">
						<template v-slot:activator="{ props }">
							<v-btn v-bind="props" class="mx-1" color="primary" variant="outlined"
								prepend-icon="mdi-refresh" @click="populateData(mainRollingForecastId)">
								Refresh Item
							</v-btn>
						</template>
						<span>Please refresh data to get the latest Product Item</span>
					</v-tooltip>

					<!-- <v-btn class="mx-1" color="primary" variant="outlined" prepend-icon="mdi-view-list-outline">
						List View
					</v-btn> -->

					<v-btn class="mx-1" color="primary" variant="outlined" prepend-icon="mdi-fullscreen"
						@click="toggleFullScreen">
						Fullscreen
					</v-btn>
				</v-col>
				<v-spacer></v-spacer>

				<v-col cols="4">
					<v-text-field placeholder="Search" v-model="search" density="compact" append-inner-icon="mdi-magnify"></v-text-field>
				</v-col>
			</v-row>
		</template>

		<v-divider></v-divider>

		<v-card-text>
			<v-data-table style="display: none;" v-if="isDataReady && products" hide-default-footer fixed-header :headers="headers"
				:items="products[selectedTab].rolling_forecast_product_details_attributes" item-key="product.id"
				:loading="loading" class="frozen-columns-table" :items-per-page="100" :search="search" >
				<template v-slot:item.index="{ index }">
					{{ index + 1 }}
				</template>

				<template v-slot:item.stock="{ item }">
					<!-- <input :readonly="mode === 0" type="text" v-model.number="item.stock"
						@input="updateValue(item, 'doi')" class="custom-input text-center" /> -->
					<div v-if="mode === 0 || selectedTab === 0 || isNotDistrict || awaitingApproval || approvedDocument " class="text-right">
						{{ formatNumber(item.stock) }}
					</div>
					<div v-else>
						<vue-number v-model="item.stock" v-bind="number" @change="updateValue(item, 'doi')" class="custom-input text-right ma-2"></vue-number>
					</div>
				</template>

				<template v-slot:item.avg_sol3m="{ item }">
					<div class="text-right">
						{{ formatNumber(item.avg_sol3m) }}
					</div>
				</template>

				<template v-slot:item.doi="{ item }">
					<div class="text-right">
						{{ item.doi }}
					</div>
				</template>

				<!-- Define slot for qty_m0 -->
				<template v-slot:item.m0_qty="{ item }">
					<!-- <input :readonly="mode === 0" type="text" v-model.number="item.m0_qty"
						@input="updateValue(item, 'm0')" class="custom-input text-center" /> -->
					<div v-if="mode === 0 || selectedTab === 0 || isNotDistrict || awaitingApproval || approvedDocument" class="text-right">
						{{ viewMode === 'Qty' ? formatNumber(item.m0_qty) : formatNumber(item.m0_value) }}
					</div>
					<div v-else>
						<vue-number v-model="item.m0_qty" v-bind="number" @change="updateValue(item, 'm0')" class="custom-input text-right"></vue-number>
					</div>
				</template>

				<template v-slot:item.m0_defiation="{ item }">
					<div :class="(item.m0_defiation < item.m0_defiation_limit) && (item.m0_defiation > (0 - item.m0_defiation_limit)) ? '' : 'invalid-mark'" class="text-right">
            			{{ formatNumber(item.m0_defiation * 100) + '%' }} 
					</div>
				</template>

				<template v-slot:item.m0_percent_to_target="{ item }">
					<div :class="item.m0_percent_to_target < item.target ? 'invalid-mark' : ''" class="text-right">
            			{{ formatNumber(item.m0_percent_to_target * 100) + '%' }} 
					</div>
				</template>

				<template v-slot:item.m0_est_ytd_actual="{ item }">
          			{{ formatNumber(item.m0_est_ytd_actual * 100) + '%' }} 
				</template>

				<template v-slot:item.l3m_forecast_accuracy="{ item }">
					{{ formatNumber(item.l3m_forecast_accuracy * 100) + '%' }} 
				</template>

				<!-- Define slot for qty_m1 -->
				<template v-slot:item.m1_qty="{ item }">
					<!-- <input :readonly="mode === 0" type="text" v-model.number="item.m1_qty"
						@input="updateValue(item, 'm1')" class="custom-input text-center" /> -->
					<div v-if="mode === 0 || selectedTab === 0 || isNotDistrict || awaitingApproval ||approvedDocument" class="text-right">
						{{ viewMode === 'Qty' ? formatNumber(item.m1_qty) : formatNumber(item.m1_value) }}
					</div>
					<div v-else>
						<vue-number v-model="item.m1_qty" v-bind="number" @change="updateValue(item, 'm1')" class="custom-input text-right"></vue-number>
					</div>
				</template>

				<template v-slot:item.m1_defiation="{ item }">
					<div :class="(item.m1_defiation < item.m1_defiation_limit) && (item.m1_defiation > (0 - item.m1_defiation_limit)) ? '' : 'invalid-mark'" class="text-right">
            			{{ formatNumber(item.m1_defiation * 100) + '%' }}  
					</div>
				</template>

				<template v-slot:item.m1_percent_to_target="{ item }">
					<div :class="item.m1_percent_to_target < item.target ? 'invalid-mark' : ''" class="text-right">
            			{{ formatNumber(item.m1_percent_to_target * 100) + '%' }}  
					</div>
				</template>

				<template v-slot:item.m1_est_ytd_actual="{ item }">
          			{{ formatNumber(item.m1_est_ytd_actual * 100) + '%' }}  
				</template>

				<template v-slot:item.l2m_forecast_accuracy="{ item }">
					{{ formatNumber(item.l2m_forecast_accuracy * 100) + '%' }} 
				</template>

				<!-- Define slot for qty_m2 -->
				<template v-slot:item.m2_qty="{ item }">
					<!-- <input :readonly="mode === 0" type="text" v-model.number="item.m2_qty"
						@input="updateValue(item, 'm2')" class="custom-input text-center" /> -->
					<div v-if="mode === 0 || selectedTab === 0 || isNotDistrict || awaitingApproval || approvedDocument" class="text-right">
						{{ viewMode === 'Qty' ? formatNumber(item.m2_qty) : formatNumber(item.m2_value) }}
					</div>
					<div v-else>
						<vue-number v-model="item.m2_qty" v-bind="number" @change="updateValue(item, 'm2')" class="custom-input text-right"></vue-number>
					</div>
				</template>

				<template v-slot:item.m2_percent_to_target="{ item }">
					<div :class="item.m2_percent_to_target < item.target ? 'invalid-mark' : ''" class="text-right">
						{{ formatNumber(item.m2_percent_to_target * 100) + '%' }}  
					</div>
				</template>

				<template v-slot:item.m2_est_ytd_actual="{ item }">
					{{ formatNumber(item.m2_est_ytd_actual * 100) + '%' }}  
				</template>

        <template v-slot:item.lm_forecast_accuracy="{ item }">
					{{ formatNumber(item.lm_forecast_accuracy * 100) + '%' }} 
				</template>

				<template v-slot:body.append>
					<tr class="elevation-0">
						<td colspan="6" style="background-color:lightgray;"></td>
						<td class="fixed-column total-label elevation-0 text-weight-bold">Grand Total</td>
						<td class="fixed-column elevation-0">{{ formatNumber(grandTotal[selectedTab].stock) }}</td>
						<td class="fixed-column elevation-0">{{ formatNumber(grandTotal[selectedTab].avg_sol3m) }}</td>
						<td class="fixed-column elevation-0">{{ formatNumber(grandTotal[selectedTab].doi)}}</td>
						<td class="fixed-column elevation-0">{{ viewMode === 'Qty' ? formatNumber(grandTotal[selectedTab].m0_qty) : formatNumber(grandTotal[selectedTab].m0_value) }}</td>
						<td colspan="2" style="background-color:lightgray;" class="fixed-column elevation-0"></td>
						<td class="fixed-column elevation-0">{{ viewMode === 'Qty' ? formatNumber(grandTotal[selectedTab].m1_qty) : formatNumber(grandTotal[selectedTab].m1_value) }}</td>
						<td colspan="2" style="background-color:lightgray;" class="fixed-column elevation-0"></td>
						<td class="fixed-column elevation-0">{{ viewMode === 'Qty' ? formatNumber(grandTotal[selectedTab].m2_qty) : formatNumber(grandTotal[selectedTab].m2_value) }}</td>
						<td colspan="7" style="background-color:lightgray;" class="fixed-column elevation-0"></td>
					</tr>
				</template>
			</v-data-table>

			<div v-if="products && products[selectedTab] && products[selectedTab].rolling_forecast_product_details_attributes" class="mt-5">
				<div class="table-wrapper">
					<table class="custom-table">
						<thead>
							<!-- First row of headers -->
							<tr>
								<th rowspan="2">No</th>
								<th rowspan="2" class="">Product Division</th>
								<th rowspan="2" class="">Product CAT.1</th>
								<th rowspan="2" class="">Product CAT.2</th>
								<th rowspan="2" class="">SKU</th>
								<th rowspan="2" class="">Product Name</th>
								<th rowspan="2" class="">Product Nickname</th>
								<th rowspan="2" class="">Stock</th>
								<th rowspan="2" class="">Avg SOL 3M</th>
								<th rowspan="2" class="">DOI</th>
								<th colspan="3" class=" text-center">{{ periodHeaders[0].title }}</th>
								<th colspan="3" class=" text-center">{{ periodHeaders[1].title }}</th>
								<th colspan="2" class=" text-center">{{ periodHeaders[2].title }}</th>
								<th colspan="3" class="">Est. YTD Acv</th>
								<th colspan="3" class="">Forecast Accuracy (L3M)</th>
							</tr>
							<!-- Second row of headers -->
							<tr>
								<!-- M0 -->
								<th class="">Qty</th>
								<th class="">Defiasi</th>
								<th class="">% to Target</th>
								<!-- M1 -->
								<th class="">Qty</th>
								<th class="">Defiasi</th>
								<th class="">% to Target</th>
								<!-- M2 -->
								<th class="">Qty</th>
								<th class="">% to Target</th>
								<!-- Est. YTD Acv -->
								<th>{{ periodHeaders[0].title.substring(0, 3) }}</th>
								<th>{{ periodHeaders[1].title.substring(0, 3) }}</th>
								<th>{{ periodHeaders[2].title.substring(0, 3) }}</th>
								<!-- Forecast Accuracy -->
								<th>{{ this.lastThreeMonths[0]}}</th>
								<th>{{ this.lastThreeMonths[1]}}</th>
								<th>{{ this.lastThreeMonths[2]}}</th>
							</tr>
						</thead>
						<tbody>
							<!-- Iterate through grouped data -->
							<template v-for="(group, groupIndex) in groupedProducts" :key="groupIndex">
								<!-- Category Group Rows -->
								<template v-if="!group.isDivisionTotal">
									<tr v-for="(item, index) in group.items" :key="index">
										<td>{{ index + 1 }}</td>
										<td class=" ">{{ item.product.product_division }}</td>
										<td class="">{{ item.product.product_category }}</td>
										<td class="">{{ item.product.product_category_2 }}</td>
										<td class="">{{ item.product.sku }}</td>
										<td class="">{{ item.product.description }}</td>
										<td class=" ">{{ item.product.product_name }}</td>
										<!-- Stock -->
										<td>
											<div v-if="mode === 0 || selectedTab === 0 || isNotDistrict" class="text-right py-2">
												{{ formatNumber(item.stock) }}
											</div>
											<div v-else>
												<vue-number
													v-model="item.stock"
													v-bind="numberProps"
													@change="updateValue(item, 'stock')"
													class="custom-input text-right ma-2"
												></vue-number>
											</div>
										</td>
										<td class="text-right">{{ formatNumber(item.avg_sol3m) }}</td>
										<td class="text-right">{{ formatNumber(item.doi) }}</td>
										<td>
											<div v-if="mode === 0 || selectedTab === 0 || isNotDistrict" class="text-right">
												{{ formatNumber(item.m0_qty) }}
											</div>
											<div v-else>
												<vue-number
													v-model="item.m0_qty"
													v-bind="numberProps"
													@change="updateValue(item, 'm0_qty')"
													class="custom-input text-right py-2"
												></vue-number>
											</div>
										</td>
										<td class="text-right">{{ formatNumber(item.m0_defiation * 100) + '%' }}</td>
										<td class="text-right">{{ formatNumber(item.m0_percent_to_target * 100) + '%' }}</td>
										<td>
											<div v-if="mode === 0 || selectedTab === 0 || isNotDistrict" class="text-right">
												{{ formatNumber(item.m1_qty) }}
											</div>
											<div v-else>
												<vue-number
													v-model="item.m1_qty"
													v-bind="numberProps"
													@change="updateValue(item, 'm1_qty')"
													class="custom-input text-right py-2"
												></vue-number>
											</div>
										</td>
										<td class="text-right">{{ formatNumber(item.m1_defiation * 100) + '%' }}</td>
										<td class="text-right">{{ formatNumber(item.m1_percent_to_target * 100) + '%' }}</td>
										<td>
											<div v-if="mode === 0 || selectedTab === 0 || isNotDistrict" class="text-right">
												{{ formatNumber(item.m2_qty) }}
											</div>
											<div v-else>
												<vue-number
													v-model="item.m2_qty"
													v-bind="numberProps"
													@change="updateValue(item, 'm2_qty')"
													class="custom-input text-right py-2"
												></vue-number>
											</div>
										</td>
										<td class="text-right">{{ formatNumber(item.m2_percent_to_target * 100) + '%' }}</td>
										<td class="text-right">{{ formatNumber(item.m0_est_ytd_actual * 100) + '%' }}</td>
										<td class="text-right">{{ formatNumber(item.m1_est_ytd_actual * 100) + '%'}}</td>
										<td class="text-right">{{ formatNumber(item.m2_est_ytd_actual * 100) + '%'}}</td>
										<td class="text-right">{{ formatNumber(item.l3m_forecast_accuracy_oct * 100) + '%'}}</td>
										<td class="text-right">{{ formatNumber(item.l3m_forecast_accuracy_nov * 100) + '%'}}</td>
										<td class="text-right">{{ formatNumber(item.l3m_forecast_accuracy_dec * 100) + '%'}}</td>
									</tr>
									<!-- Subtotal Row -->
									<tr class="subtotal">
										<!-- Do Not Using Colspan -->
										<td class="empty-column"></td>
										<td class="empty-column"></td>
										<td class="empty-column"></td>
										<td class="empty-column"></td>
										<td class="empty-column"></td>
										<td class="empty-column"></td>
										<td class="text-right">TOTAL {{ group.category }}</td>
										<td class="text-right"><strong>{{ formatNumber(group.totals.stock) }}</strong></td>
										<td class="text-right"><strong>{{ formatNumber(group.totals.avg_sol3m) }}</strong></td>
										<td class="text-right"><strong>{{ formatNumber(group.totals.doi) }}</strong></td>
										<td class="text-right"><strong>{{ formatNumber(group.totals.m0_qty) }}</strong></td>
										<td class="empty-column"></td>
										<td class="empty-column"></td>
										<td class="text-right"><strong>{{ formatNumber(group.totals.m1_qty) }}</strong></td>
										<td class="empty-column"></td>
										<td class="empty-column"></td>
										<td class="text-right"><strong>{{ formatNumber(group.totals.m2_qty) }}</strong></td>
										<td class="empty-column"></td>
										<td colspan="6" class="empty-column"></td>
									</tr>
								</template>
								<!-- Division Total Row -->
								<template v-if="group.isDivisionTotal">
									<tr class="division-total">
										<!-- Do Not Using Colspan -->
										<td class="empty-column"></td>
										<td class="empty-column"></td>
										<td class="empty-column"></td>
										<td class="empty-column"></td>
										<td class="empty-column"></td>
										<td class="empty-column"></td>
										<td class="text-right"><strong>{{ group.category }}</strong></td>
										<td class="text-right"><strong>{{ formatNumber(group.totals.stock) }}</strong></td>
										<td class="text-right"><strong>{{ formatNumber(group.totals.avg_sol3m) }}</strong></td>
										<td class="text-right"><strong>{{ formatNumber(group.totals.doi) }}</strong></td>
										<td class="text-right"><strong>{{ formatNumber(group.totals.m0_qty) }}</strong></td>
										<td class="empty-column"></td>
										<td class="empty-column"></td>
										<td class="text-right"><strong>{{ formatNumber(group.totals.m1_qty) }}</strong></td>
										<td class="empty-column"></td>
										<td class="empty-column"></td>
										<td class="text-right"><strong>{{ formatNumber(group.totals.m2_qty) }}</strong></td>
										<td class="empty-column"></td>
										<td colspan="6" class="empty-column"></td>
									</tr>
								</template>
							</template>
						</tbody>

						<tfoot>
							<tr>
								<!-- Do Not Using Colspan -->
								<td class="empty-column"></td>
								<td class="empty-column"></td>
								<td class="empty-column"></td>
								<td class="empty-column"></td>
								<td class="empty-column"></td>
								<td class="empty-column"></td>
								<td class="text-right"><strong>Grand Total</strong></td>
								<td class="text-right">{{ formatNumber(grandTotal[selectedTab].stock) }}</td>
								<td class="text-right">{{ formatNumber(grandTotal[selectedTab].avg_sol3m) }}</td>
								<td class="text-right">{{ formatNumber(grandTotal[selectedTab].doi) }}</td>
								<td class="text-right">{{ formatNumber(grandTotal[selectedTab].m0_qty) }}</td>
								<td class="empty-column"></td>
								<td class="empty-column"></td>
								<td class="text-right">{{ formatNumber(grandTotal[selectedTab].m1_qty) }}</td>
								<td class="empty-column"></td>
								<td class="empty-column"></td>
								<td class="text-right">{{ formatNumber(grandTotal[selectedTab].m2_qty) }}</td>
								<td class="empty-column"></td>
								<td colspan="6" class="empty-column"></td>
							</tr>
						</tfoot>
					</table>
				</div>
			</div>
		</v-card-text>

		<template v-slot:append>
		</template>
	</v-card>

	<v-row class="text-caption mt-2">
      <!-- <v-col>History and Notes</v-col> -->
      <v-col>
          <v-btn-toggle v-model="toggle" color="primary" variant="text">
              <v-btn height="5vh" size="x-small" density="compact" prepend-icon="mdi-history">History</v-btn>
              <v-btn height="5vh" size="x-small" density="compact" prepend-icon="mdi-note">Notes</v-btn>
          </v-btn-toggle>
      </v-col>
  </v-row>
  <v-row class="mt-0 px-4" v-if="toggle === 0">
    <v-timeline align="start" side="end">
      <v-timeline-item
        v-for="(item, index) in historyItems"
        :key="index"
        dot-color="white"
        size="x-small"
        density="compact"
      >
        <div class="d-flex">
          <div class="me-4 text-caption">{{ item.time }}</div>
          <div>
            <div class="text-caption">
              <div v-html="item.text"></div>
            </div>
          </div>
        </div>
      </v-timeline-item>
    </v-timeline>
  </v-row>
  <v-row class="mt-0 px-4" v-if="toggle === 1">
    <v-timeline align="start" side="end">
      <v-timeline-item dot-color="white" size="x-small" density="compact">
        <QuillEditor ref="myEditor" theme="snow" toolbar="essential" v-model="notesInput" />
        <v-btn class="my-2" @click="postNote">Post</v-btn>
      </v-timeline-item>

      <v-timeline-item
        v-for="(item, index) in notesItems"
        :key="index"
        dot-color="white"
        size="x-small"
        density="compact"
      >
        <div class="d-flex">
          <div class="me-4 text-caption">{{ item.time }}</div>
          <div>
            <div class="text-caption">{{ item.text }}</div>
          </div>
        </div>
      </v-timeline-item>
    </v-timeline>
  </v-row>

	<v-dialog v-model="reviseRemarkDialog" max-width="500">
		<template v-slot:default="{ isActive }">
			<v-card rounded="lg">
				<v-card-title class="d-flex justify-space-between align-center">
					<div class="text-medium-emphasis ps-2">
						Revise Remarks
					</div>

					<v-btn
						icon="mdi-close"
						variant="text"
						@click="isActive.value = false"
					></v-btn>
				</v-card-title>

				<v-divider class="mb-4"></v-divider>

				<v-card-text>
					<div class="mb-2">Message</div>
					<v-textarea
						:counter="300"
						class="mb-2"
						rows="2"
						variant="outlined"
						persistent-counter
						v-model="rollingForecastParams.revise_remark"
					></v-textarea>

					<div class="text-red mb-1">
						Caution: This action cannot be undone after you click the Save button.
          </div>
				</v-card-text>

				<v-divider class="mt-2"></v-divider>

				<v-card-actions class="my-2 d-flex justify-end">
					<v-btn
						class="text-none"
						text="Cancel"
						@click="isActive.value = false"
					></v-btn>

					<v-btn
						class="text-none"
						color="primary"
						text="Save & Revise"
						variant="flat"
						@click="reviseData"
					></v-btn>
				</v-card-actions>
			</v-card>
		</template>
	</v-dialog>


	<!-- Full Screen code -->
	<v-bottom-sheet v-model="isBottomSheetOpen" fullscreen>
		<v-card>
			<template v-slot:title>
				<v-row>
					<v-tabs v-model="this.selectedTab" bg-color="white" show-arrows
						@update:modelValue="updateTabValue($event)">
						<v-tab v-for="(data, index) in tabsData" :key="data" :text="data.name"
							:value="(data, index)"></v-tab>
					</v-tabs>
				</v-row>

				<v-row>
					<v-col>
						<v-btn v-bind="props" class="mx-1" color="primary" variant="outlined"
							prepend-icon="mdi-refresh" @click="populateData(mainRollingForecastId)">
							Refresh Item
						</v-btn>
				
						<!-- <v-btn class="mx-1" color="primary" variant="outlined" prepend-icon="mdi-view-list-outline">
							List View
						</v-btn> -->

						<v-btn class="mx-1" color="error" variant="outlined" prepend-icon="mdi-fullscreen"
							@click="isBottomSheetOpen = !isBottomSheetOpen">
							Exit Fullscreen
						</v-btn>
					</v-col>
					<v-spacer></v-spacer>

					<v-col cols="4">
						<v-text-field placeholder="Search" v-model="search" density="compact" append-inner-icon="mdi-magnify"></v-text-field>
					</v-col>
				</v-row>
			</template>

			<v-divider></v-divider>

			<v-card-text>
				<div v-if="products && products[selectedTab] && products[selectedTab].rolling_forecast_product_details_attributes" class="mt-5">
					<div class="table-wrapper">
						<table class="custom-table">
							<thead>
								<!-- First row of headers -->
								<tr>
									<th rowspan="2">No</th>
									<th rowspan="2" class="">Product Division</th>
									<th rowspan="2" class="">Product CAT.1</th>
									<th rowspan="2" class="">Product CAT.2</th>
									<th rowspan="2" class="">SKU</th>
									<th rowspan="2" class="">Product Name</th>
									<th rowspan="2" class="">Product Nickname</th>
									<th rowspan="2" class="">Stock</th>
									<th rowspan="2" class="">Avg SOL 3M</th>
									<th rowspan="2" class="">DOI</th>
									<th colspan="3" class=" text-center">{{ periodHeaders[0].title }}</th>
									<th colspan="3" class=" text-center">{{ periodHeaders[1].title }}</th>
									<th colspan="2" class=" text-center">{{ periodHeaders[2].title }}</th>
									<th colspan="3" class="">Est. YTD Acv</th>
									<th colspan="3" class="">Forecast Accuracy (L3M)</th>
								</tr>
								<!-- Second row of headers -->
								<tr>
									<!-- M0 -->
									<th class="">Qty</th>
									<th class="">Defiasi</th>
									<th class="">% to Target</th>
									<!-- M1 -->
									<th class="">Qty</th>
									<th class="">Defiasi</th>
									<th class="">% to Target</th>
									<!-- M2 -->
									<th class="">Qty</th>
									<th class="">% to Target</th>
									<!-- Est. YTD Acv -->
									<th>{{ periodHeaders[0].title.substring(0, 3) }}</th>
									<th>{{ periodHeaders[1].title.substring(0, 3) }}</th>
									<th>{{ periodHeaders[2].title.substring(0, 3) }}</th>
									<!-- Forecast Accuracy -->
									<th>{{ this.lastThreeMonths[0]}}</th>
									<th>{{ this.lastThreeMonths[1]}}</th>
									<th>{{ this.lastThreeMonths[2]}}</th>
								</tr>
							</thead>
							<tbody>
								<!-- Iterate through grouped data -->
								<template v-for="(group, groupIndex) in groupedProducts" :key="groupIndex">
									<!-- Category Group Rows -->
									<template v-if="!group.isDivisionTotal">
										<tr v-for="(item, index) in group.items" :key="index">
											<td>{{ index + 1 }}</td>
											<td class=" ">{{ item.product.product_division }}</td>
											<td class="">{{ item.product.product_category }}</td>
											<td class="">{{ item.product.product_category_2 }}</td>
											<td class="">{{ item.product.sku }}</td>
											<td class="">{{ item.product.description }}</td>
											<td class=" ">{{ item.product.product_name }}</td>
											<!-- Stock -->
											<td>
												<div v-if="mode === 0 || selectedTab === 0 || isNotDistrict" class="text-right py-2">
													{{ formatNumber(item.stock) }}
												</div>
												<div v-else>
													<vue-number
														v-model="item.stock"
														v-bind="numberProps"
														@change="updateValue(item, 'stock')"
														class="custom-input text-right ma-2"
													></vue-number>
												</div>
											</td>
											<td class="text-right">{{ formatNumber(item.avg_sol3m) }}</td>
											<td class="text-right">{{ formatNumber(item.doi) }}</td>
											<td>
												<div v-if="mode === 0 || selectedTab === 0 || isNotDistrict" class="text-right">
													{{ formatNumber(item.m0_qty) }}
												</div>
												<div v-else>
													<vue-number
														v-model="item.m0_qty"
														v-bind="numberProps"
														@change="updateValue(item, 'm0_qty')"
														class="custom-input text-right py-2"
													></vue-number>
												</div>
											</td>
											<td class="text-right">{{ formatNumber(item.m0_defiation * 100) + '%' }}</td>
											<td class="text-right">{{ formatNumber(item.m0_percent_to_target * 100) + '%' }}</td>
											<td>
												<div v-if="mode === 0 || selectedTab === 0 || isNotDistrict" class="text-right">
													{{ formatNumber(item.m1_qty) }}
												</div>
												<div v-else>
													<vue-number
														v-model="item.m1_qty"
														v-bind="numberProps"
														@change="updateValue(item, 'm1_qty')"
														class="custom-input text-right py-2"
													></vue-number>
												</div>
											</td>
											<td class="text-right">{{ formatNumber(item.m1_defiation * 100) + '%' }}</td>
											<td class="text-right">{{ formatNumber(item.m1_percent_to_target * 100) + '%' }}</td>
											<td>
												<div v-if="mode === 0 || selectedTab === 0 || isNotDistrict" class="text-right">
													{{ formatNumber(item.m2_qty) }}
												</div>
												<div v-else>
													<vue-number
														v-model="item.m2_qty"
														v-bind="numberProps"
														@change="updateValue(item, 'm2_qty')"
														class="custom-input text-right py-2"
													></vue-number>
												</div>
											</td>
											<td class="text-right">{{ formatNumber(item.m2_percent_to_target * 100) + '%' }}</td>
											<td class="text-right">{{ formatNumber(item.m0_est_ytd_actual * 100) + '%' }}</td>
											<td class="text-right">{{ formatNumber(item.m1_est_ytd_actual * 100) + '%'}}</td>
											<td class="text-right">{{ formatNumber(item.m2_est_ytd_actual * 100) + '%'}}</td>
											<td class="text-right">{{ formatNumber(item.l3m_forecast_accuracy_oct * 100) + '%'}}</td>
											<td class="text-right">{{ formatNumber(item.l3m_forecast_accuracy_nov * 100) + '%'}}</td>
											<td class="text-right">{{ formatNumber(item.l3m_forecast_accuracy_dec * 100) + '%'}}</td>
										</tr>
										<!-- Subtotal Row -->
										<tr class="subtotal">
											<!-- Do Not Using Colspan -->
											<td class="empty-column"></td>
											<td class="empty-column"></td>
											<td class="empty-column"></td>
											<td class="empty-column"></td>
											<td class="empty-column"></td>
											<td class="empty-column"></td>
											<td class="text-right">TOTAL {{ group.category }}</td>
											<td class="text-right"><strong>{{ formatNumber(group.totals.stock) }}</strong></td>
											<td class="text-right"><strong>{{ formatNumber(group.totals.avg_sol3m) }}</strong></td>
											<td class="text-right"><strong>{{ formatNumber(group.totals.doi) }}</strong></td>
											<td class="text-right"><strong>{{ formatNumber(group.totals.m0_qty) }}</strong></td>
											<td class="empty-column"></td>
											<td class="empty-column"></td>
											<td class="text-right"><strong>{{ formatNumber(group.totals.m1_qty) }}</strong></td>
											<td class="empty-column"></td>
											<td class="empty-column"></td>
											<td class="text-right"><strong>{{ formatNumber(group.totals.m2_qty) }}</strong></td>
											<td class="empty-column"></td>
											<td colspan="6" class="empty-column"></td>
										</tr>
									</template>
									<!-- Division Total Row -->
									<template v-if="group.isDivisionTotal">
										<tr class="division-total">
											<!-- Do Not Using Colspan -->
											<td class="empty-column"></td>
											<td class="empty-column"></td>
											<td class="empty-column"></td>
											<td class="empty-column"></td>
											<td class="empty-column"></td>
											<td class="empty-column"></td>
											<td class="text-right"><strong>{{ group.category }}</strong></td>
											<td class="text-right"><strong>{{ formatNumber(group.totals.stock) }}</strong></td>
											<td class="text-right"><strong>{{ formatNumber(group.totals.avg_sol3m) }}</strong></td>
											<td class="text-right"><strong>{{ formatNumber(group.totals.doi) }}</strong></td>
											<td class="text-right"><strong>{{ formatNumber(group.totals.m0_qty) }}</strong></td>
											<td class="empty-column"></td>
											<td class="empty-column"></td>
											<td class="text-right"><strong>{{ formatNumber(group.totals.m1_qty) }}</strong></td>
											<td class="empty-column"></td>
											<td class="empty-column"></td>
											<td class="text-right"><strong>{{ formatNumber(group.totals.m2_qty) }}</strong></td>
											<td class="empty-column"></td>
											<td colspan="6" class="empty-column"></td>
										</tr>
									</template>
								</template>
							</tbody>

							<tfoot>
								<tr>
									<!-- Do Not Using Colspan -->
									<td class="empty-column"></td>
									<td class="empty-column"></td>
									<td class="empty-column"></td>
									<td class="empty-column"></td>
									<td class="empty-column"></td>
									<td class="empty-column"></td>
									<td class="text-right"><strong>Grand Total</strong></td>
									<td class="text-right">{{ formatNumber(grandTotal[selectedTab].stock) }}</td>
									<td class="text-right">{{ formatNumber(grandTotal[selectedTab].avg_sol3m) }}</td>
									<td class="text-right">{{ formatNumber(grandTotal[selectedTab].doi) }}</td>
									<td class="text-right">{{ formatNumber(grandTotal[selectedTab].m0_qty) }}</td>
									<td class="empty-column"></td>
									<td class="empty-column"></td>
									<td class="text-right">{{ formatNumber(grandTotal[selectedTab].m1_qty) }}</td>
									<td class="empty-column"></td>
									<td class="empty-column"></td>
									<td class="text-right">{{ formatNumber(grandTotal[selectedTab].m2_qty) }}</td>
									<td class="empty-column"></td>
									<td colspan="6" class="empty-column"></td>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			</v-card-text>

			<template v-slot:append>
			</template>
		</v-card>
  </v-bottom-sheet>

	<v-overlay
			:model-value="loading"
		class="align-center justify-center"
		persistent
	>
		<v-progress-circular
			color="primary"
			size="120"
			indeterminate
		></v-progress-circular>
	</v-overlay>
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { mapActions } from 'vuex';
import breadcrumbConfig from '@/others/breadcrumbConfig.js';
import { readonly } from 'vue';
import DataTable from '@/components/DataTable.vue';

export default {
	components: {
		Breadcrumbs
	},

	data() {
		return {
			number: {
				decimal: '.',
				separator: ',',
				prefix: '',
				precision: 2,
				masked: false,
			},
			rollingForecastParams: {
				id: null,
				transaction_number: '',
				transaction_date: '',
				area: '',
				region: '',
				period_id: null,
				transaction_type_id: null,
				coverage_id: null,
				status: '',
				revise_remark: '',
				child_coverages: []
			},
			mainRollingForecastId: null,
			periods: [],
			transactionTypes: [],
			selectedPeriodId: null,
			tab: null,
			length: 3,
			headers: [],  // This will be populated dynamically
			isBottomSheetOpen: false,
			toggle: null,
			notes: '',
			tabsData: [],
			headTabsData: [],
			products: [],
			rofoType: '',
			search: '',
      formulas: {},
			loading: false,
			selectedTab: 0,
			selectedHeadTab: 0,
			tooltipShow: false,
			tableData: [],
			mode: 1, // 0:view 1: input,
			menuButton: [
				{ title: 'Edit' },
				{ title: 'Print' },
				{ title: 'Export' },
				{ title: 'Revise' },
			],
			grandTotal: [
				{
				stock: 0,
				avg_sol3m: 0,
				doi: 0,
				m0_qty: 0,
				m0_defiation: 0,
				m0_percent_to_target: 0,
				m1_qty: 0,
				m1_defiation: 0,
				m1_percent_to_target: 0,
				m2_qty: 0,
				m2_percent_to_target: 0,
				m0_est_ytd_actual: 0,
				m1_est_ytd_actual: 0,
				m2_est_ytd_actual: 0,
				lm_forecast_accuracy: 0,
				l2m_forecast_accuracy: 0,
				l3m_forecast_accuracy: 0
				}
			],
			asApprover: false,
			reviseRemarkDialog: false,
      draftDocument: false,
      requestRevise: false,
      awaitingApproval: false,
      isOwner: false,
			isNotDistrict: false,
			viewMode: 'Qty',
			viewTypes: ['Qty', 'Value'],
			showDiscretion: false,
			discretionSubmitted: false,
      discretionToApprove: false,
      discretionActive: false,
			periodHeaders: [],
			lastThreeMonths: [],
			historyItems: [],
			notesItems: [
				{ time: "5pm", text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae, aliquid unde." },
				{ time: "5pm", text: "Lorem ipsum dolor sit amet, Quia, eaque." },
			],
			notesInput: null,
		};
	},
	watch: {
		length(val) {
			this.tab = val - 1
		},
		products: {
			deep: true,
			handler() {
				this.products[this.selectedTab].rolling_forecast_product_details_attributes.forEach(item => {
					this.updateValue(item, 'doi');
					this.updateValue(item, 'm0');
					this.updateValue(item, 'm1');
					this.updateValue(item, 'm2');
				});
				this.calculateGrandTotal();
			}
		}
	},
	created() {
		this.selectedTab = 0;
	},
	computed: {
		isAdding() {
			return this.$route.params.action === 'add';
		},
		isDataReady() {
			return this.products.length > 0 && this.periods.length > 0 && !this.loading && this.headers.length > 9;
		},
		breadcrumbItems() {
			const action = this.$route.params.action;
			const route = this.$route.name;
			const items = breadcrumbConfig[route];

			if (typeof items === 'function') {
				return items(action);
			}

			return items || [];
		},
		groupedProducts() {
			const groups = [];
			let currentDivision = null;
			let divisionTotals = {
				stock: 0,
				avg_sol3m: 0,
				doi: 0,
				m0_qty: 0,
				m1_qty: 0,
				m2_qty: 0,
			};

			this.products[this.selectedTab].rolling_forecast_product_details_attributes.forEach((item) => {
				const category = item.product.product_category;
				const division = item.product.product_division;

				// Check if we're moving to a new division
				if (currentDivision && currentDivision !== division) {
					// Push the division total before starting a new division
					groups.push({
						category: `Total ${currentDivision}`,
						division: currentDivision,
						items: [],
						totals: { ...divisionTotals },
						isDivisionTotal: true, // Mark as division total row
					});

					// Reset division totals
					divisionTotals = {
						stock: 0,
						avg_sol3m: 0,
						doi: 0,
						m0_qty: 0,
						m1_qty: 0,
						m2_qty: 0,
					};
				}

				// Update the current division
				currentDivision = division;

				// Find or create a group for the current category
				let group = groups.find((g) => g.category === category && g.division === division);
				if (!group) {
					group = {
						category,
						division,
						items: [],
						totals: {
							stock: 0,
							avg_sol3m: 0,
							doi: 0,
							m0_qty: 0,
							m1_qty: 0,
							m2_qty: 0,
						},
					};
					groups.push(group);
				}

				// Add the item to the group
				group.items.push(item);

				// Update category totals
				group.totals.stock += parseFloat(item.stock) || 0;
				group.totals.avg_sol3m += parseFloat(item.avg_sol3m) || 0;
				group.totals.doi += parseFloat(item.doi) || 0;
				group.totals.m0_qty += parseFloat(item.m0_qty) || 0;
				group.totals.m1_qty += parseFloat(item.m1_qty) || 0;
				group.totals.m2_qty += parseFloat(item.m2_qty) || 0;

				// Update division totals
				divisionTotals.stock += parseFloat(item.stock) || 0;
				divisionTotals.avg_sol3m += parseFloat(item.avg_sol3m) || 0;
				divisionTotals.doi += parseFloat(item.doi) || 0;
				divisionTotals.m0_qty += parseFloat(item.m0_qty) || 0;
				divisionTotals.m1_qty += parseFloat(item.m1_qty) || 0;
				divisionTotals.m2_qty += parseFloat(item.m2_qty) || 0;
			});

			// Add the final division total
			if (currentDivision) {
				groups.push({
					category: `Total ${currentDivision}`,
					division: currentDivision,
					items: [],
					totals: { ...divisionTotals },
					isDivisionTotal: true,
				});
			}

			console.log('GROUP', groups)
			return groups;
		},
	},
	async mounted() {
    this.loading = true;
		const { action, id, type } = this.$route.params;
		// this.tooltipShow = true;
    const { alertMessage, alertColor } = this.$route.query;
    if (alertMessage) {
      this.showAlert({ message: alertMessage, color: alertColor });
    }
		
		this.mainRollingForecastId = id;
		setTimeout(() => this.tooltipShow = false, 3000);

		if (id) {
			this.populateData(id);
			this.fetchNotes(id);
		} else {
			// TODO
		}

		if (this.products.length > 0) {
			this.products[selectedTab].rolling_forecast_product_details_attributes.forEach(item => {
				this.updateValue(item, 'doi');
				this.updateValue(item, 'm0');
				this.updateValue(item, 'm1');
				this.updateValue(item, 'm2');
			});
      		this.calculateGrandTotal();
		}

		// if (type === 'District') {
		// 	this.rofoType = 'Distributor';
		// } else {
		// 	this.rofoType = 'Coverage';
		// }
	},
	methods: {
		...mapActions(['showAlert']),
		async populateData(coverage_id = null, period_id = null) {
			this.loading = true;
			const userLoggedIn = JSON.parse(localStorage.getItem('userData'));
			if(coverage_id && period_id) {
				try {
					const response = await axios.get(`/rolling_forecast?coverage_id=${coverage_id}&period_id=${period_id}`);
					this.rollingForecastParams = response.data;
					this.rollingForecastParams.period_id = response.data.period.id;
					this.transactionTypes = response.data.transaction_type;
					this.periods.push(response.data.period),
					this.tabsData = response.data.sub_coverages;
					this.products = response.data.child_coverages;
          this.formulas = response.data.formulas;
					this.asApprover = response.data.is_approver;
					this.approvedDocument = response.data.status === "Approved" ? true : false;
          this.draftDocument = response.data.status === "Draft" || response.data.status === "Request for Revise" ? true : false;
          this.requestRevise = response.data.status === "Approved" && (response.data.parent_status === "Draft" || response.data.parent_status === "Request for Revise") ? true : false;
          this.awaitingApproval = response.data.status === "Awaiting for Approval" ? true : false;
          this.isOwner = response.data.is_owner;
					// this.isDistrict = response.data.is_district;
					this.selectedTab = 0;
					this.isNotDistrict = response.data.not_district;
					this.rollingForecastParams.revise_remark = response.data.revise_remark;
          this.historyItems = response.data.object_histories;
					
				} catch (error) {
					const errorMessage = error.response?.data?.messages
						? error.response.data.messages
						: error.response?.message || 'error di awal';

					this.showAlert({ message: errorMessage, color: 'error' });
					this.$router.push({ name: 'rofo' });
				} finally {
					if (this.products.length > 0) {
						this.headers = this.generateHeaders(this.products[this.selectedTab]['rolling_forecast_product_details_attributes'])
					}
				}
			} else {
				try {
					const response = await axios.get(`/rolling_forecast/${coverage_id}`);
					this.rollingForecastParams = response.data;
					this.rollingForecastParams.period_id = response.data.period.id;
					this.transactionTypes = response.data.transaction_type;
					this.periods.push(response.data.period),
					this.headTabsData = [...response.data.sub_coverages];
					this.tabsData = [...response.data.sub_coverages];
					this.products = response.data.child_coverages;
					this.asApprover = response.data.is_approver;
					this.approvedDocument = response.data.status === "Approved" ? true : false;
          this.draftDocument = response.data.status === "Draft" || response.data.status === "Request for Revise" ? true : false;
          this.requestRevise = response.data.status === "Approved" && (response.data.parent_status === "Draft" || response.data.parent_status === "Request for Revise") ? true : false;
          this.awaitingApproval = response.data.status === "Awaiting for Approval" ? true : false;
          this.isOwner = response.data.is_owner;
					// this.isDistrict = response.data.is_district;			
          this.formulas = response.data.formulas;		
					this.rofoType = response.data.coverage.coverage_type;
					this.selectedTab = 0;
					this.isNotDistrict = response.data.not_district;
					this.rollingForecastParams.revise_remark = response.data.revise_remark;
          this.historyItems = response.data.object_histories;
					
					if (response.data.discretion_active){
						this.showDiscretion = true;
					}
					this.discretionActive = response.data.discretion_active;
          this.discretionToApprove = response.data.discretion_to_approve;
          this.discretionSubmitted = response.data.discretion_submitted;

					// if (!this.isDistrict) {
					// 	this.mainRollingForecastId = response.data.id;
					// }
					
				} catch (error) {
					const errorMessage = error.response?.data?.messages
						? error.response.data.messages
						: error.response?.message || 'error di request rofo data';

					this.showAlert({ message: errorMessage, color: 'error' });
					this.$router.push({ name: 'rofo' });
				} finally {
					if (this.products.length > 0) {
						this.headers = this.generateHeaders(this.products[this.selectedTab]['rolling_forecast_product_details_attributes'])
					}
				}
			}
			
      this.loading = false;
		},
		async refreshProductItem() {
			this.loading = true;
			let coverageParams = this.rollingForecastParams.coverage.id;
			try {
				const rofoData = {
					name: 'ROFO',
					status: status === 0 ? 'Draft' : status === 1 ? 'Awaiting for Approval' : 'Approved',
					id: this.rollingForecastParams.id,
					period_id: this.periods[0].id,
					coverage_id: coverageParams,
					transaction_type_id: this.rollingForecastParams.transaction_type_id,
					rolling_forecast_details_attributes: this.products.slice(1), // Slice the  generated Totals Data for input
				};

				let response = await axios.put(`/rolling_forecast/${this.rollingForecastParams.id}`, { rolling_forecast: rofoData });
				
				this.showAlert({ message: 'Rofo data successfully update', color: 'success' });
				if (response.data) {
					this.rollingForecastParams = response.data.rolling_forecast;
					this.tabsData = response.data.rolling_forecast.sub_coverages;
					this.products = response.data.rolling_forecast.child_coverages;
				
				} else {
					throw new Error('Failed to update rofo');
				}
			} catch (error) {
				const errorMessage = error.response?.data?.messages
					? error.response.data.messages
					: error.response?.message || 'An error accured';

				this.showAlert({ message: errorMessage, color: 'error' });
			}
			this.loading = false;
		},
		async handleSubmit(status) {
			let coverageParams = this.rollingForecastParams.coverage.id;
			try {
				const rofoData = {
					name: 'ROFO',
					status: status === 0 ? 'Draft' : status === 1 ? 'Awaiting for Approval' : 'Approved',
					id: this.rollingForecastParams.id,
					period_id: this.periods[0].id,
					coverage_id: coverageParams,
					transaction_type_id: this.rollingForecastParams.transaction_type_id,
					rolling_forecast_details_attributes: this.products.slice(1), // Slice the  generated Totals Data for input
				};

				if (this.$route.params.action === 'add' && status <= 0) {
					const response = await axios.post('/rolling_forecast', { rolling_forecast: rofoData });

					this.showAlert({ message: 'Rofo data successfully saved', color: 'success' });
					if (response.data) {
						// this.mode = 0;
						this.rollingForecastParams = response.data.rolling_forecast;
						this.rollingForecastParams.period_id = response.data.rolling_forecast.period.id;
						this.transactionTypes = response.data.rolling_forecast.transaction_type;
						this.periods.push(response.data.rolling_forecast.period),
						this.tabsData = response.data.rolling_forecast.sub_coverages;
						this.products = response.data.rolling_forecast.child_coverages;
						this.formulas = response.data.rolling_forecast.formulas;
						this.asApprover = response.data.rolling_forecast.is_approver;
						this.approvedDocument = response.data.rolling_forecast.status === "Approved" ? true : false;
						this.draftDocument = response.data.rolling_forecast.status === "Draft" || response.data.status === "Request for Revise" ? true : false;
						this.requestRevise = response.data.rolling_forecast.status === "Approved" && (response.data.rolling_forecast.parent_status === "Draft" || response.data.rolling_forecast.parent_status === "Request for Revise") ? true : false;
            this.awaitingApproval = response.data.rolling_forecast.status === "Awaiting for Approval" ? true : false;
						this.isOwner = response.data.rolling_forecast.is_owner;
						this.selectedTab = 0;
						this.isNotDistrict = response.data.rolling_forecast.not_district;
            this.historyItems = response.data.object_histories;
						
					} else {
						throw new Error(response.message);
					}
				} else {
					const updateStatus = status === 0 ? null : status === 1 ? 'submit' : status = 'approve';
					let response;
					if (updateStatus) {
						response = await axios.put(`/rolling_forecast/${this.rollingForecastParams.id}/${updateStatus}`, { rolling_forecast: rofoData });
					} else {
						response = await axios.put(`/rolling_forecast/${this.rollingForecastParams.id}`, { rolling_forecast: rofoData });
					}
					
					this.showAlert({ message: 'Rofo data successfully update', color: 'success' });
					if (response.data) {
						this.rollingForecastParams = response.data.rolling_forecast;
						this.rollingForecastParams.period_id = response.data.rolling_forecast.period.id;
						this.transactionTypes = response.data.rolling_forecast.transaction_type;
						this.periods.push(response.data.rolling_forecast.period),
						this.tabsData = response.data.rolling_forecast.sub_coverages;
						this.products = response.data.rolling_forecast.child_coverages;
						this.formulas = response.data.rolling_forecast.formulas;
						this.asApprover = response.data.rolling_forecast.is_approver;
						this.approvedDocument = response.data.rolling_forecast.status === "Approved" ? true : false;
						this.draftDocument = response.data.rolling_forecast.status === "Draft" || response.data.status === "Request for Revise" ? true : false;
						this.requestRevise = response.data.rolling_forecast.status === "Approved" && (response.data.rolling_forecast.parent_status === "Draft" || response.data.rolling_forecast.parent_status === "Request for Revise") ? true : false;
            this.awaitingApproval = response.data.rolling_forecast.status === "Awaiting for Approval" ? true : false;
						this.isOwner = response.data.rolling_forecast.is_owner;
						this.selectedTab = 0;
						this.isNotDistrict = response.data.rolling_forecast.not_district;
            this.historyItems = response.data.object_histories;
					
					} else {
						throw new Error('Failed to update rofo');
					}
				}
			} catch (error) {
				const errorMessage = error.response?.data?.messages
					? error.response.data.messages
					: error.response?.message || 'An error accured';

				this.showAlert({ message: errorMessage, color: 'error' });
			}
		},
		submitForm(status) {
			this.$refs.form.validate();
			this.handleSubmit(status);
		},
		requestForRevise() {
			this.reviseRemarkDialog = true;
		},
		async reviseData() {
			this.reviseRemarkDialog = false;
			let coverageParams = this.rollingForecastParams.coverage_id;
			const rofoData = {
					name: 'ROFO',
					status: 'Request for Revise',
					revise_remark: this.rollingForecastParams.revise_remark,
					id: this.rollingForecastParams.id,
					period_id: this.periods[0].id,
					coverage_id: coverageParams,
					transaction_type_id: this.rollingForecastParams.transaction_type_id,
					rolling_forecast_details_attributes: this.products.slice(1), // Slice the  generated Totals Data for input
				};
			try {
				const response = await axios.put(`/rolling_forecast/${this.rollingForecastParams.id}`, { rolling_forecast: rofoData });
				this.showAlert({ message: 'Rofo data successfully update', color: 'success' });
				this.$router.push({ name: 'rofo' });
			} catch (error) {
				const errorMessage = error.response?.data?.messages
					? error.response.data.messages
					: error.response?.message || 'An error accured';

				this.showAlert({ message: errorMessage, color: 'error' });
			}
		},
		async submitDiscretion() {
			this.loading = true;
			const discretionParams = {
				discretion_request: {
						requester_id: JSON.parse(localStorage.getItem('userData')).id,
						reviewer_id: null,
						object_id: this.rollingForecastParams.id,
						object_type: 'RollingForecast',
						status: null,
				},
			};

			try {
					const response = await axios.post('/rolling_forecast/submit_discretion', discretionParams);
					this.showAlert({ message: 'Discretion request successfully submitted', color: 'success' });
					this.$router.push({ name: 'rofo' });
			} catch (error) {
					const errorMessage = error.response?.data?.messages
							? error.response.data.messages
							: error.response?.message || 'An error occurred';

					this.showAlert({ message: errorMessage, color: 'error' });
			}
			this.loading = false;
		},
		async updateDiscretion(status) {
			this.loading = true;
			const discretionParams = {
				discretion_request: {
            requester_id: null,
						reviewer_id: null,
						object_id: this.rollingForecastParams.id,
						object_type: 'RollingForecast',
						status: status === 0 ? 'Rejected' : 'Approved',
				},
			};

			try {
					const response = await axios.post('/rolling_forecast/update_discretion', discretionParams);
					this.showAlert({ message: 'Discretion request successfully submitted', color: 'success' });
					this.$router.push({ name: 'rofo' });
			} catch (error) {
					const errorMessage = error.response?.data?.messages
							? error.response.data.messages
							: error.response?.message || 'An error occurred';

					this.showAlert({ message: errorMessage, color: 'error' });
			}
			this.loading = false;
		},
		generateHeaders(data) {
			const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
			// Base headers
			const baseHeaders = [
				{ title: 'NO', value: 'index', align: 'center' },
				{ title: 'PRODUCT DIVISION', value: 'product.product_division', nowrap: true, width: '180px', align: 'left' , sortable: true},
				{ title: 'PRODUCT CAT.1', value: 'product.product_category', nowrap: true, width: '180px', align: 'left', sortable: true },
				{ title: 'PRODUCT CAT.2', value: 'product.product_category_2', nowrap: true, width: '180px', align: 'left', sortable: true },
				{ title: 'SKU', value: 'product.sku', align: 'left', sortable: true },
				{ title: 'PRODUCT NAME', value: 'product.description', nowrap: true, width: '180px', align: 'left', sortable: true },
				{ title: 'PRODUCT NICKNAME', value: 'product.product_name',nowrap: true, align: 'left', sortable: true },
				{ title: 'STOCK', value: 'stock', width: '120px', class: 'stock-col', align: 'center', sortable: true },
				{ title: 'AVG SOL3M', value: 'avg_sol3m', width: '80px', align: 'center', sortable: true },
				{ title: 'DOI', value: 'doi', width: '80px', align: 'center', sortable: true },
			];

			// Collect unique periods and results from all items
			const valueEachPeriods = new Set();
			const estForecast = new Set();
			
			data.forEach(prod => {
				// Extract periods
				valueEachPeriods.add('m0');
				valueEachPeriods.add('m1');
				valueEachPeriods.add('m2');

				estForecast.add('est_ytd_actual');
				estForecast.add('forecast_accuracy_l3m');

			});

			// Convert sets to arrays
			const periodsArray = Array.from(valueEachPeriods);
			const resultsArray = Array.from(estForecast);

			// Generate period headers using the provided logic
			const periods = [];
			if (this.periods && this.periods.length > 0) {
				const startDate = new Date(this.periods[0].date);
				const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
				const startMonthIndex = startDate.getMonth();

				for (let i = 0; i < periodsArray.length; i++) {
					const currentMonthIndex = (startMonthIndex + i) % 12;
					const currentMonth = months[currentMonthIndex];
					periods.push(currentMonth);
				}
			}

			// Dynamic headers for periods
			const periodHeaders = periods.map((period, index) => ({
				title: period + ` (M+${index})`,
				align: 'center',
				children: [
					{ title: `Qty`, value: `m${index}_qty`, align: 'center' },
					...(index !== 2
            ? [{ title: `Defiasi`, value: `m${index}_defiation`, align: 'center' }]
            : []),
					{ title: `% to Target`, value: `m${index}_percent_to_target`, nowrap: false, width: '100px', align: 'center' },
				],
			}));

			// Helper function to get last three months in abbreviated format
			function getLastThreeMonths(startMonthIndex) {
				return [
					months[(startMonthIndex - 3 + 12) % 12], // 3 months ago
					months[(startMonthIndex - 2 + 12) % 12], // 2 months ago
					months[(startMonthIndex - 1 + 12) % 12]  // 1 month ago
				];
			}

			// Calculate the start month index based on the first period in the array
			const firstPeriod = periods[0].toUpperCase(); // Example: "JAN", "FEB"
			const startMonthIndex = months.indexOf(firstPeriod);
			const lastThreeMonths = getLastThreeMonths(startMonthIndex);

			// Dynamic headers for results
			const resultHeaders = resultsArray.map((result, index) => ({
				title: index === 0 ? `Est. YTD Acv` : 'FORECAST ACCURACY L3M',
				align: 'center',
				children: periods.map((period, periodIndex) => {
						const reverseIndex = periods.length - 1 - periodIndex; // Reverse the index
						return {
							title: index === 0 ? period : lastThreeMonths[periodIndex],
							value: index === 0 ? `m${periodIndex}_est_ytd_actual` : `l${reverseIndex > 0 ? reverseIndex + 1 : ''}m_forecast_accuracy`,
							align: 'center',
						};
				})
			}));

			this.periodHeaders = periodHeaders;
			this.lastThreeMonths = lastThreeMonths;

			// Combine all headers
			return [
				...baseHeaders,
				...periodHeaders,
				...resultHeaders,
			];
		},
		toggleFullScreen() {
			this.isBottomSheetOpen = true;
		},
		updateTabValue(value) {
			this.selectedTab = value;
			if(this.products.length > 0) {
				this.products[this.selectedTab].rolling_forecast_product_details_attributes.forEach(item => {
					if(item){
						this.updateValue(item, 'doi');
						this.updateValue(item, 'm0');
						this.updateValue(item, 'm1');
						this.updateValue(item, 'm2');
					}
				});
			}
		},
		async updateHeadTabValue(value, index) {
			this.selectedHeadTab = index;
			if (index > 0) {
				this.populateData(value.id, this.periods[0].id) 
			} else {
				try {
					const response = await axios.get(`/rolling_forecast/${this.mainRollingForecastId}`);
					this.rollingForecastParams = response.data;
					this.rollingForecastParams.period_id = response.data.period.id;
					this.transactionTypes = response.data.transaction_type;
					this.periods.push(response.data.period),
					this.headTabsData = [...response.data.sub_coverages];
					this.tabsData = [...response.data.sub_coverages];
					this.products = response.data.child_coverages;
					this.asApprover = response.data.is_approver;
					this.approvedDocument = response.data.status === "Approved" ? true : false;
          this.draftDocument = response.data.status === "Draft" || response.data.status === "Request for Revise" ? true : false;
          this.requestRevise = response.data.status === "Approved" && (response.data.parent_status === "Draft" || response.data.parent_status === "Request for Revise") ? true : false;
          this.awaitingApproval = response.data.status === "Awaiting for Approval" ? true : false;
          this.isOwner = response.data.is_owner;
					// this.isDistrict = response.data.is_district;
					this.rofoType = response.data.coverage.coverage_type;
          this.formulas = response.data.formulas;
					this.selectedTab = 0;
          this.historyItems = response.data.object_histories;

					// if (!this.isDistrict){
					// 	this.headTabsData[0] = {
					// 		id: response.data.coverage.id,
					// 		type: response.data.coverage.name,
					// 		name: response.data.coverage.name
					// 	};
					// }
					
				} catch (error) {
					const errorMessage = error.response?.data?.messages
						? error.response.data.messages
						: error.response?.message || 'error di request rofo data';

					this.showAlert({ message: errorMessage, color: 'error' });
					this.$router.push({ name: 'rofo' });
				} finally {
					if (this.products.length > 0) {
						this.headers = this.generateHeaders(this.products[this.selectedTab]['rolling_forecast_product_details_attributes'])
					}
				}
			}
			
		},
    calculateResult(variables, formula) {
      // Extract variable keys and values
      const variableKeys = Object.keys(variables);
      const variableValues = Object.values(variables);

      // Construct a dynamic function with variable keys as parameters
      const fn = new Function(...variableKeys, `return ${formula};`);

      // Call the function with variable values
      const result = fn(...variableValues);

      // Check if the result is a number
      if (typeof result === 'number' && isFinite(result) && !isNaN(result)) {
        return result.toFixed(2);  // Rounds the result to the nearest integer
      } else {
		    return "0";
      }
    },
		updateValue(item, type) {
			switch (type) {
				case 'doi':
					item.doi = this.calculateResult({
						stock: Number(item.stock),
						avg_sol3m: Number(item.avg_sol3m),
						working_days: Number(item.working_days)
					}, this.formulas.doi_formula);

          item.m0_est_ytd_actual = this.calculateResult({
						ytd_sell_in: Number(item.ytd_sell_in),
            m0_qty: Number(item.m0_qty),
						m0_ytd_target: Number(item.m0_ytd_target)
					}, this.formulas.m0_est_ytd_actual_formula);

          item.m1_est_ytd_actual = this.calculateResult({
						ytd_sell_in: Number(item.ytd_sell_in),
            m1_qty: Number(item.m1_qty),
						m0_qty: Number(item.m0_qty),
						m1_ytd_target: Number(item.m1_ytd_target)
					}, this.formulas.m1_est_ytd_actual_formula);

          item.m2_est_ytd_actual = this.calculateResult({
						ytd_sell_in: Number(item.ytd_sell_in),
						m1_qty: Number(item.m1_qty),
						m0_qty: Number(item.m0_qty),
            m2_qty: Number(item.m2_qty),
						m2_ytd_target: Number(item.m2_ytd_target)
					}, this.formulas.m2_est_ytd_actual_formula);
					
					item.lm_forecast_accuracy = this.calculateResult({
						lm_m0_qty: Number(item.lm_m0_qty),
						lm_sell_in: Number(item.lm_sell_in)
					}, this.formulas.lm_forecast_accuracy_formula);
					
					item.l2m_forecast_accuracy = this.calculateResult({
						l2m_m0_qty: Number(item.l2m_m0_qty),
						l2m_sell_in: Number(item.l2m_sell_in)
					}, this.formulas.l2m_forecast_accuracy_formula);
					
					item.l3m_forecast_accuracy = this.calculateResult({
						l3m_m0_qty: Number(item.l3m_m0_qty),
						l3m_sell_in: Number(item.l3m_sell_in)
					}, this.formulas.l3m_forecast_accuracy_formula);
					break;
					
				case 'm0':
          item.m0_defiation = this.calculateResult({
						lm_m1_qty: Number(item.lm_m1_qty),
						m0_qty: Number(item.m0_qty)
					}, this.formulas.m0_defiation_formula);
					
					item.m0_percent_to_target = this.calculateResult({
						m0_qty: Number(item.m0_qty),
						m0_target: Number(item.m0_target)
					}, this.formulas.m0_percent_to_target_formula);
					break;
					
				case 'm1':
          item.m1_defiation = this.calculateResult({
						lm_m2_qty: Number(item.lm_m2_qty),
						m1_qty: Number(item.m1_qty)
					}, this.formulas.m1_defiation_formula);
					
					item.m1_percent_to_target = this.calculateResult({
						m1_qty: Number(item.m1_qty),
						m1_target: Number(item.m1_target)
					}, this.formulas.m1_percent_to_target_formula);
					break;
					
				case 'm2':					
					item.m2_percent_to_target = this.calculateResult({
						m2_qty: Number(item.m2_qty),
						m2_target: Number(item.m2_target)
					}, this.formulas.m2_percent_to_target_formula);
					break;
			}
		},
		calculateGrandTotal() {
      		this.grandTotal = this.tabsData.map((tab, index) => {
			const products = this.products[index].rolling_forecast_product_details_attributes;

				const totals = {
					tabName: tab.name,  // Assuming tabsData has a name field
					stock: 0,
					avg_sol3m: 0,
          working_days: 0,
					doi: 0,
					m0_qty: 0,
					m0_value: 0,
					m0_defiation: 0,
					m0_percent_to_target: 0,
					m1_qty: 0,
					m1_value: 0,
					m1_defiation: 0,
					m1_percent_to_target: 0,
					m2_qty: 0,
					m2_value: 0,
					m2_percent_to_target: 0,
					m0_est_ytd_actual: 0,
					m1_est_ytd_actual: 0,
					m2_est_ytd_actual: 0,
					lm_forecast_accuracy: 0,
					l2m_forecast_accuracy: 0,
					l3m_forecast_accuracy: 0
				};

				products.forEach((item) => {
					totals.stock += Number(item.stock) || 0;
					totals.avg_sol3m += Number(item.avg_sol3m) || 0;
          totals.doi += Number(item.doi) || 0;
					totals.working_days += Number(item.working_days) || 0;
					totals.m0_qty += Number(item.m0_qty) || 0;
					totals.m0_value += Number(item.m0_value) || 0;
					totals.m0_defiation += Number(item.m0_defiation) || 0;
					totals.m0_percent_to_target += Number(item.m0_percent_to_target) || 0;
					totals.m1_qty += Number(item.m1_qty) || 0;
					totals.m1_value += Number(item.m1_value) || 0;
					totals.m1_defiation += Number(item.m1_defiation) || 0;
					totals.m1_percent_to_target += Number(item.m1_percent_to_target) || 0;
					totals.m2_qty += Number(item.m2_qty) || 0;
					totals.m2_value += Number(item.m2_value) || 0;
					totals.m2_percent_to_target += Number(item.m2_percent_to_target) || 0;
					totals.m0_est_ytd_actual = Number(item.m0_est_ytd_actual) || 0;
          totals.m1_est_ytd_actual = Number(item.m1_est_ytd_actual) || 0;
          totals.m2_est_ytd_actual = Number(item.m2_est_ytd_actual) || 0;
					totals.lm_forecast_accuracy += Number(item.lm_forecast_accuracy) || 0;
					totals.l2m_forecast_accuracy += Number(item.l2m_forecast_accuracy) || 0;
					totals.l3m_forecast_accuracy += Number(item.l3m_forecast_accuracy) || 0;
				});

				if (products.length > 0) {
					totals.avg_sol3m = totals.avg_sol3m;
					totals.doi = (totals.stock / (totals.avg_sol3m / (totals.working_days / products.length)));
					totals.m0_defiation = totals.m0_defiation / products.length;
					totals.m1_defiation = totals.m1_defiation / products.length;
					totals.m0_percent_to_target = totals.m0_percent_to_target / products.length;
					totals.m1_percent_to_target = totals.m1_percent_to_target / products.length;
					totals.m2_percent_to_target = totals.m2_percent_to_target / products.length;
					totals.m0_est_ytd_actual = totals.m0_est_ytd_actual / products.length;
					totals.m1_est_ytd_actual = totals.m1_est_ytd_actual / products.length;
					totals.m2_est_ytd_actual = totals.m2_est_ytd_actual / products.length;
					totals.lm_forecast_accuracy = totals.lm_forecast_accuracy / products.length;
					totals.l2m_forecast_accuracy = totals.l2m_forecast_accuracy / products.length;
					totals.l3m_forecast_accuracy = totals.l3m_forecast_accuracy / products.length;
				}

				return totals;
			});
		},
		formatNumber(value) {
			const number = Number(value) || 0;
			return new Intl.NumberFormat('en-US', {
				minimumFractionDigits: 0,
				maximumFractionDigits: 0,
			}).format(number);
		},
		updateMode() {
			this.mode = 1;
			this.$route.params.action = 'update'
		},
		async handleNoteSubmit() {
			try {
				const noteData = {
				content: this.$refs.myEditor.getHTML(),
				noteable_type: 'RollingForecast',
				noteable_id: this.rollingForecastParams.id
				};

				const response = await axios.post('/note', { note: noteData });

				if (response.data) {
					this.fetchNotes(this.rollingForecastParams.id)
					this.showAlert({ message: 'Successfully save Note', color: 'success' });
				} else {
					this.showAlert({ message: "Failed", color: 'error' });
				}
				
			} catch (error) {
				const errorMessage = error.response?.data?.messages
				? error.response.data.messages
				: error.response?.message || 'An error occurred';

				this.showAlert({ message: errorMessage, color: 'error' });
			}
		},
		async fetchNotes(id) {
			try {
				const noteData = {
					noteable_type: 'RollingForecast',
					noteable_id: id
				};

				const response = await axios.get('/note', { params: noteData });

				if (response.data) {
					this.noteList = response.data;
				} else {
					this.showAlert({ message: "Failed", color: 'error' });
				}
				
			} catch (error) {
				const errorMessage = error.response?.data?.messages
				? error.response.data.messages
				: error.response?.message || 'An error occurred';

				this.showAlert({ message: errorMessage, color: 'error' });
			}
		},
		formatTime(datetime) {
			const date = new Date(datetime);
			const options = {
				year: 'numeric',
				month: 'short', // Short month name (e.g., Jan, Feb)
				day: 'numeric',
				hour: '2-digit',
				minute: '2-digit',
			};
			return date.toLocaleDateString(undefined, options); // Default locale
		},
		changeViewMode(mode) {
			console.log(mode)
			this.viewMode = mode;
		},
		hasTotal(index, item) {
        if (!this.products || this.products.length <= 1) {
            return false;
        }

        const currentItem = this.products[this.selectedTab].rolling_forecast_product_details_attributes[index];
        const nextItem = this.products[this.selectedTab].rolling_forecast_product_details_attributes[index + 1];

        if (!nextItem) {
            return true; // Last item
        }

        return currentItem.product.product_division !== nextItem.product.product_division;
    },
    calculateTotal(division){
        return this.products.filter(item => item.product_division === division).reduce((sum, item) => sum + item.m0_qty, 0);
    }
	}
};
</script>

<style scoped>
.custom-input {
	border: 1px solid #ddd;
	padding: 6px 6px;
	border-radius: 2px;
	width: 5vw;
	box-sizing: border-box;
	text-align: right;
}

.custom-input:focus {
	border-color: #1976d2;
	outline: none;
}

/* Apply the same fixed positioning to the Total row's fixed columns */
.fixed-column {
	position: sticky;
	background-color: white;
	z-index: 1;
	text-align: right;
	font-weight: bold;
}

/* Apply specific width for each fixed column to match headers */
.total-label {
	width: 100px;
	text-align: center;
	/* Adjust this to match the width of the first column */
}

.fixed-column:nth-child(2) {
	left: 100px;
	/* Adjust based on the width of the first fixed column */
}

/* Ensure the sticky columns stay in place when scrolling */
tr>.fixed-column {
	box-shadow: 2px 0px 5px -2px rgba(0, 0, 0, 0.2);
}

.invalid-mark {
	background: #FFCDD2;
}

:deep(.v-table.v-table--fixed-header > .v-table__wrapper > table > thead > tr > th) {
  border: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));
}

:deep(.v-table .v-table__wrapper > table > tbody > tr:not(:last-child) > td) {
  border: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));
}

:deep(.v-table > .v-table__wrapper > table > tbody > tr > td) {
	border: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));
}

.v-table {
  border: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));
}

.v-tab {
	color: #0CA4BC;
}

.inactive-tab {
  background-color: #f0f0f0;
	color: black;
}

.table-wrapper {
  overflow-x: auto;
  overflow-y: auto;
  max-height: 700px;
}

.custom-table {
  width: 100%;
	border-collapse: collapse;
  /* text-align: left; */
  font-size: 12px;
}

/* First row of headers */
.custom-table thead tr:first-child th {
  /* position: sticky; */
  /* top: 1px; */
  z-index: 2; /* Higher z-index for the first row */
  /* background-color: white; */
}

/* Second row of headers */
.custom-table thead tr:nth-child(2) th {
  /* position: sticky; */
  /* top: 35px;  */
  z-index: 1; 
  /* background-color: white; */
}

/* Ensure no overlap between rows */
.custom-table thead th {
  height: 35px; /* Set a consistent height for all header rows */
}

.custom-table th,
.custom-table td {
  padding: 10px;
  border: 1px solid #ddd;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Sticky Column (No - 1st Column) */
.custom-table tr th:nth-child(1),
.custom-table tr td:nth-child(1) {
  position: sticky;
  left: 0; /* Sticks to the left edge */
  background-color: white;
  z-index: 2;
  width: 50px;
}

/* Sticky Column (Product Division - 2nd Column) */
.custom-table tr th:nth-child(2),
.custom-table tr td:nth-child(2) {
  position: sticky;
  left: 37px; 
  background-color: white;
  z-index: 2;
  width: 150px;
}

/* Sticky Column (Product CAT.1 - 3rd Column) */
.custom-table tr th:nth-child(3),
.custom-table tr td:nth-child(3) {
  position: sticky;
  left: 155px;
  background-color: white;
  z-index: 2;
  width: 130px;
}

/* Sticky Column (Product CAT.2 - 4th Column) */
.custom-table tr th:nth-child(4),
.custom-table tr td:nth-child(4) {
  position: sticky;
  left: 259px;
  background-color: white;
  z-index: 2;
  width: 130px;
}

/* Sticky Column (SKU - 5th Column) */
.custom-table tr th:nth-child(5),
.custom-table tr td:nth-child(5) {
  position: sticky;
  left: 409px;
  background-color: white;
  z-index: 2;
  width: 100px;
}

/* Sticky Column (Product Name - 6th Column) */
.custom-table tr th:nth-child(6),
.custom-table tr td:nth-child(6) {
  position: sticky;
  left: 488px; 
  background-color: white;
  z-index: 2;
  width: 250px;
}

/* Sticky Column (Product Nickname - 7th Column) */
.custom-table tr th:nth-child(7),
.custom-table tr td:nth-child(7) {
  position: sticky;
  left:702px;
  background-color: white;
  z-index: 2;
  width: 190px;
}

/* Sticky Column (Product Division - 2nd Column) */
.subtotal tr td:nth-child(1) {
  position: sticky;
  left: 702px;
  background-color: white;
  z-index: 2; 
  width: 150px;
}



/* Column widths */
/* .custom-table th:nth-child(1),
.custom-table td:nth-child(1) { width: 50px; }
.custom-table th:nth-child(3),
.custom-table td:nth-child(3) { width: 130px; }
.custom-table th:nth-child(4),
.custom-table td:nth-child(4) { width: 130px; }
.custom-table th:nth-child(5),
.custom-table td:nth-child(5) { width: 100px; } */
.custom-table th:nth-child(8),
.custom-table td:nth-child(8) { width: 150px; }
.custom-table th:nth-child(9),
.custom-table td:nth-child(9) { width: 100px; }
.custom-table th:nth-child(10),
.custom-table td:nth-child(10) { width: 100px; }
.custom-table th:nth-child(11),
.custom-table td:nth-child(11) { width: 350px; }
.custom-table th:nth-child(12),
.custom-table td:nth-child(12) { width: 350px; }
.custom-table th:nth-child(13),
.custom-table td:nth-child(13) { width: 230px; }
.custom-table th:nth-child(14),
.custom-table td:nth-child(14) { width: 200px; }
.custom-table th:nth-child(15),
.custom-table td:nth-child(15) { width: 200px; }

.empty-column {
	background-color: lightgray;
}

</style>