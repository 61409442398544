<template>
  <Breadcrumbs :items="breadcrumbItems" />
  <v-card>
    <template v-slot:title> </template>

    <v-divider></v-divider>

    <v-card-text>
      <div>
        <v-form ref="form" @submit.prevent="handleSubmit">
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Employee ID</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-text-field v-model="employee.employee_id" required bgColor="none"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">First Name</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-text-field v-model="employee.first_name" required bgColor="none"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Last Name</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-text-field v-model="employee.last_name" required bgColor="none"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Email</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-text-field v-model="employee.email" required bgColor="none"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Status</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-select bgColor="none" v-model="employee.status" :items="statusOption" placeholder="" required></v-select>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-card-text>

    <template v-slot:append>
      <v-btn @click="submitForm" color="primary">
        {{ isAdding ? $t('button.save') : $t('button.update') }}
      </v-btn>
    </template>
  </v-card>
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { mapActions } from 'vuex';
import breadcrumbConfig from '@/others/breadcrumbConfig.js';

export default {
  components: {
    Breadcrumbs
  },
  data() {
    return {
      employee: {
        id: null,
        first_name: '',
        last_name: '',
        email: '',
        status: ''
      },
      statusOption: ['Active', 'Inactive']
    };
  },
  computed: {
    isAdding() {
      return this.$route.params.action === 'add';
    },
    breadcrumbItems() {
      const action = this.$route.params.action;
      const route = this.$route.name;
      const items = breadcrumbConfig[route];

      if (typeof items === 'function') {
        return items(action);
      }

      return items || [];
    }
  },
  async mounted() {
    const { action, id } = this.$route.params;

    // Fetch employee details if editing
    if (action === 'edit' && id) {
      try {
        const response = await axios.get(`/employee/${id}`);
        this.employee = response.data;
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    }
  },
  methods: {
    ...mapActions(['showAlert']),
    async handleSubmit() {
      try {
        const employeeData = {
          employee_id :this.employee.employee_id,
          first_name :this.employee.first_name,
          last_name: this.employee.last_name,
          email: this.employee.email,
          status: this.employee.status
        };

        if (this.isAdding) {
          const response = await axios.post('/employee', { employee: employeeData });
          this.showAlert({ message: 'Employee data successfully saved', color: 'success' });
          if (response.data) {
            this.$router.push({ name: 'employee' });
          } else {
            throw new Error(response.message);
          }
        } else {
          const response = await axios.put(`/employee/${this.employee.id}`, { employee: employeeData });
          if (response.status === 200) {
            this.$router.push({ name: 'employee' });
            this.showAlert({ message: response.data.message, color: 'success' });
          } else {
            this.showAlert({ message: response.data.message, color: 'error' });
          }
        }
      } catch (error) {
        const errorMessage = error.response?.data?.message
          ? error.response.data.message
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    submitForm() {
      this.$refs.form.validate(); // Trigger form validation
      this.handleSubmit(); // Call the submit method
    },
  }
};
</script>
