<template>
  <router-view refs="router-root"></router-view>
</template>

<script>
import DataTable from '@/components/DataTable.vue';

export default {
  components: {
    DataTable
  },
}
</script>

<style>
@import '~vuetify/dist/vuetify.min.css';

.v-container {
  background-color: whitesmoke;
  height: 100%;
}

/* custom class for textfield font size */
.custom-text-field input {
  font-size: 12px;
  /* Adjust to your desired font size */
}

.v-textarea .v-field--no-label textarea, .v-textarea .v-field--active textarea {
  font-size: 12px;
}

.custom-label {
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 8px;
}

.v-label.v-field-label {
  font-size: 12px;
}

.v-select .v-select__selection-text {
  font-size: 12px;
}
.v-data-table th {
  color: #0CA4BC;
  white-space: nowrap;
}
/* --------- */

.v-card .v-card-text {
  padding-bottom: 30px;
}
</style>
