// store/index.js

import { createStore } from 'vuex'

export default createStore({
  state: {
    isAuthenticated: JSON.parse(localStorage.getItem('isAuthenticated')) || false,
    alert: {
      show: false,
      message: '',
      color: 'success'
    },
    loading: false
  },
  getters: {
    isAuthenticated: state => state.isAuthenticated,
    alertState(state) {
      return state.alert;
    },
    loadingState(state) {
      return state.loading;
    }
  },
  mutations: {
    setAuthentication(state, status) {
      state.isAuthenticated = status;
      localStorage.setItem('isAuthenticated', JSON.stringify(status));
    },
    SET_ALERT(state, { show, message, color }) {
      state.alert.show = show;
      state.alert.message = message;
      state.alert.color = color;
    },
    HIDE_ALERT(state) {
      state.alert.show = false;
    },
    SET_LOADING(state, status) {
      state.loading = true;
    },
    HIDE_LOADING(state) {
      state.loading = false;
    }
  },
  actions: {
    login({ commit }) {
      commit('setAuthentication', true);
    },
    logout({ commit }) {
      commit('setAuthentication', false);
    },
    showAlert({ commit }, { message, color }) {
      commit('SET_ALERT', { show: true, message, color });
    },
    hideAlert({ commit }) {
      commit('HIDE_ALERT');
    },
    showLoading({ commit}) {
      commit('SET_LOADING')
    },
    hideLoading({ commit }){
      commit('HIDE_LOADING')
    }
  },
  modules: {},
});
