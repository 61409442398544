<template>
  <v-row align="center" class="text-caption">
    <!-- Back Button -->
    <v-col cols="auto"  v-if="showBackButton">
      <v-btn variant="text" prepend-icon="mdi-arrow-left" @click="goBack">
        <template v-slot:prepend>
          <v-icon color="primary"></v-icon>
        </template>

        Back


      </v-btn>
    </v-col>

    <!-- Spacer -->
    <v-spacer></v-spacer>

    <!-- Breadcrumbs -->
    <v-col cols="auto">
      <v-breadcrumbs :items="items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :key="item.title" :to="item.href" @click.native="navigate(item.href)">
            {{ item.title }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    showBackButton() {
      // Check if the current route's path contains "add", "edit", or "view"
      const path = this.$router.currentRoute.value.fullPath;
      return /add|edit|view/.test(path); // Use regex to match any of these words
    }
  },
  methods: {
    navigate(href) {
      if (href && !this.$router.currentRoute.value.fullPath.includes(href)) {
        this.$router.push(href);
      }
    },
    goBack() {
      this.$router.back();
    }
  }
}
</script>

<style scoped>
/* Optional: Adjust spacing and alignment */
.v-row {
  display: flex;
  align-items: center;
}
</style>
