// plugins/vuetify.js
import '@mdi/font/css/materialdesignicons.css'; // Font import first
import 'vuetify/styles'; // Vuetify styles second
import App from '../App.vue'; // Your main app component
import { createVuetify } from 'vuetify'; // Create Vuetify instance
import * as components from 'vuetify/components'; // Import Vuetify components
import * as directives from 'vuetify/directives'; // Import Vuetify directives
import colors from 'vuetify/util/colors'; // Import color utilities

export default createVuetify({
  components,
  directives,
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          primary: '#0CA4BC', // Custom primary color
          secondary: colors.teal.lighten4,
          success: colors.green.lighten2,
          error: colors.red.lighten2,
        },
      },
    },
  },
  defaults: {
    VTextField: {
      hideDetails: true,
      variant: 'outlined',
      // bgColor: '#EEEAEA',
      density: 'compact',
      class: ['custom-text-field']
    },
    VTextarea: {
      hideDetails: true,
      variant: 'outlined',
      // bgColor: '#EEEAEA',
      density: 'compact',
      class: ['custom-textarea']
    },
    VSelect: {
      hideDetails: true,
      variant: 'outlined',
      density: 'compact',
      // bgColor: '#EEEAEA',
    },
    VDateInput: {
      hideDetails: true,
      density: 'compact',
    },
    VCard: {
      elevation: 0,
      // minHeight: '250px'
    },
    VBtn: {
      size: 'small',
      class: 'text-none',
      elevation: '0'
    },
    VIcon: {
      size: 'small'
    },  
    VDataTable: {
      density: 'compact',
      class: ['text-caption', 'elevation-0'],
    },
    VCheckbox: {
      density: 'compact',
      class: 'text-caption',
      hideDetails: true
    },
    VFab: {
      size: 'x-small'
    },
    VList: {
      density: 'compact',
      slim: true,
      variant: 'text'
    },
    VListItem: {
      density: 'compact',
      slim: true,
    },
    VListItemTitle: {
      class: 'text-caption'
    },
    VRow: {
      align: 'center'
    },
    VAlert: {
      class: 'text-caption'
    },
    VBottomNavigation: {
      elevation: 0,
      bgColor: '#EEEAEA',
      density: 'compact',
    },
    VToolbar: {
      elevation: 1
    },
    VProgressCircular: {
      bgColor: '#EEEAEA',
    }
  },
});
