// main.js
import '@vueup/vue-quill/dist/vue-quill.snow.css'; //Ensure that Vuetify and Vue Quill styles are properly loaded before the app starts.
import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import axios from './plugins/axios';
import i18n from './i18n'
import { QuillEditor } from '@vueup/vue-quill'
import { VDateInput } from 'vuetify/labs/VDateInput'
import { VCalendar } from 'vuetify/labs/VCalendar'
import VueNumberFormat from '@coders-tm/vue-number-format'

const app = createApp(App);

app.use(i18n);
app.use(VueNumberFormat);
app.component('QuillEditor', QuillEditor)
app.component('VDateInput', VDateInput)
app.component('VCalendar', VCalendar)
app.config.globalProperties.$axios = axios;
app.use(store)
  .use(router)
  .use(vuetify)
  .mount('#app');

