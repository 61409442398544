import axios from 'axios';
import router from '../router';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE,   
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor to include the Bearer token in the Authorization header
instance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('authToken');
    
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    
    return config;
  },
  error => Promise.reject(error)
);

// Add a response interceptor to handle expired signatures
instance.interceptors.response.use(
  response => response,
  async error => {
    if(error.response.data.error === 'Signature has expired' 
      || error.response.data.error === "You need to sign in or sign up before continuing." 
      || error.response.data.error === "revoked token"
      || error.response.data.error === "Your session expired. Please sign in again to continue.") {
      if(router.currentRoute.value.fullPath !== '/') {
        localStorage.setItem('redirectPath', router.currentRoute.value.fullPath);
      }
      localStorage.removeItem('authToken');
      await  router.push('/');
    } else if (error.response && error.response.headers['content-type'].includes('text/html')) {
      console.warn('2',error)
      const parser = new DOMParser();
      const doc = parser.parseFromString(error.response.data, 'text/html');
      const errorMessage = doc.querySelector('body').innerText || '';
      // console.log(error);
      if (errorMessage.includes('Signature has expired')) {
        if(router.currentRoute.value.fullPath !== '/') {
          localStorage.setItem('redirectPath', router.currentRoute.value.fullPath);
        }
        // Clear the token and redirect to login page
        localStorage.removeItem('authToken');
        await  router.push('/');
      }
    } else {
      console.warn('3',error)
      // localStorage.setItem('redirectPath', router.currentRoute.value.fullPath);
      // localStorage.removeItem('authToken');
      // router.push({ name: 'Login' });
    }
    
    return Promise.reject(error);
  }
);

// instance.interceptors.response.use(
//   response => response,
//   error => {
//     if(error.response.data.error === 'Signature has expired' 
//       || error.response.data.error === "You need to sign in or sign up before continuing." 
//       || error.response.data.error === "revoked token"
//       || error.response.data.error === "Your session expired. Please sign in again to continue.") {

//       localStorage.setItem('redirectPath', router.currentRoute.value.fullPath);
//       localStorage.removeItem('authToken');
//       router.push({ name: 'Login' });
//     } else if (error.response && error.response.headers['content-type'].includes('text/html')) {
//       const parser = new DOMParser();
//       const doc = parser.parseFromString(error.response.data, 'text/html');
//       const errorMessage = doc.querySelector('body').innerText || '';
//       // console.log(error);
//       if (errorMessage.includes('Signature has expired')) {
//         localStorage.setItem('redirectPath', router.currentRoute.value.fullPath);
//         // Clear the token and redirect to login page
//         localStorage.removeItem('authToken');
//         router.push({ name: 'Login' });
//       }
//     } else {
//       // localStorage.setItem('redirectPath', router.currentRoute.value.fullPath);
//       // localStorage.removeItem('authToken');
//       // router.push({ name: 'Login' });
//     }
    
//     return Promise.reject(error);
//   }
// );


export default instance;
