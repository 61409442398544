<template>
   <Breadcrumbs :items="breadcrumbItems" />
   <v-card elevation="0">
        <template v-slot:append>
            <v-btn class="text-none" color="primary" @click="handleSubmit">
                Save
            </v-btn>
        </template>

        <v-divider></v-divider>
        <v-card-text>
          
          <v-row class="mt-2">
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Period</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-select v-model="period"
                :items="periods" item-value="id" item-title="title" required></v-select>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Event Title</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-select v-model="title"
                :items="weekOptions" required></v-select>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Working Days</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-text-field v-model="working_days" label="Working Days"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Date Range</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-date-input
                v-model="dataRange"
                label="Select Date Range"
                multiple="range"
                prepend-icon=""
                variant='outlined'
              ></v-date-input>
            </v-col>
          </v-row>
        </v-card-text>
    </v-card>
  </template>
  
  <script>
  import { useDate } from "vuetify";
  import axios from '../plugins/axios';
  import Breadcrumbs from '@/components/Breadcrumbs.vue';
  import { mapActions } from 'vuex';
  import breadcrumbConfig from '@/others/breadcrumbConfig.js';
  
  export default {
    components: {
      Breadcrumbs
    },
    data: () => ({
      dialog: false,
      dataRange: null,
      title: "",
      type: "month",
      types: ["month", "week", "day"],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      working_days: null,
      value: [new Date()],
      events: [],
      colors: ['#FF5733', '#33FF57', '#3357FF', '#F0FF33'], // Define your colors here
      weekOptions: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
      currentColorIndex: 0, // Initialize the current color index
      eventId: null,
      periods: [],
      period: null
    }),
    computed: {
      isAdding() {
        return !this.$route.params.id;
      },
      breadcrumbItems() {
        const action = this.$route.params.action;
        const route = this.$route.name;
        const items = breadcrumbConfig[route];
  
        if (typeof items === 'function') {
          return items(action);
        }
  
        return items || [];
      }
    },
    mounted() {
      this.fetchCalendar(); 
      this.fetchPeriod();

      this.eventId = this.$route.params.id;
      if (this.eventId) {
        this.fetchEventDetails(); // Fetch event if in edit mode
      }   
    },
    methods: {
      ...mapActions(['showAlert']),
      async fetchEventDetails() {
        try {
          const response = await axios.get(`/calendar_manager/${this.eventId}`);
          const event = response.data;
          
          // Populate form fields with fetched data
          this.period = event.period.id;
          this.title = event.title;
          this.working_days = event.working_days;
          this.dataRange = [new Date(event.start_date), new Date(event.end_date)];
        } catch (error) {
          this.showAlert({ message: 'Failed to load event details', color: 'error' });
        }
      },
      async fetchCalendar() {
        this.loading = true;
        try {
          const response = await axios.get('/calendar_manager');
          const fetchedEvents = response.data;

          this.events = fetchedEvents.flatMap(event => this.generateEventRange(event));
          this.loading = false;
        } catch (error) {
          this.loading = false;
          const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'An error occurred';
  
          this.showAlert({ message: errorMessage, color: 'error' });
        }
      },
      generateEventRange(event) {
        const period = event.period_id;
        const startDate = new Date(event.start_date);
        const endDate = new Date(event.end_date);
        const eventRange = [];

        // Loop through each date between start and end
        for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
          eventRange.push({
            period_id: period,
            title: event.title,
            start: new Date(d),          // Set the current date in the range
            end: new Date(d),            // Same as start, since it's a single-day event
            color: event.color,          // Set the color for the event
            timed: event.timed           // Optional: timed event
          });
        }

        return eventRange;  // Return the array of event objects for the range
      },
      addEvent() {
          if (this.dataRange && this.dataRange.length > 0) {
            // Get the color for this group of events
            const currentColor = this.colors[this.currentColorIndex];
            // console.log(this.dataRange)
            // Loop through the dataRange values to create events
            // this.dataRange.forEach((date) => {
            //     // Create a new event for each date
            //     this.events.push({
            //       title: this.title || "Untitled Event",
            //       start: date, // Set the current date as start
            //       end: date,   // Set the current date as end (single-day event)
            //       color: currentColor, // Use the current group color
            //       timed: false, // Set timed to false to make it span full days
            //     });
            // });

            // Increment the color index for the next group
            this.currentColorIndex = (this.currentColorIndex + 1) % this.colors.length;

            // Reset dialog
            this.dialog = false;

            // if (this.events.length > 0) {
              this.handleSubmit();
            // }
          }
      },
      async handleSubmit() {
        try {
          let eventsToSave = null;
          // Ensure that there are events and get the start date from the first event and the end date from the last event
          if (this.dataRange && this.dataRange.length > 0) {
            const eventStart = this.dataRange[0];  // First event's start date
            const eventEnd = this.dataRange[this.dataRange.length - 1] || this.dataRange[this.dataRange.length - 1];  // Last event's end date or fallback to start if end is not provided

            // Prepare the data object to save 
            eventsToSave = {
              period_id: this.period,
              title: this.title, 
              start_date: eventStart.toLocaleDateString('en-CA'),
              end_date: eventEnd.toLocaleDateString('en-CA'),
              working_days: this.working_days,
              color: this.generateColor({
                title: this.title,
                start_date: this.eventStart,
                end_date: this.eventEnd
              })
            };
          }

          if (this.isAdding) {
            // Make a POST request to create a new calendar event
            const response = await axios.post('/calendar_manager', { calendar_manager: eventsToSave });
            this.showAlert({ message: 'Calendar data successfully saved', color: 'success' });

            if (response.data) {
              this.$router.push({ name: 'calendar' });
              this.dataRange = null;
            } else {
              throw new Error(response.message);
            }
          } else {
            // Update an existing event
            const response = await axios.put(`/calendar_manager/${this.eventId}`, { calendar_manager: eventsToSave });
            this.showAlert({ message: 'Calendar data successfully updated', color: 'success' });

            if (response.data) {
              this.$router.push({ name: 'calendar' });
            } else {
              throw new Error('Failed to update calendar');
            }
          }
        } catch (error) {
          const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'An error occurred';

          this.showAlert({ message: errorMessage, color: 'error' });
        }
      },
      generateColor(eventData) {
        // Combine event data (like title, start_date, etc.) into a single string
        const dataString = `${eventData.title}${eventData.start_date}${eventData.end_date}`;
        
        // Create a hash from the data string
        let hash = 0;
        for (let i = 0; i < dataString.length; i++) {
          hash = dataString.charCodeAt(i) + ((hash << 5) - hash);
        }
        
        // Convert the hash to a hexadecimal color code
        let color = '#';
        for (let i = 0; i < 3; i++) {
          const value = (hash >> (i * 8)) & 0xFF;
          color += ('00' + value.toString(16)).substr(-2);
        }

        return color;
      },
      async fetchPeriod() {
        this.loading = true;
        try {
          const response = await axios.get('/period');
          this.periods = response.data;
          this.loading = false;
        } catch (error) {
          this.loading = false;
          const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'An error occurred';

          this.showAlert({ message: errorMessage, color: 'error' });
        }
      },
    },
  };
  </script>

  <style>
    .v-calendar .v-calendar-day .v-calendar-event {
        background-color: transparent; /* Make the event background transparent */
        border-left: 3px solid currentColor; /* Create a colored left border */
        border-right: 3px solid currentColor; /* Create a colored right border */
        position: relative; /* Allow for absolute positioning */
    }

    .v-calendar .v-calendar-day .v-calendar-event span {
        display: block;
        width: 100%; /* Make the inner span take full width */
    }

</style>
  