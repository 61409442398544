<template>
    <Breadcrumbs :items="breadcrumbItems" />
    <v-row>
        <v-col cols="12" class="d-flex justify-end">
            <v-btn color="primary" @click="navigateToForm('add')">{{ $t('button.newApprovalMatrix') }}</v-btn>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <data-table :headers="headers" :items="approvals" :actions="tableActions" :loading="loading" :filterAttributes="['Active', 'Inactive']"
            @filterChanged="fetchFilteredItems" :filterLabel="'Status'" :filter1="true"/>
        </v-col>
    </v-row>
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import breadcrumbConfig from '@/others/breadcrumbConfig';
import DataTable from '@/components/DataTable.vue';
import { mapActions } from 'vuex';

export default {
    components: {
        Breadcrumbs,
        DataTable
    },
    data() {
        return {
            headers: [
                { title: 'Module', key: 'module', align: 'start', clickable: true, sortable: true},
                { title: 'Coverage Type', key: 'coverage_type', align: 'start', clickable: true, sortable: true },
                { title: 'Transaction Type', value: 'transaction_type.name', align: 'start', clickable: true, sortable: true },
                { title: 'Approver Type', key: 'approver_type', align: 'start', clickable: true, sortable: true },
                { title: 'Start Date', key: 'start_date', align: 'start', clickable: true, sortable: true },
                { title: 'End Date', key: 'end_date', align: 'start', clickable: true , sortable: true},
                { title: 'Status', key: 'status', align: 'start', sortable: true },
                { title: '', key: 'actions', align: 'end' },
            ],
            approvals: [],
            tableActions: {
                edit: this.navigateToForm,
                view: this.navigateToForm
            }
        };
    },
    computed: {
        breadcrumbItems() {
            const route = this.$route.name;
            const action = this.$route.params.action;
            const breadcrumbItems = breadcrumbConfig[route];

            // Handle case where the route is dynamic, e.g., 'approval-form'
            if (typeof breadcrumbItems === 'function') {
                return breadcrumbItems(action);
            }

            return breadcrumbItems || [];
        }
    },
    methods: {
        ...mapActions(['showAlert']),
        async fetchApprovals() {
            try {
                const response = await axios.get('/approval');
                console.log(response.data);
                this.approvals = response.data;
            } catch (error) {
                const errorMessage = error.response?.data?.messages
                    ? error.response.data.messages
                    : error.response?.message || 'An error occurred';

                this.showAlert({ message: errorMessage, color: 'error' });
            }
        },
        navigateToForm(action, approval = { id: null }) {
            if (action == 'view') {
                this.$router.push({ name: 'approval-view', params: { action, id: approval.id } });
            } else {
                this.$router.push({ name: 'approval-form', params: { action, id: approval.id } });
            }
        },
        async deleteApproval(approvalToDelete) {
            try {
                const response = await axios.delete(`/approval/${approvalToDelete.id}`);

                if (response.data) {
                    this.approvals = this.approvals.filter(u => u.id !== approvalToDelete.id);
                    this.showAlert({ message: 'Approval deleted successfully', color: 'success' });
                } else {
                    this.showAlert({ message: 'Failed to delete approvals', color: 'error' });
                }
            } catch (error) {
                const errorMessage = error.response?.data?.messages
                    ? error.response.data.messages
                    : error.response?.message || 'An error occurred';

                this.showAlert({ message: errorMessage, color: 'error' });
            }
        },
        async fetchFilteredItems(filters) {
          this.loading = true;

          try {
            // Handle Proxy or Target Structure
            const actualFilters = filters.target || filters;

            const params = {};

            // Map filter indices to keys
            if (actualFilters[1]) {
            params.status = actualFilters[1]; // "Draft" or other statuses
            }

            // Construct query string
            const queryString = new URLSearchParams(params).toString();
            // Make API request
            const response = await axios.get(`/approval?${queryString}`);
            this.approvals = response.data;
          } catch (error) {
            const errorMessage =
            error.response?.data?.messages ||
            error.response?.message ||
            "An error occurred";
            this.showAlert({ message: errorMessage, color: "error" });
          }

          this.loading = false;
        },
    },
    mounted() {
        this.fetchApprovals();
    }
};
</script>