<template>
    <Breadcrumbs :items="breadcrumbItems" />
    <v-row>
        <v-col cols="12" class="d-flex justify-end">
            <v-btn color="primary" @click="navigateToForm('add')" v-if="this.user.coverage.coverage_type !== 'Global'">{{ $t('button.newRollingForecast') }}</v-btn>
            <v-btn class="ml-2" color="primary" @click="recalculateCurrentPeriod" >Recalculate Current Period</v-btn>
          </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <v-layout class="overflow-visible" style="height: 40px;">
              <v-bottom-navigation v-model="type" background-color="transparent">
                <v-btn
                  v-for="(buttonLabel, index) in buttonLabels"
                  :key="index"
                  :class="{
                    'active-btn': type === index // Apply active class when button is selected
                  }"
                  @click="fetchFilteredItems"
                >
                  {{ buttonLabel }}
                </v-btn>
              </v-bottom-navigation>
            </v-layout>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
          <data-table 
            :headers="headers" 
            :items="rofo" 
            :actions="tableActions" 
            :filterLabel="'Status'" 
            :filter1="true"
            :filterAttributes="['Draft', 'Awaiting for Approval', 'Request for Revise', 'Approved', 'Cancelled']"
            :filterLabel2="'Period'" 
            :filter2="true"
            :filterAttributes2="period"
            :filterLabel3="'Transaction Date'" 
            :filter3="true"
            @filterChanged="fetchFilteredItems"
          />
        </v-col>
    </v-row>
    <v-overlay
      v-if="loading"
      :model-value="loading"
      class="align-center justify-center"
      persistent
    >
      <v-progress-circular
        color="primary"
        size="120"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import breadcrumbConfig from '@/others/breadcrumbConfig';
import { mapActions } from 'vuex';
import DataTable from '@/components/DataTable.vue';

export default {
    components: {
        Breadcrumbs,
        DataTable
    },
    data() {
        return {
            user: JSON.parse(localStorage.getItem('userData')),
            type: 0,
            loading: false,
            headers: [
                { title: 'Transaction Number', key: 'transaction_number', align: 'start', clickable: true , sortable: true},
                { title: 'Period', value: 'period.name', align: 'start' , sortable: true},
                { title: 'Transaction Date', key: 'transaction_date', align: 'start', sortable: true },
                { title: 'District', key: 'district', align: 'start' , sortable: true},
                { title: 'Area', key: 'area', align: 'start' , sortable: true},
                { title: 'Region', key: 'region', align: 'start' , sortable: true},
                { title: 'Created By', key: 'owner_name', align: 'start' , sortable: true},
                { title: 'Status', key: 'status', align: 'start' },
            ],
            rofo: [],
            tableActions: {
                // edit: this.navigateToForm,
                // delete: this.deleteRofo,
                view: this.navigateToForm
            },
            period: [],
            buttonLabels: ['District', 'Area', 'Region', 'Domestic'],
        };
    },
    computed: {
        breadcrumbItems() {
            const route = this.$route.name;
            const action = this.$route.params.action;
            const breadcrumbItems = breadcrumbConfig[route];

            // Handle case where the route is dynamic, e.g., 'user-form'
            if (typeof breadcrumbItems === 'function') {
                return breadcrumbItems(action);
            }

            return breadcrumbItems || [];
        }
    },
    watch: {
        type() {
            this.fetchRofo();
        }
    },
    methods: {
        ...mapActions(['showAlert']),
        async fetchRofo() {
            this.loading = true;
            let coverageTypeParam;
            switch (this.type) {
                case 0:
                    coverageTypeParam = 'District';
                    break;
                case 1:
                    coverageTypeParam = 'Area';
                    break;
                case 2:
                    coverageTypeParam = 'Region';
                    break;
                case 3:
                    coverageTypeParam = 'Domestic';
                    break;
            }

            try {
                const response = await axios.get(`/rolling_forecast?type=${coverageTypeParam}`);
                this.rofo = response.data;
                setTimeout(() => this.loading = false, 200);
                this.loading = false;
            } catch (error) {
                const errorMessage = error.response?.data?.messages
                    ? error.response.data.messages
                    : error.response?.message || 'An error occurred';

                this.showAlert({ message: errorMessage, color: 'error' });
                this.loading = false;
            }
        },
        navigateToForm(action, rofo = { id: null }) {
            let coverageTypeParam;

            switch (this.type) {
                case 0:
                    coverageTypeParam = 'District';
                    break;
                case 1:
                    coverageTypeParam = 'Area';
                    break;
                case 2:
                    coverageTypeParam = 'Region';
                    break;
                case 3:
                    coverageTypeParam = 'Domestic';
                    break;
            }

            if (action === 'view') {
                this.$router.push({ name: 'rofo-view', params: { action, id: rofo.id, type: coverageTypeParam } });
            } else {
                this.$router.push({ name: 'rofo-form', params: { action, id: rofo.id, type: coverageTypeParam } });
            }
        },
        async deleteRofo(rofoToDelete) {
            try {
                const response = await axios.delete(`/rofo/${rofoToDelete.id}`);

                if (response.data) {
                    this.rofo = this.rofo.filter(u => u.id !== rofoToDelete.id);

                    this.showAlert({ message: 'Success', color: 'success' });
                } else {
                    throw new Error('Failed to delete rofo');
                }
            } catch (error) {
                const errorMessage = error.response?.data?.messages
                    ? error.response.data.messages
                    : error.response?.message || 'An error occurred';

                this.showAlert({ message: errorMessage, color: 'error' });
            }
        },
        async fetchFilteredItems(filters) {
          this.loading = true;

          try {
            // Handle Proxy or Target Structure
            const actualFilters = filters.target || filters;

            const params = {};

            // Map filter indices to keys
            if (actualFilters[1]) {
              params.status = actualFilters[1]; // "Draft" or other statuses
            }

            if (actualFilters[2]) {
              params.period = actualFilters[2]; // Period ID
            }

            if (actualFilters[3] && Array.isArray(actualFilters[3])) {
              // Extract first and last date from date range
              const [startDate, endDate] = [
                new Date(actualFilters[3][0]).toISOString(),
                new Date(actualFilters[3][actualFilters[3].length - 1]).toISOString(),
              ];
              params.start_date = startDate;
              params.end_date = endDate;
            }

            switch (this.type) {
                case 0:
                    params.type = 'District';
                    break;
                case 1:
                    params.type = 'Area';
                    break;
                case 2:
                    params.type = 'Region';
                    break;
                case 3:
                    params.type = 'Domestic';
                    break;
            }

            // Construct query string
            const queryString = new URLSearchParams(params).toString();
            // Make API request
            const response = await axios.get(`/rolling_forecast?${queryString}`);
            this.rofo = response.data;
          } catch (error) {
            const errorMessage =
              error.response?.data?.messages ||
              error.response?.message ||
              "An error occurred";
            this.showAlert({ message: errorMessage, color: "error" });
          }

          this.loading = false;
        },
        async fetchPeriod() {
          try {
            const response = await axios.get('/period');
            this.period = response.data;
          } catch (error) {
            const errorMessage = error.response?.data?.messages
              ? error.response.data.messages
              : error.response?.message || 'An error occurred';

            this.showAlert({ message: errorMessage, color: 'error' });
          }
        },
        async recalculateCurrentPeriod() {
          this.loading = true;
          try {
            const response = await axios.get('/rolling_forecast/recalculate'); // Adjust api route/path
            // this.rofo = response.data;
            this.loading = false;
          } catch (error) {
            const errorMessage = error.response?.data?.messages
              ? error.response.data.messages
              : error.response?.message || 'An error occurred';

            this.showAlert({ message: errorMessage, color: 'error' });
            this.loading = false;
          }
        }
    },
    mounted() {
        this.fetchRofo();
        this.fetchPeriod();
    }
};
</script>

<style scoped>
:deep(.v-bottom-navigation__content) {
    justify-content: left !important;
}

.active-btn {
  background-color: #0CA4BC; 
  color: white;
}
</style>