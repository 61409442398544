<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <v-btn size="small" icon @click.stop="rail = !rail" v-show="!isMobile">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer" v-show="isMobile"></v-app-bar-nav-icon>

      <v-btn class="toolbar-title" variant="text" @click="goToHomePage">
        <v-toolbar-title class="text-caption">ABC Sales Insight</v-toolbar-title>
      </v-btn>
      <v-spacer></v-spacer>
      <div class="text-caption">Hi. {{userData.full_name}}</div>
      <!-- Language Switcher -->
      <!-- <v-menu v-model="languageMenu" :close-on-content-click="false" location="end">
        <template v-slot:activator="{ props }">
          <v-btn icon v-bind="props">
            <v-icon>mdi-earth</v-icon>
          </v-btn>
        </template>
        <v-card min-width="200">
          <v-list>
            <v-list-item @click="changeLanguage('en')" :class="{ 'active-language': currentLanguage === 'en' }">
              <v-list-item-title>{{ $t('language.english') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="changeLanguage('id')" :class="{ 'active-language': currentLanguage === 'id' }">
              <v-list-item-title>{{ $t('language.indonesian') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu> -->

      <v-menu v-model="menu" :close-on-content-click="false" location="end">
        <template v-slot:activator="{ props }">
          <v-btn icon v-bind="props">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>

        <v-card min-width="300">
          <v-list>
            <v-list-item prepend-avatar="" subtitle="Role"
              :title="userData.full_name">
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-row>
            <v-col class="ma-4 text-caption" cols="12">{{ userData.username }}</v-col>
          </v-row>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn variant="text" @click="menu = false">
              {{ $t('actions.close') }}
            </v-btn>
            <v-btn color="danger" variant="text" @click="logout">
              {{ $t('actions.logout') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-app-bar>

    <!-- <v-navigation-drawer v-model="rail" permanent> -->
    <v-navigation-drawer :rail="rail" permanent v-model="drawer" style="padding-bottom: 10vh;"  :location="$vuetify.display.xs ? 'bottom' : undefined" class="scroll-bar-thin">
      <v-list density="compact" nav v-model:opened="open" v-show="!isMobile">
        <v-list-subheader>Main Menu</v-list-subheader>
        <v-list-item prepend-icon="mdi-book-multiple" to="/home/rofo" :title="$t('navigation.rollingForecast')"
          value="rollingForecast"></v-list-item>
        <v-list-item prepend-icon="mdi-book-multiple" to="/home/cmo" :title="$t('navigation.cmo')"
          value="cmo"></v-list-item>
        <v-list-item prepend-icon="mdi-book-multiple" to="/home/memoDiscount" :title="$t('navigation.memoDiscount')"
          value="memo"></v-list-item>
        <v-divider></v-divider>
        <v-list-subheader>Notification Center</v-list-subheader>
        <!-- <v-list-item prepend-icon="mdi-checkbox-marked-circle" to="/home/approvalNeeded" :title="'Approval Needed'" value="approvalNeeded"></v-list-item> -->
        <v-list-item prepend-icon="mdi-email" to="/home/notification" :title="'Notification'" value="notification">
          <template v-slot:append>
            <v-badge
              v-if="unreadNotification !== 0"
              color="info"
              :content="unreadNotification"
              inline
            ></v-badge>
          </template>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-subheader>Configuration</v-list-subheader>
        <v-list-group value="Users">
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="props" prepend-icon="mdi-account-circle"
              :title="$t('navigation.userManagement')"></v-list-item>
          </template>

          <v-list-item :prepend-icon="rail == true ? 'mdi-account-cog' : ''" to="/home/employee"
            :title="$t('navigation.employee')" value="employee" :style="!rail ? { 'margin-left': '1.5vw' } : {  }"></v-list-item>

          <v-list-item :prepend-icon="rail == true ? 'mdi-account-cog' : ''" to="/home/user"
            :title="$t('navigation.user')" value="user" :style="!rail ? { 'margin-left': '1.5vw' } : {  }"></v-list-item>

          <v-list-item :prepend-icon="rail == true ? 'mdi-account-lock' : ''" to="/home/role"
            :title="$t('navigation.rolePermission')" value="role" :style="!rail ? { 'margin-left': '1.5vw' } : {  }"></v-list-item>
        </v-list-group>
        <v-list-group value="Approval">
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="props" prepend-icon="mdi-cog"
              :title="$t('navigation.systemConfiguration')"></v-list-item>
          </template>
          <v-list-item :prepend-icon="rail == true ? 'mdi-time' : ''" 
            :style="!rail ? { 'margin-left': '1.5vw' } : {  }"
            to="/home/period"
            :title="$t('navigation.period')" 
            value="period">
          </v-list-item>
          <v-list-item :prepend-icon="rail == true ? 'mdi-ab-testing' : ''" to="/home/approval" :title="$t('navigation.approvalMatrix')"
            value="approval" :style="!rail ? { 'margin-left': '1.5vw' } : {  }"></v-list-item>
          <v-list-item :prepend-icon="rail == true ? 'mdi-ab-testing' : ''" to="/home/rollingForecastConfiguration" :title="$t('navigation.rofoConfig')"
            value="rofoConfig" :style="!rail ? { 'margin-left': '1.5vw' } : {  }"></v-list-item>
          <v-list-item :prepend-icon="rail == true ? 'mdi-ab-testing' : ''" to="/home/rofoOpeningBalance" :title="$t('navigation.rofoOpeningBalance')"
            value="rofoOpeningBalance" :style="!rail ? { 'margin-left': '1.5vw' } : {  }"></v-list-item>
          <v-list-item :prepend-icon="rail == true ? 'mdi-ab-testing' : ''" to="/home/cmoConfiguration" :title="$t('navigation.cmoConfig')"
            value="cmoConfig" :style="!rail ? { 'margin-left': '1.5vw' } : {  }"></v-list-item>
        </v-list-group>
        <v-list-group value="Master">
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="props" prepend-icon="mdi-book" :title="$t('navigation.master')"></v-list-item>
          </template>
          <v-list-item :prepend-icon="rail == true ? 'mdi-book-multiple' : ''" to="/home/transactionType"
            :title="$t('navigation.transactionType')" value="transactionType" :style="!rail ? { 'margin-left': '1.5vw' } : {  }"></v-list-item>
          <v-list-item :prepend-icon="rail == true ? 'mdi-map-legend' : ''" to="/home/coverage" :title="$t('navigation.coverage')"
            value="coverage" :style="!rail ? { 'margin-left': '1.5vw' } : {  }"></v-list-item>
          <v-list-item :prepend-icon="rail == true ? 'mdi-home-group' : ''" 
            :style="!rail ? { 'margin-left': '1.5vw' } : {  }"
            to="/home/distributor"
            :title="$t('navigation.distributor')" 
            value="distributor">
          </v-list-item>
          <v-list-item :prepend-icon="rail == true ? 'mdi-package-variant-closed-check' : ''" to="/home/product"
            :title="$t('navigation.product')" value="product" :style="!rail ? { 'margin-left': '1.5vw' } : {  }"></v-list-item>
          <v-list-item :prepend-icon="rail == true ? 'mdi-tournament' : ''" to="/home/productDivision"
          :title="$t('navigation.productDivision')" value="productDivision" :style="!rail ? { 'margin-left': '1.5vw' } : {  }"></v-list-item>
          <v-list-item :prepend-icon="rail == true ? 'mdi-cube-outline' : ''" to="/home/productCategory"
          :title="$t('navigation.productCategory')" value="productCategory" :style="!rail ? { 'margin-left': '1.5vw' } : {  }"></v-list-item>
          <v-list-item :prepend-icon="rail == true ? 'mdi-cube-outline' : ''" to="/home/productGroup"
          :title="$t('navigation.productGroup')" value="productGroup" :style="!rail ? { 'margin-left': '1.5vw' } : {  }"></v-list-item>
          <v-list-item :prepend-icon="rail == true ? 'mdi-currency-usd' : ''" to="/home/price"
          :title="$t('navigation.price')" value="price" :style="!rail ? { 'margin-left': '1.5vw' } : {  }"></v-list-item>
          <v-list-item :prepend-icon="rail == true ? 'mdi-check-outline' : ''" to="/home/uom"
          :title="$t('navigation.uom')" value="uom" :style="!rail ? { 'margin-left': '1.5vw' } : {  }"></v-list-item>
          <v-list-item :prepend-icon="rail == true ? 'mdi-vehicle' : ''" to="/home/vehicle"
            :title="$t('navigation.vehicle')" value="vehicle" :style="!rail ? { 'margin-left': '1.5vw' } : {  }"></v-list-item>
          <v-list-item :prepend-icon="rail == true ? 'mdi-calendar' : ''" to="/home/calendar"
            :title="$t('navigation.calendar')" value="calendar" :style="!rail ? { 'margin-left': '1.5vw' } : {  }"></v-list-item>
          <v-list-item :prepend-icon="rail == true ? 'mdi-promotion' : ''" to="/home/promotion"
            :title="$t('navigation.promotion')" value="promotion" :style="!rail ? { 'margin-left': '1.5vw' } : {  }"></v-list-item>
        </v-list-group>
        <v-divider></v-divider>
        <v-list-subheader>Supporting Module</v-list-subheader>
        <v-list-group value="Support Module">
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="props" prepend-icon="mdi-note-plus-outline"
              title="Supporting Module"></v-list-item>
          </template>
          <v-list-item :prepend-icon="rail == true ? 'mdi-account-cog' : ''" to="/home/sellIn"
            title="Sell In" value="sellIn" :style="!rail ? { 'margin-left': '1.5vw' } : {  }"></v-list-item>
          <v-list-item :prepend-icon="rail == true ? 'mdi-account-cog' : ''" to="/home/sellOut"
            title="Sell Out" value="sellOut" :style="!rail ? { 'margin-left': '1.5vw' } : {  }"></v-list-item>
          <v-list-item :prepend-icon="rail == true ? 'mdi-account-lock' : ''" to="/home/salesTarget"
            title="Sales Target" value="salesTarget" :style="!rail ? { 'margin-left': '1.5vw' } : {  }"></v-list-item>
          <v-list-item :prepend-icon="rail == true ? 'mdi-account-lock' : ''" to="/home/defiasiLimit"
            title="Defiation Limit" value="defiasiLimit" :style="!rail ? { 'margin-left': '1.5vw' } : {  }"></v-list-item>
        </v-list-group>
      </v-list>


      <v-list density="compact" nav v-model:opened="open" v-show="isMobile">
        <v-list-subheader>Notification Center</v-list-subheader>
        <!-- <v-list-item prepend-icon="mdi-checkbox-marked-circle" to="/home/approvalNeeded" :title="'Approval Needed'" value="approvalNeeded"></v-list-item> -->
        <v-list-item prepend-icon="mdi-email" to="/home/notification" :title="'Notification'" value="notification"></v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid class="d-flex align-center justify-center"
        v-if="loading"
      >
        <v-row
          no-gutters
        >
          <v-col class="d-flex justify-center mb-4"
          >
            <v-progress-circular indeterminate :size="100"></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>     
      <v-container fluid v-else>
        <template v-if="isHomeRoute">
          <!-- Dashboard Content -->
          <v-empty-state headline="Welcome,"
            image="https://cdn.vuetifyjs.com/docs/images/components/v-empty-state/teamwork.png"
            title="What would you like to do today?">
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-card to="/home/user" prepend-icon="mdi-account"
                    text="Manage user credentials and permissions" title="User Management"></v-card>
                </v-col>

                <v-col cols="12" md="6">
                  <v-card to="/home/rofo" prepend-icon="mdi-book" text="Create a rolling forecast document"
                    title="Rolling Forecast"></v-card>
                </v-col>

                <v-col cols="12" md="6">
                  <v-card prepend-icon="mdi-delete"
                    text="Create a new bin to store your code" title="Create a Bin"></v-card>
                </v-col>

                <v-col cols="12" md="6">
                  <v-card prepend-icon="$warning" text="File a bug report for Vuetify"
                    title="Report a Bug"></v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-empty-state>
        </template>
        <template v-else>
          <Alert></Alert>
          <!-- Router View for Other Routes -->
          <router-view></router-view>
        </template>
      </v-container>
    </v-main>
    <v-footer class="footer text-caption d-flex justify-center align-center">
      <div>©{{ new Date().getFullYear() }} - <a :href="'//' + 'intelion.co.id'" target="_blank">Intelion</a></div>
    </v-footer>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import { useI18n } from 'vue-i18n';
import Alert from '@/components/Alert.vue';
import { useDisplay } from 'vuetify';
import axios from '../plugins/axios';
import { mapActions } from 'vuex';

export default {
  components: {
    Alert,
  },
  data() {
    return {
      open: [],
      rail: false,
      drawer: true,
      menu: false,
      languageMenu: false,
      currentLanguage: this.$i18n.locale,
      userData: null,
      isMobile: false,
      unreadNotification: 0,
      loading:false
    };
  },
  computed: {
    ...mapGetters(['loadingState']),
    loading() {
      return this.loadingState;
    },
    isHomeRoute() {
      return this.$route.name === 'home';
    }
  },
  watch: {
    '$i18n.locale'(newLocale) {
      this.currentLanguage = newLocale;
    },
    '$vuetify.display.xs'(newVal) {
      this.isMobile = newVal;
    },
    $route() {
      this.fetchNotification(); // Fetch notifications whenever route changes
    }
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem('userData'));
    this.userData = userData;

    const { xs } = useDisplay();
    this.isMobile = xs.value;

    this.fetchNotification();

  },
  methods: {
    ...mapActions(['showAlert']),
    goToProfile() {
      this.$router.push({ name: 'profile' });
    },
    async logout() {
      try {
        await axios.delete('/logout');
        localStorage.removeItem('authToken');
        await this.$store.dispatch('logout');
        this.$router.push('/');
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
        
        localStorage.removeItem('authToken');
        await this.$store.dispatch('logout');
        this.$router.push('/');
      }
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang;  // Change the language using Vue I18n
    },
    goToHomePage() {
      this.$router.push({ name: 'home' });
    },
    async fetchNotification() {
      this.loading = true;
      try {
        const response = await axios.get('/notification');
        this.unreadNotification = response.data.filter(notification => notification.status === 'Unread').length;
        this.loading = false;
      } catch (error) {
        this.loading = false;
       
      }
    },
  }
};
</script>

<style scoped>
.v-list-group__items .v-list-item {
  padding-inline-start: calc(16px - 8px) !important;
}

/* customize spacer in a list-item */
:deep(.v-list-item--slim .v-list-item__prepend>.v-badge~.v-list-item__spacer),
:deep(.v-list-item--slim .v-list-item__prepend>.v-icon~.v-list-item__spacer),
:deep(.v-list-item--slim .v-list-item__prepend>.v-tooltip~.v-list-item__spacer) {
  width: 8px;
}

.active-language {
  background-color: lightblue;
}

/* html, body {
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
} */

/* #app {
    flex: 1;
    display: flex;
    flex-direction: column;
} */

/* .main-content {
    flex: 1;
} */

.footer {
  /* flex-shrink: 0; */
  display: flex;
  flex-direction: column;
  background-color: #2D3D56;
  color: white;
  text-align: center;
  /* padding: 10px; */
  z-index: 9999;
  height: 100%;
  max-height: 35px;
  /* unvisited link */
  a:link {
    color: rgb(255, 255, 255);
    text-decoration: none;
  }
  /* visited link */
  a:visited {
    color: rgb(255, 255, 255);
    text-decoration: none;
  }
}

.v-navigation-drawer {
  scrollbar-width: thin; /* Options: auto, thin, none */
  scrollbar-color: white #f1f1f1; /* thumb color and track color */
}

.v-navigation-drawer::-webkit-scrollbar {
  width: 1px; /* Set the width of the scrollbar */
}

.v-menu > .v-overlay__content > .v-card {
  overflow: hidden;
}
</style>
