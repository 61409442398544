<template>
  <Breadcrumbs :items="breadcrumbItems" />
  <v-card elevation="0" class="mt-2">
    <template v-slot:append>
      <v-btn class="text-none" color="primary" @click="goToCustomizePage('edit', price)">
        Customize
      </v-btn>
    </template>

    <v-divider></v-divider>

    <v-card-text>
      <v-row>
        <v-col lg="2" md="2" sm="2" cols="12">
          <label class="custom-label">Transaction Code</label>
        </v-col>
        <v-col md="6" sm="6" cols="12">
          <div>{{ price.transaction_code }}</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="2" md="2" sm="2" cols="12">
          <label class="custom-label">Price 1</label>
        </v-col>
        <v-col md="6" sm="6" cols="12">
          <div>{{ price.price_1 }}</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="2" md="2" sm="2" cols="12">
          <label class="custom-label">Price 2</label>
        </v-col>
        <v-col md="6" sm="6" cols="12">
          <div>{{ price.price_2 }}</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="2" md="2" sm="2" cols="12">
          <label class="custom-label">Price 3</label>
        </v-col>
        <v-col md="6" sm="6" cols="12">
          <div>{{ price.price_3 }}</div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import breadcrumbConfig from '@/others/breadcrumbConfig.js';
import { mapActions } from 'vuex';

export default {
  components: {
    Breadcrumbs,
  },
  data() {
    return {
      price: {
        id: null,
        transaction_code: null,
        price_1: null,
        price_2: null,
        price_3: null
      }
    };
  },
  computed: {
      breadcrumbItems() {
          const action = this.$route.params.action;
          const route = this.$route.name;
          const items = breadcrumbConfig[route];
          
          if (typeof items === 'function') {
              return items(action);
          }

          return items || [];
      },
  },
  async mounted() {
    const { id } = this.$route.params;

    // Fetch price details
    if (id) {
      try {
        const response = await axios.get(`/price/${id}`);
        this.price = response.data;

      } catch (error) {
        const errorMessage = error.response?.data?.messages 
                            ? error.response.data.messages 
                            : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    }
  },
  methods: {
    ...mapActions(['showAlert']),
    goToCustomizePage(action, price) {
      this.$router.push({ name: 'price-form', params: { action, id: price.id } });
    },
  },
};
</script>

<style scoped>
.custom-label {
  font-weight: bold;
  font-size: 11px;
}
</style>
