<template>
    <Breadcrumbs :items="breadcrumbItems" />
    <v-card>
      <template v-slot:title> </template>
  
      <v-divider></v-divider>
  
      <v-card-text>
        <div>
          <v-form ref="form" @submit.prevent="handleSubmit">
            <v-row>
              <v-col lg="2" md="2" sm="2" cols="12">
                <label class="custom-label">Qty Total</label>
              </v-col>
              <v-col md="6" sm="6" cols="12">
                <v-text-field v-model="sellout.qtytotal" required></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="2" md="2" sm="2" cols="12">
                <label class="custom-label">Product Number</label>
              </v-col>
              <v-col md="6" sm="6" cols="12">
                <v-text-field v-model="sellout.prdnum" required></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="2" md="2" sm="2" cols="12">
                <label class="custom-label">Month</label>
              </v-col>
              <v-col md="6" sm="6" cols="12">
                <v-text-field v-model="sellout.month" required></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="2" md="2" sm="2" cols="12">
                <label class="custom-label">Year</label>
              </v-col>
              <v-col md="6" sm="6" cols="12">
                <v-text-field v-model="sellout.year" required></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="2" md="2" sm="2" cols="12">
                <label class="custom-label">Ship to</label>
              </v-col>
              <v-col md="6" sm="6" cols="12">
                <v-text-field v-model="sellout.ship_to_number" required></v-text-field>
              </v-col>
            </v-row>
            
          </v-form>
        </div>
      </v-card-text>
  
      <template v-slot:append>
        <v-btn @click="submitForm" color="primary">
          {{ isAdding ? $t('button.save') : $t('button.update') }}
        </v-btn>
      </template>
    </v-card>
  </template>
  
  <script>
  import axios from '../plugins/axios';
  import Breadcrumbs from '@/components/Breadcrumbs.vue';
  import { mapActions } from 'vuex';
  import breadcrumbConfig from '@/others/breadcrumbConfig.js';
  
  export default {
    components: {
      Breadcrumbs
    },
    data() {
      return {
        sellout: {
          id: null,
          prdnum: '',
          qtytotal: '',
          ship_to_number: '',
          month: '',
          year:null,
        },
        product: [],
        selectedRoleId: null,
        selectedProductId: null,
      };
    },
    computed: {
      isAdding() {
        return this.$route.params.action === 'add';
      },
      breadcrumbItems() {
        const action = this.$route.params.action;
        const route = this.$route.name;
        const items = breadcrumbConfig[route];
  
        if (typeof items === 'function') {
          return items(action);
        }
  
        return items || [];
      }
    },
    async mounted() {
      const { action, id } = this.$route.params;
      // Fetch sellout details if editing
      if (action === 'edit' && id) {
        try {
          const response = await axios.get(`/selling_out/${id}`);
          this.sellout = response.data;
          this.selectedProductId = this.sellout.product_number
        } catch (error) {
          const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'An error occurred';
  
          this.showAlert({ message: errorMessage, color: 'error' });
        }
      }
    },
    methods: {
      ...mapActions(['showAlert']),
      async handleSubmit() {
        try {
          const selloutData = {
            qtytotal :this.sellout.qtytotal,
            prdnum: this.sellout.prdnum,
            month: this.sellout.month,
            year: this.sellout.year,
            ship_to_number: this.sellout.ship_to_number,
          };
  
          if (this.isAdding) {
            const response = await axios.post('/selling_out',  selloutData );
            this.showAlert({ message: 'sellout data successfully saved', color: 'success' });
            if (response.data) {
              this.$router.push({ name: 'sellOut' });
            } else {
              throw new Error(response.message);
            }
          } else {
            const response = await axios.put(`/selling_out/${this.sellout.id}`, selloutData );
            this.showAlert({ message: 'sellout data successfully update', color: 'success' });
            if (response.data) {
              this.$router.push({ name: 'sellOut' });
            } else {
              throw new Error('Failed to update sellout');
            }
          }
        } catch (error) {
          console.log(error)
          const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'An error occurred';
  
          this.showAlert({ message: errorMessage, color: 'error' });
        }
      },
      submitForm() {
        this.$refs.form.validate(); // Trigger form validation
        this.handleSubmit(); // Call the submit method
      },
      async fetchProducts() {
        try {
          const response = await axios.get('/product');
          this.product = response.data;
        } catch (error) {
          const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'An error occurred';
  
          this.showAlert({ message: errorMessage, color: 'error' });
        }
      },
      itemProps (item) {
        return {
          title: item.product_name,
          subtitle: item.sku,
        }
      },
    }
  };
  </script>