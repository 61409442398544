<template>
    <Breadcrumbs :items="breadcrumbItems" />
    <v-card elevation="0" class="mt-2">
      <template v-slot:append>
        <v-btn class="text-none" color="primary" @click="goToCustomizePage('edit', notification)">
          Customize
        </v-btn>
      </template>
  
      <v-divider></v-divider>
  
      <v-card-text>
        <v-row>
          <v-col lg="2" md="2" sm="2" cols="12">
            <label class="custom-label">Message</label>
          </v-col>
          <v-col md="6" sm="6" cols="12">
            <div>{{ notification.message }}</div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </template>
  
  <script>
  import axios from '../plugins/axios';
  import Breadcrumbs from '@/components/Breadcrumbs.vue';
  import breadcrumbConfig from '@/others/breadcrumbConfig.js';
  import { mapActions } from 'vuex';
  
  export default {
    components: {
      Breadcrumbs,
    },
    data() {
      return {
        notification: {
          id: null,
          sku: '',
          barcode: '',
          notification_name: '',
          alias_name: '',
          description: '',
          status: '',
          small_length: null,
          small_width: null,
          small_height: null,
          small_volume: null,
          small_weight: null,
          medium_length: null,
          medium_width: null,
          medium_height: null,
          medium_volume: null,
          medium_weight: null,
          large_length: null,
          large_width: null,
          large_height: null,
          large_volume: null,
          large_weight: null
        }
      };
    },
    computed: {
      breadcrumbItems() {
        const action = this.$route.params.action;
        const route = this.$route.name;
        const items = breadcrumbConfig[route];
  
        if (typeof items === 'function') {
          return items(action);
        }
  
        return items || [];
      },
    },
    async mounted() {
      const { id } = this.$route.params;
  
      // Fetch notification details
      if (id) {
        this.fetchNotification(id)
      }
    },
    methods: {
      ...mapActions(['showAlert']),
      goToCustomizePage(action, notification) {
        this.$router.push({ name: 'notification-form', params: { action, id: notification.id } });
      },
      async fetchNotification(id) {
        try {
          const response = await axios.get(`/notification/${id}`);
          this.notification = response.data;
          if (this.notification.status !== 'Read') {
            this.updateNotificationStatus(id);
          }
  
        } catch (error) {
          const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'An error occurred';
  
          this.showAlert({ message: errorMessage, color: 'error' });
        }
      },
      async updateNotificationStatus(id) {
        try {
          const notificationData = {
            status: 'Read'
          }
          const response = await axios.put(`/notification/${id}`, { notification : notificationData});
  
        } catch (error) {
          console.log(error)
          const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'Aadsfasdfed';
  
          this.showAlert({ message: errorMessage, color: 'error' });
        }
      }
    },
  };
  </script>
  
  <style scoped>
  .custom-label {
    font-weight: bold;
    font-size: 11px;
  }
  </style>
  