<template>
  <Breadcrumbs :items="breadcrumbItems" />
  <v-card>
    <template v-slot:title> </template>

    <v-divider></v-divider>

    <v-card-text>
      <div>
        <v-form ref="form" @submit.prevent="handleSubmit">
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Total Qty</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-text-field v-model="salesTarget.total_qty" required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Month</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-text-field v-model="salesTarget.month" required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Year</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-text-field v-model="salesTarget.year" required></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-card-text>

    <template v-slot:append>
      <v-btn @click="submitForm" color="primary">
        {{ isAdding ? $t('button.save') : $t('button.update') }}
      </v-btn>
    </template>
  </v-card>
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { mapActions } from 'vuex';
import breadcrumbConfig from '@/others/breadcrumbConfig.js';

export default {
  components: {
    Breadcrumbs
  },
  data() {
    return {
      salesTarget: {
        total_qty: '',
        month: null,
        year: null
      },
      product: [],
      distributor: []
    };
  },
  computed: {
    isAdding() {
      return this.$route.params.action === 'add';
    },
    breadcrumbItems() {
      const action = this.$route.params.action;
      const route = this.$route.name;
      const items = breadcrumbConfig[route];

      if (typeof items === 'function') {
        return items(action);
      }

      return items || [];
    }
  },
  async mounted() {
    const { action, id } = this.$route.params;

    this.fetchProducts()
    this.fetchDistributor()

    // Fetch salesTarget details if editing
    if (action === 'edit' && id) {
      try {
        const response = await axios.get(`/sales_target/${id}`);
        this.salesTarget = response.data;
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    }
  },
  methods: {
    ...mapActions(['showAlert']),
    async handleSubmit() {
      try {
        const salesTargetData = {
          total_qty: this.salesTarget.total_qty,
          month: this.salesTarget.month,
          year: this.salesTarget.year,
        };

        if (this.isAdding) {
          const response = await axios.post('/sales_target/', { sales_target: salesTargetData });

          if (response.data) {
            this.$router.push({ name: 'salesTarget' });
            this.showAlert({ message: 'Successfully added salesTarget data', color: 'success' });
          } else {
            this.showAlert({ message: "Failed", color: 'error' });
          }
        } else {
          const response = await axios.put(`/sales_target/${this.salesTarget.id}`, { sales_target: salesTargetData });

          if (response.data) {
            this.$router.push({ name: 'salesTarget' });
            this.showAlert({ message: 'Successfully added salesTarget data', color: 'success' });
          } else {
            this.showAlert({ message: 'Error updating salesTarget', color: 'error' });
          }
        }
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    submitForm() {
      this.$refs.form.validate(); // Trigger form validation
      this.handleSubmit(); // Call the submit method
    },
    async fetchProducts() {
      try {
        const response = await axios.get('/product');
        this.product = response.data;
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    async fetchDistributor() {
      this.loading = true;
      try {
        const response = await axios.get('/distributor');
        this.distributor = response.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    itemProps(item) {
      return {
        title: item.product_name,
        subtitle: item.sku,
      }
    },
    distributorProps(item) {
      return {
        title: item.name,
        subtitle: item.code
      }
    }
  }
};
</script>