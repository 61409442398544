<template>
    <Breadcrumbs :items="breadcrumbItems" />
    <v-row>
      <v-col cols="12" class="d-flex justify-end">
        <!-- <v-btn color="primary" @click="navigateToForm('add')">{{ $t('button.newNotification') }}</v-btn> -->
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <data-table :headers="headers" :items="notification" :actions="tableActions" :loading="loading" :mobile="isMobile" 
        :v-model="selected" :show-select="showSelect"/>
      </v-col>
    </v-row>
  </template>
  
  <script>
  import axios from '../plugins/axios';
  import Breadcrumbs from '@/components/Breadcrumbs.vue';
  import breadcrumbConfig from '@/others/breadcrumbConfig';
  import { mapActions } from 'vuex';
  import DataTable from '@/components/DataTable.vue';
  
  export default {
    components: {
      Breadcrumbs,
      DataTable
    },
    data() {
      return {
        headers: [
          { title: 'Status', key: 'status', align: 'start', sortable: true},
          { title: 'Message', key: 'message', align: 'start', clickable: true, sortable: true },
          { title: '', key: 'actions', align: 'end', sortable: false },
        ],
        notification: [],
        tableActions: {
          // edit: this.navigateToForm,
          delete: this.deleteNotification,
          view: this.navigateToForm
        },
        loading: false,
        isMobile: false,
        selected: [],
        showSelect: true,
      };
    },
    computed: {
      breadcrumbItems() {
        const route = this.$route.name;
        const action = this.$route.params.action;
        const breadcrumbItems = breadcrumbConfig[route];
  
        // Handle case where the route is dynamic, e.g., 'notification-form'
        if (typeof breadcrumbItems === 'function') {
          return breadcrumbItems(action);
        }
  
        return breadcrumbItems || [];
      }
    },
    watch: {
      '$vuetify.display.xs'(newVal) {
        this.isMobile = newVal;
      },
    },
    methods: {
      ...mapActions(['showAlert']),
      async fetchNotification() {
        this.loading = true;
        try {
          const response = await axios.get('/notification');
          this.notification = response.data;
          this.loading = false;
        } catch (error) {
          this.loading = false;
          const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'An error occurred';
  
          this.showAlert({ message: errorMessage, color: 'error' });
        }
      },
      navigateToForm(action, notification = { id: null }) {
        if (action == 'view') {
          this.$router.push({ name: 'notification-view', params: { action, id: notification.id } });
        } else {
          this.$router.push({ name: 'notification-form', params: { action, id: notification.id } });
        }
      },
      async deleteNotification(notificationToDelete) {
        try {
          const response = await axios.delete(`/notification/${notificationToDelete.id}`);
  
          if (response.data) {
            this.notification = this.notification.filter(u => u.id !== notificationToDelete.id);
  
            this.showAlert({ message: 'Success', color: 'success' });
          } else {
            throw new Error('Failed to delete notification');
          }
        } catch (error) {
          const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'An error occurred';
  
          this.showAlert({ message: errorMessage, color: 'error' });
        }
      }
    },
    mounted() {
      this.fetchNotification();
    }
  };
  </script>