<template>
  <Breadcrumbs :items="breadcrumbItems" />
  <v-card>
    <template v-slot:title> </template>

    <template v-slot:append>
      <v-btn class="text-none" color="primary" @click="submitForm">
        {{ isEditMode ? 'Update' : 'Save' }}
      </v-btn>
    </template>

    <v-divider></v-divider>

    <v-card-text>
      <div>
        <v-form @submit.prevent="submitForm">
          <v-row>
            <v-col lg="2" md="2" sm="12" cols="12">
              <label class="custom-label">Role Name</label>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="roleName"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="12" cols="12">
              <label class="custom-label">Status</label>
            </v-col>
            <v-col cols="12" md="6">
              <v-select v-model="status" :items="statusOptions"></v-select>
            </v-col>
          </v-row>
          <v-divider class="my-4"></v-divider>
          <v-row>
            <v-col cols="12"><h3>List of Permissions</h3></v-col>
            <v-col cols="2">
              <v-text-field v-model="search" placeholder="Search by Module" density="compact" append-inner-icon="mdi-magnify" clearable></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-data-table items-per-page="999" :headers="headers" :items="modules" item-value="name" :search="search"
                hide-default-footer>
                <template v-slot:item.name="{ item }">
                  {{ formatModuleName(item.name) }}
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn variant="text" density="compact" @click="confirmDelete(item)" class="pa-0" color="error">
                    {{ $t('actions.delete') }}
                  </v-btn>
                </template>
                
                <template v-slot:item.permission="{ item }">
                  <div class="permission-wrapper">
                    <!-- Select All Checkbox -->
                    <v-checkbox
                      label="Select All"
                      v-model="item.selectAllCheckbox"
                      @change="toggleAllPermissions(item)"
                    ></v-checkbox>


                    <!-- Individual Permissions -->
                    <v-checkbox
                      v-for="permission in item.permissions"
                      :key="permission.value"
                      :label="permission.label"
                      v-model="item.permissionsChecked[permission.value]"
                    ></v-checkbox>
                  </div>
                </template>


              </v-data-table>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-card-text>
  </v-card>

  <!-- Confirmation Dialog -->
  <v-dialog v-model="showConfirmation" max-width="400" persistent>
    <v-card>
      <v-card-title>
        Confirm Deletion
      </v-card-title>
      <v-card-subtitle>
        Are you sure you want to delete this item?
      </v-card-subtitle>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="showConfirmation = false">
          Cancel
        </v-btn>
        <v-btn @click="removeModule(moduleToDelete)">
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-overlay
    :model-value="loading"
    class="align-center justify-center"
    persistent
  >
    <v-progress-circular
        color="primary"
        size="120"
        indeterminate
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { mapActions } from 'vuex';
import breadcrumbConfig from '@/others/breadcrumbConfig.js';

export default {
  components: {
    Breadcrumbs,
  },
  computed: {
    breadcrumbItems() {
      const action = this.$route.params.action;
      const route = this.$route.name;
      const items = breadcrumbConfig[route];

      if (typeof items === 'function') {
        return items(action);
      }

      return items || [];
    },
    isEditMode() {
      return !!this.$route.params.id;
    }
  },
  data() {
    return {
      roleName: '',
      status: 'Active',
      selectedModule: null,
      statusOptions: ['Active', 'Inactive'],
      moduleOptions: [
      ], // TODO: THIS APPROACH SHOULD BE REFACTORED !!
      modules: [],
      actionData: [],
      headers: [
        { title: 'Module', key: 'name' },
        { title: 'Permission', key: 'permission' },
        // { title: 'Action', key: 'actions', sortable: false },
      ],
      showConfirmation: false,
      moduleToDelete: null,
      loading: false,
      selectAllCheckbox: {}, 
      search: ''
    };
  },
  watch: {
    selectedModule: {
      handler(newModules) {
        if (newModules && newModules.length > 0) {
          clearTimeout(this._debounceTimeout);
          this._debounceTimeout = setTimeout(() => {
            this.actionData = [];
            newModules.forEach(module => this.fetchAction(module));
          }, 300); // Adjust debounce delay as needed
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    const { id } = this.$route.params;

    // Fetch all available permissions
    await this.fetchAllModule();

    if (id) {
      // Edit action: Fetch existing role data
      try {
        this.loading = true;
        const response = await axios.get(`/role/${id}`);
        const role = response.data;

        this.roleName = role.name;
        this.status = role.status;

        // Initialize permissions with both existing and available ones
        await this.initializeModules(role.permissions);
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'Failed to fetch role data';

        this.showAlert({ message: errorMessage, color: 'error' });
      } finally {
        this.loading = false;
      }
    }
  },
  methods: {
    ...mapActions(['showAlert']),
    async fetchAllModule() {
      this.loading = true;
      try {
        const response = await axios.get('/role/available_permissions');
        const modules = response.data.permissions_available;

        this.modules.forEach(module => {
          module.selectAllCheckbox = false; // Initialize "Select All" state for each module
        });



        if (Array.isArray(modules)) {
          this.moduleOptions = modules.map(module => module.label);

          this.selectedModule = modules.map(module => module.label);

          for (const module of this.selectedModule) {
            await this.fetchAction(module); // Ensure fetchAction populates actionData
            console.log(`[fetchAllModule] actionData after fetching ${module}:`, this.actionData);
          }

          this.selectedModule.forEach(module => {
            const formattedModule = module.toLowerCase().replace(/\s+/g, '_');
            const newPermissions = this.actionData[formattedModule] || [];
            console.log(`[fetchAllModule] Permissions for ${formattedModule}:`, newPermissions);

            const permissionsChecked = newPermissions.reduce((acc, curr) => {
              acc[curr.value] = false;
              return acc;
            }, {});

            if (!this.modules.some(m => m.name === module)) {
              this.modules.push({
                name: module,
                permissions: newPermissions,
                permissionsChecked: permissionsChecked,
              });
            }
          });
        } else {
          console.error("permissions_available is not an array");
        }
      } catch (error) {
        console.error("Error fetching modules", error);
      }
      this.loading = false;
    },
    async fetchAction(module) {
      try {
        const formattedModule = module.toLowerCase().replace(/\s+/g, '_');
        console.log(`[fetchAction] Fetching actions for: ${formattedModule}`);

        const response = await axios.get('/role/available_permissions', {
          params: { module: formattedModule },
        });

        const methods = response.data?.permissions_available?.methods || [];
        const moduleActions = methods.map(method => {
          const key = Object.keys(method)[0];
          return { value: `${formattedModule}/${key}`, label: method[key].label };
        });

        // Update actionData
        this.actionData[formattedModule] = moduleActions;
        console.log(`[fetchAction] Updated actionData for ${formattedModule}:`, this.actionData[formattedModule]);
      } catch (error) {
        console.error(`[fetchAction] Error fetching module actions for ${module}:`, error);
      }
    },

    addModule() {
      if (this.selectedModule && this.selectedModule.length > 0) {
        this.selectedModule.forEach(module => {
          if (!this.modules.some(m => m.name === module)) {
            const formattedModule = module.toLowerCase().replace(/\s+/g, '_');
            const newPermissions = this.actionData[formattedModule] || []; // Use the specific module's actions
            const permissionsChecked = newPermissions.reduce((acc, curr) => {
              acc[curr.value] = false; // Initialize all permissions as unchecked
              return acc;
            }, {});

            // Push the new module with its permissions
            this.modules.push({
              name: module,
              permissions: newPermissions,
              permissionsChecked: permissionsChecked,
            });
          }
        });
        this.selectedModule = []; // Reset the selectedModule array
      }
    },
    confirmDelete(module) {
      this.showConfirmation = true;
      this.moduleToDelete = module;
    },
    removeModule(module) {
      this.modules = this.modules.filter(m => m.name !== module.name);
      this.showConfirmation = false;
    },
    async submitForm() {
      const allPermissions = this.modules.flatMap(module =>
        Object.keys(module.permissionsChecked)
          .filter(permission => module.permissionsChecked[permission])
          .map(permission => `api/${permission}`)
      );

      const roleData = {
        role: {
          name: this.roleName,
          status: this.status,
          permissions: allPermissions,
        }
      };
      
      try {
        const { id } = this.$route.params;
        const url = id ? `/role/${id}` : '/role';
        const method = id ? 'put' : 'post';
        const response = await axios[method](url, roleData);
        method == 'put' ?
          this.showAlert({ message: 'Role successfully update', color: 'success' })
          : this.showAlert({ message: 'Role successfully saved', color: 'success' })

        this.$router.push({ name: 'role' });
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'Submit error';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    async initializeModules(permissions) {
      const moduleNames = [...new Set(permissions.map(permission => permission.split('/')[1]))];
      const existingPermissions = new Set(permissions.map(permission => permission.replace('api/', '')));

      // Map fetched permissions to existing permissions
      this.modules = this.modules.map(module => {
        const modulePermissions = module.permissions || [];

        // Merge available permissions with existing permissions
        const permissionsChecked = modulePermissions.reduce((acc, curr) => {
          acc[curr.value] = existingPermissions.has(curr.value); // Check if permission exists in the role
          return acc;
        }, {});

        return {
          ...module,
          permissions: modulePermissions, // Keep all available permissions
          permissionsChecked: permissionsChecked, // Checked or unchecked based on role's data
          selectAllCheckbox: Object.values(permissionsChecked).every(Boolean), // True if all are selected
        };
      });
    },
    formatModuleName(moduleName) {
      if (/^[A-Z]/.test(moduleName)) {
        return moduleName;
      }
      
      return moduleName
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    },
    toggleAllPermissions(item) {
      const isAllSelected = item.selectAllCheckbox; // Get the "Select All" state for this module
      Object.keys(item.permissionsChecked).forEach(permission => {
        item.permissionsChecked[permission] = isAllSelected; // Toggle all checkboxes
      });
    },

    // Optionally, if you want to compute "Select All" dynamically:
    isAllSelected(item) {
      const permissions = Object.values(item.permissionsChecked);
      return permissions.length > 0 && permissions.every(selected => selected);
    },

  }
};
</script>

<style>
.v-data-table__wrapper {
  overflow-x: auto;
}

.permission-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.permission-wrapper .v-checkbox {
  margin-right: 10px;
}
</style>
