<template>
    <Breadcrumbs :items="breadcrumbItems" />
    <v-card elevation="0" class="mt-2">
      <template v-slot:append>
        <v-btn class="text-none" color="primary" @click="goToCustomizePage('edit', defiasiLimit)">
          Customize
        </v-btn>
      </template>
  
      <v-divider></v-divider>
  
      <v-card-text>
        <v-row>
          <v-col lg="2" md="2" sm="2" cols="12">
            <label class="custom-label">SKU</label>
          </v-col>
          <v-col md="6" sm="6" cols="12">
            <div>{{ defiasiLimit.sku }}</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="2" md="2" sm="2" cols="12">
            <label class="custom-label">Product Name</label>
          </v-col>
          <v-col md="6" sm="6" cols="12">
            <div>{{ defiasiLimit.product_name }}</div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </template>
  
  <script>
  import axios from '../plugins/axios';
  import Breadcrumbs from '@/components/Breadcrumbs.vue';
  import breadcrumbConfig from '@/others/breadcrumbConfig.js';
  import { mapActions } from 'vuex';
  
  export default {
    components: {
      Breadcrumbs,
    },
    data() {
      return {
        defiasiLimit: {
          id: null,
          product_name: '',
          sku: '',
        }
      };
    },
    computed: {
      breadcrumbItems() {
        const action = this.$route.params.action;
        const route = this.$route.name;
        const items = breadcrumbConfig[route];
  
        if (typeof items === 'function') {
          return items(action);
        }
  
        return items || [];
      },
    },
    async mounted() {
      const { id } = this.$route.params;
  
      // Fetch product details
      if (id) {
        try {
          const response = await axios.get(`/defiation_limit/${id}`);
          this.defiasiLimit = response.data;
  
        } catch (error) {
          const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'An error occurred';
  
          this.showAlert({ message: errorMessage, color: 'error' });
        }
      }
    },
    methods: {
      ...mapActions(['showAlert']),
      goToCustomizePage(action, defiasiLimit) {
        this.$router.push({ name: 'defiasiLimit-form', params: { action, id: defiasiLimit.id } });
      },
    },
  };
  </script>
  
  <style scoped>
  .custom-label {
    font-weight: bold;
    font-size: 11px;
  }
  </style>
  