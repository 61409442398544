<template>
  <Breadcrumbs :items="breadcrumbItems" />
  <v-card>
    <template v-slot:title> </template>

    <v-divider></v-divider>

    <v-card-text>
      <div>
        <v-form ref="form" @submit.prevent="handleSubmit">
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Distributor (Sold To)</label>
            </v-col>
            <v-col md="3" sm="3" cols="12">
              <v-select v-model="parentDistributorId" :items="parentDistributors" item-value="id" item-title="name"
                    placeholder="" clearable></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Distributor (Ship To)</label>
            </v-col>
            <v-col md="3" sm="3" cols="12">
              <v-select v-model="price.distributor" :items="distributors" return-object item-title="name"
                    placeholder="" clearable></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Distribution Type</label>
            </v-col>
            <v-col md="3" sm="3" cols="12">
              <v-text-field readonly v-model="distributorType"
                placeholder=""></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-data-table :items="price.price_details_attributes" :headers="productHeaders" hide-default-footer>
                <template v-slot:item.price_detail_periods_attributes="{ item: parentItem }">
                  <v-btn
                    variant="outlined"
                    append-icon="mdi-list-box-outline"
                    width="100%"
                    height="25"
                    class="d-flex justify-space-between align-center"
                  >
                    Price by Period
                    <v-dialog activator="parent" max-width="50%">
                      <template v-slot:default="{ isActive }">
                        <v-card
                          prepend-icon="mdi-picture-in-picture-bottom-right"
                          :title="`Product Price by Period`"
                        >
                          <v-row class="my-6">
                            <v-col class="ma-4" cols="12">
                              <!-- Child Table -->
                              <v-data-table
                                :items="parentItem.price_detail_periods_attributes || []"
                                :headers="priceByPeriodHeaders"
                                hide-default-footer
                              >
                                <!-- Use childItem as the variable name for rows -->
                                <template v-slot:item.start_date="{ item: childItem }">
                                  <v-date-input
                                    :disabled="isEditMode"
                                    v-model="childItem.start_date"
                                    placeholder="Start Active Date"
                                    variant="outlined"
                                    density="compact"
                                    :multiple="false"
                                    prepend-icon=""
                                    prepend-inner-icon="$calendar"
                                  ></v-date-input>
                                </template>

                                <template v-slot:item.price_value="{ item: childItem }">
                                  <vue-number
                                    v-model="childItem.price_value"
                                    v-bind="number"
                                    class="custom-input text-right ma-2"
                                    style="height: 40px;"
                                  ></vue-number>
                                </template>


                                <template v-slot:item.actions="{ item: childItem }">
                                  <!-- <v-btn
                                    density="compact"
                                    variant="text"
                                    color="error"
                                    @click="confirmDelete(parentItem, childItem)"
                                  >
                                    {{ $t('actions.delete') }}
                                  </v-btn> -->

                                  <v-menu
                                    v-model="confirmDeleteMenu"
                                    :close-on-content-click="false"
                                    location="end"
                                  >
                                    <template v-slot:activator="{ props }">
                                      <v-btn
                                        color="red"
                                        variant="text"
                                        v-bind="props"
                                      >
                                        Delete
                                      </v-btn>
                                    </template>

                                    <v-card min-width="300">
                                      <v-list>
                                        <v-list-item>
                                          Are you sure you want to Delete this items?
                                        </v-list-item>
                                      </v-list>

                                      <v-card-actions class="bg-surface-light pt-4">
                                        <v-spacer></v-spacer>

                                        <v-btn
                                          variant="text"
                                          @click="menu = false"
                                        >
                                          Cancel
                                        </v-btn>
                                        <v-btn
                                          color="red"
                                          variant="text"
                                          @click="confirmDelete(parentItem, childItem)"
                                        >
                                          Delete
                                        </v-btn>
                                      </v-card-actions>
                                    </v-card>
                                  </v-menu>
                                </template>
                              </v-data-table>
                            </v-col>

                            <!-- Add New Price Button -->
                            <v-col>
                              <v-btn
                                class="ma-4"
                                variant="outlined"
                                color="primary"
                                @click="addNewPrice(parentItem)"
                              >
                                Add Price
                              </v-btn>
                            </v-col>
                          </v-row>

                          <template v-slot:actions>
                            <v-btn
                              class="ml-auto"
                              text="Save"
                              variant="flat"
                              color="primary"
                              @click="isActive.value = false"
                            ></v-btn>
                          </template>
                        </v-card>
                      </template>
                    </v-dialog>
                  </v-btn>
                </template>
              </v-data-table>

            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-card-text>

    <template v-slot:append>
      <v-btn @click="submitForm" color="primary">
        {{ isAdding ? $t('button.save') : $t('button.update') }}
      </v-btn>
    </template>
  </v-card>
  
</template>

<script>6
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { mapActions } from 'vuex';
import breadcrumbConfig from '@/others/breadcrumbConfig.js';

export default {
  components: {
    Breadcrumbs
  },
  data() {
    return {
      price: {
        id: null,
        price_1: null,
        price_2: null,
        price_3: null,
        transaction_code: null,
        distributor: null,
        price_details_attributes: []
      },
      parentDistributors: [],
      distributors: [],
      parentDistributorId: null,
      productHeaders :[
        { title: 'Product Code', value: 'product_code', align: 'center', sortable: true },
        { title: 'Product Division', key: 'product_division.name', align: 'center' , sortable: true},
        { title: 'Product Name', key: 'product_name', align: 'center' , sortable: true},
        { title: 'Product Nickname', key: 'product_nickname', align: 'center' , sortable: true},
        { title: 'Price', value: 'price_detail_periods_attributes', align: 'center' },
      ],
      priceByPeriodHeaders : [
        { title: 'Start Date', value:'start_date', align: 'center', sortable: true },
        { title: 'Price', value: 'price_value', align: 'center' , sortable: true},
        { title: 'Actions', value: 'actions', align: 'center' },
      ],
      products: [],
      periods: [],
      confirmDeleteMenu: false,
      priceId: null,
    };
  },
  computed: {
    isAdding() {
      return this.$route.params.action === 'add';
    },
    breadcrumbItems() {
      const action = this.$route.params.action;
      const route = this.$route.name;
      const items = breadcrumbConfig[route];

      if (typeof items === 'function') {
        return items(action);
      }

      return items || [];
    },
    distributorType() {
      return this.price.distributor ? this.price.distributor.distribution_type : 'Select Distributor First';
    },
  },
  async mounted() {
    const { action, id } = this.$route.params;

    this.priceId = id;
    this.fetchParentDistributor();
    this.fetchDistributors();

    await this.fetchProducts(); 
    this.fetchPeriods();

    // Fetch price details if editing
    if (action === 'edit' && id) {
      try {
        const response = await axios.get(`/price/${id}`);
        this.price.id = response.data.id;
        this.price.distributor = response.data.distributor;
        this.parentDistributorId = response.data.distributor.parent_id;
        this.price.start_date = new Date(response.data.start_date);
        this.price.end_date = new Date(response.data.end_date);
        // this.price.price_details_attributes = response.data.price_details;
        // Update `start_date` in each `price_detail_periods_attributes` of `price_details_attributes`
        this.price.price_details_attributes = response.data.price_details.map(detail => {
          if (detail.price_detail_periods_attributes) {
            detail.price_detail_periods_attributes.forEach(period => {
              period.start_date = new Date(period.start_date); // Convert start_date to a Date object
            });
          }
          return detail;
        });
       
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    }
  },
  methods: {
    ...mapActions(['showAlert']),
    async handleSubmit(isDeleteItem = false) {
      this.loading = true;

      this.price.price_details_attributes = this.price.price_details_attributes.map(detail => {
        if (detail.price_detail_periods_attributes) {
          detail.price_detail_periods_attributes.forEach(period => {
            period.start_date = new Date(period.start_date).toLocaleDateString('en-CA');
          });
        }
        return detail;
      });
      
      try {
        const priceData = {
          distributor_id: this.price.distributor.id,
          price_details_attributes: this.price.price_details_attributes
        };

        if (this.isAdding) {
          const response = await axios.post('/price/', { price: priceData });

          if (response.data) {
            this.$router.push({ name: 'price' });
            this.showAlert({ message: 'Successfully added Price data', color: 'success' });
          } else {
            this.showAlert({ message: "Failed", color: 'error' });
          }
        } else {
          const response = await axios.put(`/price/${this.price.id}`, { price: priceData });

          if (response.data) {
            if (isDeleteItem) {
              const editResponse = await axios.get(`/price/${this.priceId}`);
              this.price.id = editResponse.data.id;
              this.price.distributor = editResponse.data.distributor;
              this.parentDistributorId = editResponse.data.distributor.parent_id;
              this.price.start_date = new Date(editResponse.data.start_date);
              this.price.end_date = new Date(editResponse.data.end_date);
              this.price.price_details_attributes = editResponse.data.price_details;

              this.confirmDeleteMenu = false;
            } else {
              this.$router.push({ name: 'price' });
            }
            this.showAlert({ message: 'Successfully added Price data', color: 'success' });
          } else {
            this.showAlert({ message: 'Error updating Price', color: 'error' });
          }
        }
        this.loading = false;
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    submitForm() {
      this.handleSubmit(); // Call the submit method
    },
    async fetchParentDistributor() {
      try {
        const response = await axios.get('/distributor?is_parent=true');
        this.parentDistributors = response.data;
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    async fetchDistributors() {
      try {
        const response = await axios.get('/distributor?is_parent=false');
        this.distributors = response.data;
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    async fetchProducts() {
      try {
        const response = await axios.get('/product?price=true');
        this.price.price_details_attributes = response.data;
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    async fetchPeriods() {
      try {
        const response = await axios.get('/period');
        this.periods = response.data;
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    addNewPrice(item) {
      // Ensure item.price_detail_periods_attributes is initialized as an array
      if (!Array.isArray(item.price_detail_periods_attributes)) {
        this.$set(item, 'price_detail_periods_attributes', []); // Use Vue's reactivity setter
      }

      // Add a new price detail row with default values
      item.price_detail_periods_attributes.push({
        period_id: null,        
        price_value: null,       
        start_date: null,
        status: null 
      });
    },
    confirmDelete(parentItem, childItem) {
      // Find the index of the child item
      this.loading = true;
      const index = parentItem.price_detail_periods_attributes.indexOf(childItem);
      if (index > -1) {
        if (childItem.id) {
          parentItem.price_detail_periods_attributes[index]._destroy = true;
          this.handleSubmit(true);
        } else {
          parentItem.price_detail_periods_attributes.splice(index, 1);
        }
      }
      this.loading = false;
    },

  }
};
</script>