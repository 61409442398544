<template>
    <Breadcrumbs :items="breadcrumbItems" />
    <v-card elevation="0" class="mt-2">
      <template v-slot:append>
        <v-btn class="text-none" color="primary" @click="goToCustomizePage('edit', salesTarget)">
          Customize
        </v-btn>
      </template>
  
      <v-divider></v-divider>
  
      <v-card-text>
        <v-row>
          <v-col lg="2" md="2" sm="2" cols="12">
            <label class="custom-label">Code</label>
          </v-col>
          <v-col md="6" sm="6" cols="12">
            <div>{{ salesTarget.code }}</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="2" md="2" sm="2" cols="12">
            <label class="custom-label">Name</label>
          </v-col>
          <v-col md="6" sm="6" cols="12">
            <div>{{ salesTarget.name }}</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="2" md="2" sm="2" cols="12">
            <label class="custom-label">Alias Name</label>
          </v-col>
          <v-col md="6" sm="6" cols="12">
            <div>{{ salesTarget.alias_name }}</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="2" md="2" sm="2" cols="12">
            <label class="custom-label">Shipping Address</label>
          </v-col>
          <v-col md="6" sm="6" cols="12">
            <div>{{ salesTarget.shipping_address }}</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="2" md="2" sm="2" cols="12">
            <label class="custom-label">Billing Address</label>
          </v-col>
          <v-col md="6" sm="6" cols="12">
            <div>{{ salesTarget.billing_address }}</div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </template>
  
  <script>
  import axios from '../plugins/axios';
  import Breadcrumbs from '@/components/Breadcrumbs.vue';
  import breadcrumbConfig from '@/others/breadcrumbConfig.js';
  import { mapActions } from 'vuex';
  
  export default {
    components: {
      Breadcrumbs,
    },
    data() {
      return {
        salesTarget: {
          id: null,
          name: '',
          code: '',
          alias_name: '',
          shipping_address: '',
          billing_address: ''
        }
      };
    },
    computed: {
      breadcrumbItems() {
        const action = this.$route.params.action;
        const route = this.$route.name;
        const items = breadcrumbConfig[route];
  
        if (typeof items === 'function') {
          return items(action);
        }
  
        return items || [];
      },
    },
    async mounted() {
      const { id } = this.$route.params;
  
      // Fetch product details
      if (id) {
        try {
          const response = await axios.get(`/sales_target/${id}`);
          this.salesTarget = response.data;
  
        } catch (error) {
          const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'An error occurred';
  
          this.showAlert({ message: errorMessage, color: 'error' });
        }
      }
    },
    methods: {
      ...mapActions(['showAlert']),
      goToCustomizePage(action, salesTarget) {
        this.$router.push({ name: 'salesTarget-form', params: { action, id: salesTarget.id } });
      },
    },
  };
  </script>
  
  <style scoped>
  .custom-label {
    font-weight: bold;
    font-size: 11px;
  }
  </style>
  